import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GET_SERVICE_DETAILS_FAILURE, GET_SERVICE_DETAILS_SUCCESS, getServiceDetails, SET_LOADER, UPDATE_SERVICE_DETAILS_FAILURE, UPDATE_SERVICE_DETAILS_SUCCESS, updateService, updateServiceDetails, UPLOAD_TERMS_AND_CONDITIONS_FAILURE } from '../../Redux/Actions/Actions'
import UploadComponent from '../Reusable_Components/UploadComponent'
import Loader from '../Reusable_Components/loader'
import axios from 'axios'
import { base_url } from '../../utilities'
import { Button, Modal } from 'react-bootstrap'
import { MdErrorOutline } from 'react-icons/md'
import DatePicker from 'react-datepicker';
import TableComponent from '../Reusable_Components/TableComponent.jsx'
import { eleminateSpacesAtStartOfInputField } from '../Reusable_Components/ReusableFunctions'
import Footer from "../Login/Footer.jsx";

export default function EditService(props) {

    const dispatch = useDispatch()

    const serviceDetails = useSelector(state => state.serviceDetails)
    const getServiceDetailsError = useSelector(state => state.getServiceDetailsError)
    const loading = useSelector(state => state.loading)
    const updateServiceSuccess = useSelector(state => state.updateServiceSuccess)
    const updateServiceError = useSelector(state => state.updateServiceError)

    const [edit, setEdit] = useState(false)
    const [filesData, setFilesData] = useState([])
    const [tncFilesData, setTncFilesData] = useState([])
    const [hsnCodeError, setHsnCodeError] = useState("");
    const [cgstError, setCgstError] = useState("");
    const [sgstError, setSgstError] = useState("");
    const [igstError, setIgstError] = useState("");
    const [biilingPeriodFilter, setBillingPeriodFilter] = useState(new Date())
    const [taxRatesBillingFilter, setTaxRatesBillingFilter] = useState(new Date())
    const [paymentTermsBillingFilter, setPaymentTermsBillingFilter] = useState(new Date())
    const [termsAndConditionsBillingFilter, setTermsAndConditionsBillingFilter] = useState(new Date())
    const [viewHsnRecordsModal, setViewHsnRecordsModal] = useState(false)
    const [hsnCodes, setHsnCodes] = useState([])
    const [hsnCodeErrorModal, setHsnCodeErrorModal] = useState(null)
    const [viewTaxRatesModal, setViewTaxRatesModal] = useState(false)
    const [taxRates, setTaxRates] = useState([])
    const [taxRatesError, setTaxRatesError] = useState(null)
    const [viewInvoiceTermsModal, setViewInvoiceTermsModal] = useState(false)
    const [invoiceTerms, setInvoiceTerms] = useState([])
    const [invoiceTermsError, setInvoiceTermsError] = useState(null)

    useEffect(() => {
        dispatch(getServiceDetails(props.serviceId))
    }, [])

    const handleSelectPtFile = (files) => {
        dispatch({
            type: GET_SERVICE_DETAILS_SUCCESS,
            payload: { ...serviceDetails, payment_terms: { ...(serviceDetails?.payment_terms), document_key: null } }
        })
        const processedFiles1 = [];
        files.forEach((file) => {
            const reader1 = new FileReader();
            reader1.onloadend = function () {
                const base64FileContent = reader1.result.split(",")[1];
                const processedFile1 = {
                    file_content: base64FileContent,
                    file_name: file.file.name,
                    file_type: "pdf",
                    object_type: "terms_documents",
                    flag: null,
                    document_type: "pterms",
                    user_id: localStorage.getItem("username")
                };
                processedFiles1.push(processedFile1);
                if (processedFiles1.length === files.length) {
                    setFilesData(processedFiles1);
                }
            };
            reader1.readAsDataURL(file.file);
        });
    }

    const handleSelectTncFile = (files) => {
        dispatch({
            type: GET_SERVICE_DETAILS_SUCCESS,
            payload: { ...serviceDetails, terms_and_conditions: { ...(serviceDetails?.terms_and_conditions), document_key: null } }
        })
        const processedFiles1 = [];
        files.forEach((file) => {
            const reader1 = new FileReader();
            reader1.onloadend = function () {
                const base64FileContent = reader1.result.split(",")[1];
                const processedFile1 = {
                    file_content: base64FileContent,
                    file_name: file.file.name,
                    file_type: "pdf",
                    object_type: "terms_documents",
                    flag: null,
                    document_type: "tandc",
                    user_id: localStorage.getItem("username")
                };
                processedFiles1.push(processedFile1);
                if (processedFiles1.length === files.length) {
                    setTncFilesData(processedFiles1);
                }
            };
            reader1.readAsDataURL(file.file);
        });
    }

    const handlePtFileUpload = (index) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + "documents", { "files": [filesData[index]] })
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                    dispatch({
                        type: GET_SERVICE_DETAILS_SUCCESS,
                        payload: { ...serviceDetails, payment_terms: { ...(serviceDetails?.payment_terms), document_key: response?.data?.data[0]?.key } }
                    })

                }
            })
            .catch((error) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: UPLOAD_TERMS_AND_CONDITIONS_FAILURE,
                    payload: error.response.data.message
                })
            })
    }

    const handleTncFileUpload = (index) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.post(base_url + "documents", { "files": [tncFilesData[index]] })
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                    dispatch({
                        type: GET_SERVICE_DETAILS_SUCCESS,
                        payload: { ...serviceDetails, terms_and_conditions: { ...(serviceDetails?.terms_and_conditions), document_key: response?.data?.data[0]?.key } }
                    })

                }
            })
            .catch((error) => {
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: UPLOAD_TERMS_AND_CONDITIONS_FAILURE,
                    payload: error.response.data.message
                })
            })
    }

    const handleUpdateService = () => {
        const data = {
            service_type_id: serviceDetails?.service?.service_type_id,
            service_description: serviceDetails?.service?.service_description,
            invoice_name: serviceDetails?.service?.invoice_name,
            invoice_description: serviceDetails?.service?.invoice_description,
            invoice_frequency: serviceDetails?.service?.invoice_frequency,
            invoice_generation_day: serviceDetails?.service?.invoice_generation_day,
            invoice_cutoff_day: serviceDetails?.service?.invoice_cutoff_day,
            pre_pay_ind: serviceDetails?.service?.pre_pay_ind,
            charge_by_consumption_ind: serviceDetails?.service?.charge_by_consumption_ind,
            allow_prorate_billing_ind: serviceDetails?.service?.allow_prorate_billing_ind,
            hsn_code: serviceDetails?.hsn_code?.hsn_code,
            hsncode_effective_from_month: biilingPeriodFilter.getMonth() + 1,
            hsncode_effective_from_year: biilingPeriodFilter.getFullYear(),
            cgst: parseFloat(serviceDetails?.tax_rates?.cgst),
            sgst: parseFloat(serviceDetails?.tax_rates?.sgst),
            igst: parseFloat(serviceDetails?.tax_rates?.igst),
            taxrates_effective_from_month: taxRatesBillingFilter.getMonth() + 1,
            taxrates_effective_from_year: taxRatesBillingFilter.getFullYear(),
            // terms_and_conditions: serviceDetails?.terms_and_conditions?.document_key,
            // tandc_effective_from_month: termsAndConditionsBillingFilter.getMonth() + 1,
            // tandc_effective_from_year: termsAndConditionsBillingFilter.getFullYear(),
            // payment_terms: serviceDetails?.payment_terms?.document_key,
            // pterms_effective_from_month: paymentTermsBillingFilter.getMonth() + 1,
            // pterms_effective_from_year: paymentTermsBillingFilter.getFullYear()
        }
        dispatch(updateService(data))
    }

    const getHsnCodes = (service_code) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + `customers/serviceTypes/hsnCodes?filter_condition=%7B%22service_code%22%3A%20%22${service_code}%22%7D`)
            .then((res) => {
                if (res.status === 200) {
                    setHsnCodes(res.data.data)
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                setHsnCodeErrorModal(err.response.data.message)
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }

    const getTaxRates = (service_code) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + `customers/serviceTypes/taxRates?filter_condition=%7B%22service_code%22%3A%20%22${service_code}%22%7D`)
            .then((res) => {
                if (res.status === 200) {
                    setTaxRates(res.data.data)
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                setTaxRatesError(err.response.data.message)
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }

    const getInvoiceTerms = (service_code, document_type) => {
        dispatch({
            type: SET_LOADER,
            payload: true
        })
        axios.get(base_url + `customers/serviceTypes/invoiceTerms?filter_condition=%7B%22service_code%22%3A%20%22${service_code}%22%2C%20%22document_type%22%3A%20%22${document_type}%22%7D`)
            .then((res) => {
                if (res.status === 200) {
                    setInvoiceTerms(res.data.data)
                    dispatch({
                        type: SET_LOADER,
                        payload: false
                    })
                }
            })
            .catch((err) => {
                setInvoiceTermsError(err.response.data.message)
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
            })
    }
    const [showBackConfirmation, setShowBackConfirmation] = useState(false)
    const handleBack = () => setShowBackConfirmation(false);
    const handleProceed = () => {
        setShowBackConfirmation(false);
    }
    // code to download invoice terms pdf
    // const downloadInvoiceTerms = (document_key) => {
    //     dispatch({
    //         type: SET_LOADER,
    //         payload: true
    //     })
    //     axios.get(base_url + `customers/serviceTypes/invoiceTerms/${document_key}`)
    //         .then((res) => {
    //             if (res.status === 200) {
    //                 const link = document.createElement('a');
    //                 link.href = res.data.data;
    //                 document.body.appendChild(link);
    //                 link.click();
    //                 document.body.removeChild(link);
    //                 dispatch({
    //                     type: SET_LOADER,
    //                     payload: false
    //                 })
    //             }
    //         })
    //         .catch((err) => {
    //             dispatch({
    //                 type: SET_LOADER,
    //                 payload: false
    //             })
    //         })
    // }

    return (
        <div className="addUserContainer">
            <div className="stepsContainer">
                <div className="stepsTitle">
                    <h6 style={{ padding: "20px", lineHeight: "0px" }}>Edit Service</h6>
                </div>
                <div className={'stepsCardActive'}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <span style={{ fontWeight: "bold", fontSize: "medium" }}>Service Information</span>
                    </div>
                    <span style={{ fontSize: "small", marginTop: "10px" }}>Service Details</span>
                </div>
                {/* <div style={{ height: "80%" }} className='d-flex flex-column align-items-start gap-2 px-3'>
                    {(serviceName === "" || serviceNameError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Serivce Name</span>}
                    {(serviceCode === "" || serviceCodeError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Serivce Code</span>}
                    {(serviceDescription === "" || serviceDescriptionError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Serivce Description</span>}
                    {(invoiceName === "" || invoiceNameError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Invoice Name</span>}
                    {(invoiceDescription === "" || invoiceDescriptionError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Invoice Description</span>}
                    {(invoiceFrequency === "" || invoiceFrequencyError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Invoice Frequency</span>}
                    {(generationDate === "" || generationDateError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> Invoice Generation Date</span>}
                    {(hsnCode === "" || hsnCodeError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> HSN Code</span>}
                    {(cgst === "" || cgstError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> CGST</span>}
                    {(sgst === "" || sgstError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> SGST</span>}
                    {(igst === "" || igstError) && <span className='text-danger'><MdErrorOutline fontSize={20} /> IGST</span>}
                </div> */}

            </div>
            <div className="infoContainer">
                <div className="infoTitle">
                    <h6 style={{ padding: "20px", lineHeight: "0px" }}>Service Information</h6>
                </div>
                <div className="infoForm">
                    <div className="infoBody">
                        <h6>Service details</h6>
                        <div className="d-flex gap-5 flex-wrap">
                            <div>
                                <label className='add-facility-modal-label'>Service Name<span style={{ color: "red" }}>*</span></label>
                                <input type='text' className='form-control responsive-add-facility-input-fields'
                                    placeholder='Eg: Space liscese fee, ELectricity etc'
                                    disabled
                                    value={serviceDetails?.service?.service_name}
                                />
                                {/* <span style={{ color: "red", fontSize: "small" }}>{serviceNameError}</span> */}
                            </div>
                            <div>
                                <label className='add-facility-modal-label'>Service Code (max 20 characters)<span style={{ color: "red" }}>*</span></label>
                                <input type='text' className='form-control responsive-add-facility-input-fields'
                                    placeholder='Eg: slf, electricity,water,misc etc'
                                    disabled
                                    value={serviceDetails?.service?.service_code}
                                />
                                {/* <span style={{ color: "red", fontSize: "small" }}>{serviceCodeError}</span> */}
                            </div>
                        </div>
                        <div className="inputsSection">
                            <div style={{ width: "100%" }}>
                                <label className='add-facility-modal-label'>Service Description<span style={{ color: "red" }}>*</span></label>
                                <textarea className='form-control'
                                    placeholder='Description of the service'
                                    style={{ border: "0.5px solid #9D9D9C" }}
                                    disabled={!edit}
                                    onChange={(e) => {
                                        dispatch({
                                            type: GET_SERVICE_DETAILS_SUCCESS,
                                            payload: { ...serviceDetails, service: { ...(serviceDetails?.service), service_description: eleminateSpacesAtStartOfInputField(e.target.value) } }
                                        })
                                    }}
                                    value={serviceDetails?.service?.service_description}
                                />
                                {/* <span style={{ color: "red", fontSize: "small" }}>{serviceDescriptionError}</span> */}
                            </div>
                        </div>
                        <hr />
                        <h6>Invoice Information</h6>
                        <div className="inputsSection">
                            <div>
                                <label className='add-facility-modal-label'>Service name in Invoice<span style={{ color: "red" }}>*</span></label>
                                <input type='text' className='form-control responsive-add-facility-input-fields'
                                    placeholder='Eg: slf, electricity etc'
                                    disabled={!edit}
                                    value={serviceDetails?.service?.invoice_name}
                                    onChange={(e) => {
                                        dispatch({
                                            type: GET_SERVICE_DETAILS_SUCCESS,
                                            payload: { ...serviceDetails, service: { ...(serviceDetails?.service), invoice_name: eleminateSpacesAtStartOfInputField(e.target.value) } }
                                        })
                                    }
                                    }
                                />
                            </div>
                        </div>
                        <div className="inputsSection">
                            <div style={{ width: "100%" }}>
                                <label className='add-facility-modal-label'>Invoice Description<span style={{ color: "red" }}>*</span></label>
                                <textarea className='form-control'
                                    placeholder='Description of the invoice'
                                    style={{ border: "0.5px solid #9D9D9C" }}
                                    disabled={!edit}
                                    value={serviceDetails?.service?.invoice_description}
                                    onChange={(e) => {
                                        dispatch({
                                            type: GET_SERVICE_DETAILS_SUCCESS,
                                            payload: { ...serviceDetails, service: { ...(serviceDetails?.service), invoice_description: eleminateSpacesAtStartOfInputField(e.target.value) } }
                                        })
                                    }
                                    }
                                />
                                {/* <span style={{ color: "red", fontSize: "small" }}>{invoiceDescriptionError}</span> */}
                            </div>
                        </div>
                        <div className="inputsSection">
                            <div>
                                <label className='add-facility-modal-label'>Frequency<span style={{ color: "red" }}>*</span></label>
                                <select className='form-select responsive-add-facility-input-fields' value={serviceDetails?.service?.invoice_frequency} disabled={!edit}
                                    style={{ height: "40px", border: "1px solid #D3D3D3" }}
                                    onChange={(e) => {
                                        dispatch({
                                            type: GET_SERVICE_DETAILS_SUCCESS,
                                            payload: { ...serviceDetails, service: { ...(serviceDetails?.service), invoice_frequency: e.target.value } }
                                        })
                                    }
                                    }
                                >
                                    <option value="" hidden>Select</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="quarterly">Quarterly</option>
                                    <option value="yearly">Yearly</option>
                                </select>
                            </div>
                            <div>
                                <label className='add-facility-modal-label'>Invoice generation day<span style={{ color: "red" }}>*</span></label>
                                <select className='form-select responsive-add-facility-input-fields'
                                    disabled={!edit}
                                    value={serviceDetails?.service?.invoice_generation_day}
                                    style={{ height: "40px", border: "1px solid #D3D3D3" }}
                                    onChange={(e) => {
                                        dispatch({
                                            type: GET_SERVICE_DETAILS_SUCCESS,
                                            payload: { ...serviceDetails, service: { ...(serviceDetails?.service), invoice_generation_day: e.target.value } }
                                        })
                                    }
                                    }
                                >
                                    <option value="" hidden>Select</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                </select>
                            </div>
                            <div>
                                <label className='add-facility-modal-label'>Invoice Cut-off day<span style={{ color: "red" }}>*</span></label>
                                <input type='text' disabled={!edit} className='form-control responsive-add-facility-input-fields'
                                    value={serviceDetails?.service?.invoice_cutoff_day}
                                    onChange={(e) => {
                                        dispatch({
                                            type: GET_SERVICE_DETAILS_SUCCESS,
                                            payload: { ...serviceDetails, service: { ...(serviceDetails?.service), invoice_cutoff_day: e.target.value } }
                                        })
                                    }
                                    }
                                />
                            </div>
                        </div>
                        <div className="inputsSection">
                            <div className="checkboxHolder">
                                <input type='checkbox' id='prepaid' className="inputCheckbox"
                                    checked={serviceDetails?.service?.pre_pay_ind}
                                    disabled={!edit}
                                    onChange={(e) => {
                                        dispatch({
                                            type: GET_SERVICE_DETAILS_SUCCESS,
                                            payload: { ...serviceDetails, service: { ...(serviceDetails?.service), pre_pay_ind: e.target.checked, charge_by_consumption_ind: e.target.checked && false } }
                                        })
                                    }
                                    }
                                />
                                <label className='add-facility-modal-label' htmlFor='prepaid'>Pre Paid</label>
                            </div>
                            <div className="checkboxHolder">
                                <input type='checkbox' id='consumption'
                                    className="inputCheckbox"
                                    checked={serviceDetails?.service?.charge_by_consumption_ind}
                                    disabled={!edit}
                                    onChange={(e) => {
                                        dispatch({
                                            type: GET_SERVICE_DETAILS_SUCCESS,
                                            payload: { ...serviceDetails, service: { ...(serviceDetails?.service), charge_by_consumption_ind: e.target.checked, pre_pay_ind: e.target.checked && false } }
                                        })
                                    }
                                    }
                                />
                                <label className='add-facility-modal-label' htmlFor='consumption'>Charge by consumption</label>
                            </div>
                            <div className="checkboxHolder">
                                <input type='checkbox' className="inputCheckbox" id='prorate'
                                    checked={serviceDetails?.service?.allow_prorate_billing_ind}
                                    disabled={!edit}
                                    onChange={(e) => {
                                        dispatch({
                                            type: GET_SERVICE_DETAILS_SUCCESS,
                                            payload: { ...serviceDetails, service: { ...(serviceDetails?.service), allow_prorate_billing_ind: e.target.checked } }
                                        })
                                    }
                                    }
                                />
                                <label className='add-facility-modal-label' htmlFor='prorate' >Pro rate billing</label>
                            </div>
                        </div>
                        <hr />
                        <div style={{ position: "relative" }}>
                            <h6>HSN Code</h6>
                            <div className="d-flex gap-5 flex-wrap">
                                <div>
                                    <label className='add-facility-modal-label'>HSN Code<span style={{ color: "red" }}>*</span></label>
                                    <input type='text'
                                        onWheel={(e) => e.target.blur()}
                                        disabled={!edit} value={serviceDetails?.hsn_code?.hsn_code}
                                        className='noSpinArrows form-control responsive-add-facility-input-fields'
                                        placeholder='Eg: 123456 or 12345678'
                                        onChange={(e) => {
                                            const input = e.target.value.replace(/[^0-9]/g, '');
                                            dispatch({
                                                type: GET_SERVICE_DETAILS_SUCCESS,
                                                payload: { ...serviceDetails, hsn_code: { ...(serviceDetails?.hsn_code), hsn_code: input } }
                                            });
                                            if (input === "") {
                                                setHsnCodeError("HSN Code is required");
                                            } else if (!/^\d{6}$|^\d{8}$/.test(input)) {
                                                setHsnCodeError("Invalid HSN Code");
                                            } else {
                                                setHsnCodeError("");
                                            }
                                        }}
                                    />
                                    <span style={{ color: "red", fontSize: "small" }}>{hsnCodeError}</span>
                                </div>
                                {edit && <div className='d-flex flex-column'>
                                    <label className='add-facility-modal-label' style={{ marginBottom: "4px" }}>Effective from<span style={{ color: "red" }}>*</span></label>
                                    <DatePicker
                                        disabled={!edit}
                                        className="admin-controls-date-picker form-control responsive-add-facility-input-fields"
                                        placeholderText="Select Period"
                                        selected={biilingPeriodFilter}
                                        style={{ width: "1rem", height: "2.5rem !important" }}
                                        onChange={(date) => setBillingPeriodFilter(date)}
                                        showMonthYearPicker
                                        dateFormat="MMM yyyy"
                                    />
                                </div>}
                                <span role='button'
                                    onClick={() => {
                                        setViewHsnRecordsModal(true)
                                        getHsnCodes(serviceDetails?.service?.service_code)
                                    }}
                                    style={{ position: "absolute", right: 0, top: "0px", textDecoration: "underline" }}
                                >View previous records</span>
                            </div>
                        </div>
                        <hr />
                        <div style={{ position: "relative" }}>
                            <h6>Tax rates</h6>
                            <b>Intra State: </b>
                            <div className="d-flex gap-5 flex-wrap" style={{ padding: "0px 0px 10px 0px" }}>
                                <div>
                                    <label className='add-facility-modal-label'>CGST (0, 2.5, 6, 9, 14)<span style={{ color: "red" }}>*</span></label>
                                    <input type='text'
                                        onWheel={(e) => e.target.blur()}
                                        disabled={!edit} value={serviceDetails?.tax_rates?.cgst}
                                        className='noSpinArrows form-control responsive-add-facility-input-fields'
                                        onChange={(e) => {
                                            let value = e.target.value.replace(/[^0-9.]/g, '');
                                            dispatch({
                                                type: GET_SERVICE_DETAILS_SUCCESS,
                                                payload: { ...serviceDetails, tax_rates: { ...(serviceDetails?.tax_rates), cgst: value, sgst: value, igst: value * 2 } }
                                            })
                                            if (value === "") {
                                                setCgstError("CGST is required")
                                                setSgstError("SGST is required")
                                                setIgstError("IGST is required")
                                            } else if (![0, 2.5, 6, 9, 14].includes(parseFloat(value))) {
                                                setCgstError("Invalid CGST value")
                                                setSgstError("Invalid SGST value")
                                                setIgstError("Invalid IGST value")
                                            } else {
                                                setCgstError("")
                                                setSgstError("")
                                                setIgstError("")
                                            }
                                        }}
                                    />
                                    <span style={{ color: "red", fontSize: "small" }}>{cgstError}</span>
                                </div>
                                <div>
                                    <label className='add-facility-modal-label'>SGST (0, 2.5, 6, 9, 14)<span style={{ color: "red" }}>*</span></label>
                                    <input type='text'
                                        onWheel={(e) => e.target.blur()}
                                        disabled={!edit} value={serviceDetails?.tax_rates?.sgst}
                                        className='noSpinArrows form-control responsive-add-facility-input-fields'
                                        onChange={(e) => {
                                            let value = e.target.value.replace(/[^0-9.]/g, '');
                                            dispatch({
                                                type: GET_SERVICE_DETAILS_SUCCESS,
                                                payload: { ...serviceDetails, tax_rates: { ...(serviceDetails?.tax_rates), sgst: value, cgst: value, igst: value * 2 } }
                                            })
                                            if (value === "") {
                                                setCgstError("CGST is required")
                                                setSgstError("SGST is required")
                                                setIgstError("IGST is required")
                                            } else if (![0, 2.5, 6, 9, 14].includes(parseFloat(value))) {
                                                setCgstError("Invalid CGST value")
                                                setSgstError("Invalid SGST value")
                                                setIgstError("Invalid IGST value")
                                            } else {
                                                setCgstError("")
                                                setSgstError("")
                                                setIgstError("")
                                            }
                                        }}
                                    />
                                    <span style={{ color: "red", fontSize: "small" }}>{sgstError}</span>
                                </div>
                            </div>
                            <b>Inter State: </b>
                            <div className="d-flex gap-5 flex-wrap">
                                <div>
                                    <label className='add-facility-modal-label'>IGST (0, 5, 12, 18, 28)<span style={{ color: "red" }}>*</span></label>
                                    <input type='text'
                                        onWheel={(e) => e.target.blur()}
                                        disabled={!edit} value={serviceDetails?.tax_rates?.igst}
                                        className='noSpinArrows form-control responsive-add-facility-input-fields'
                                        onChange={(e) => {
                                            let value = e.target.value.replace(/[^0-9.]/g, '');
                                            dispatch({
                                                type: GET_SERVICE_DETAILS_SUCCESS,
                                                payload: { ...serviceDetails, tax_rates: { ...(serviceDetails?.tax_rates), igst: value, cgst: value / 2, sgst: value / 2 } }
                                            })
                                            if (value === "") {
                                                setCgstError("CGST is required")
                                                setSgstError("SGST is required")
                                                setIgstError("IGST is required")
                                            } else if (![0, 5, 12, 18, 28].includes(parseFloat(value))) {
                                                setCgstError("Invalid CGST value")
                                                setSgstError("Invalid SGST value")
                                                setIgstError("Invalid IGST value")
                                            } else {
                                                setCgstError("")
                                                setSgstError("")
                                                setIgstError("")
                                            }
                                        }}
                                    />
                                    <span style={{ color: "red", fontSize: "small" }}>{igstError}</span>
                                </div>
                            </div>
                            <span role='button'
                                onClick={() => {
                                    setViewTaxRatesModal(true)
                                    getTaxRates(serviceDetails?.service?.service_code)
                                }}
                                style={{ position: "absolute", right: 0, top: "0px", textDecoration: "underline" }}
                            >View previous records</span>
                        </div>
                        {edit && <div className='d-flex flex-column mt-3'>
                            <label className='add-facility-modal-label' style={{ marginBottom: "4px" }}>Effective from<span style={{ color: "red" }}>*</span></label>
                            <DatePicker
                                disabled={!edit}
                                className="admin-controls-date-picker form-control responsive-add-facility-input-fields"
                                placeholderText="Select Period"
                                selected={taxRatesBillingFilter}
                                style={{ width: "1rem", height: "2.5rem !important" }}
                                onChange={(date) => setTaxRatesBillingFilter(date)}
                                showMonthYearPicker
                                dateFormat="MMM yyyy"
                            />
                        </div>}
                        <hr />
                        {/* <div>
                            <h6>Invoice Terms</h6>
                            <div className='invoice_terms_container'>
                                <div className='py-2' style={{ width: "100%", position: "relative" }}>
                                    <h4>Payment Terms</h4>
                                    <div className='d-flex align-items-center w-100'>
                                        <div className='d-flex flex-column gap-3 w-100'>
                                            <label>Upload the Payment Terms PDF</label>
                                            <div>
                                                <UploadComponent
                                                    onFilesSelected={handleSelectPtFile}
                                                    viewMode={!edit}
                                                    uploadedFileData={serviceDetails?.payment_terms?.document_key ? [{ file_name: serviceDetails?.payment_terms?.document_key?.split("/")[2]?.split("-")[0], file_type: serviceDetails?.payment_terms?.document_key?.split("/")[2]?.split(".")[1], s3_key: serviceDetails?.payment_terms?.document_key }] : []}
                                                    deleteSpecificFileMain={0}
                                                    uploadActionClicked={handlePtFileUpload}
                                                    idType={"paymentTerms"}
                                                    acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <span role='button'
                                        onClick={() => {
                                            setViewInvoiceTermsModal(true)
                                            getInvoiceTerms(serviceDetails?.service?.service_code, "payment_terms")
                                        }}
                                        style={{ position: "absolute", right: 0, top: "0px", textDecoration: "underline" }}
                                    >View previous records</span>
                                    {edit && <div className='d-flex flex-column'>
                                        <label className='add-facility-modal-label' style={{ marginBottom: "4px" }}>Effective from</label>
                                        <DatePicker
                                            disabled={!edit}
                                            className="admin-controls-date-picker form-control responsive-add-facility-input-fields"
                                            placeholderText="Select Period"
                                            selected={paymentTermsBillingFilter}
                                            style={{ width: "1rem", height: "2.5rem !important" }}
                                            onChange={(date) => setPaymentTermsBillingFilter(date)}
                                            showMonthYearPicker
                                            dateFormat="MMM yyyy"
                                        />
                                    </div>}
                                </div>
                                <div className='py-2' style={{ width: "100%", position: "relative" }}>
                                    <h4>Terms and Conditions</h4>
                                    <div className='d-flex align-items-center w-100'>
                                        <div className='d-flex flex-column gap-3 w-100'>
                                            <label>Upload the Terms and Conditions PDF</label>
                                            <div>
                                                <UploadComponent
                                                    onFilesSelected={handleSelectTncFile}
                                                    viewMode={!edit}
                                                    uploadedFileData={serviceDetails?.terms_and_conditions?.document_key ? [{ file_name: serviceDetails?.terms_and_conditions?.document_key?.split("/")[2]?.split("-")[0], file_type: serviceDetails?.terms_and_conditions?.document_key?.split("/")[2]?.split(".")[1], s3_key: serviceDetails?.terms_and_conditions?.document_key }] : []}
                                                    deleteSpecificFileMain={0}
                                                    uploadActionClicked={handleTncFileUpload}
                                                    idType={"termsAndConditions"}
                                                    acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <span role='button'
                                        onClick={() => {
                                            setViewInvoiceTermsModal(true)
                                            getInvoiceTerms(serviceDetails?.service?.service_code, "terms_and_conditions")
                                        }}
                                        style={{ position: "absolute", right: 0, top: "0px", textDecoration: "underline" }}
                                    >View previous records</span>
                                    {edit && <div className='d-flex flex-column'>
                                        <label className='add-facility-modal-label' style={{ marginBottom: "4px" }}>Effective from</label>
                                        <DatePicker
                                            disabled={!edit}
                                            className="admin-controls-date-picker form-control responsive-add-facility-input-fields"
                                            placeholderText="Select Period"
                                            selected={termsAndConditionsBillingFilter}
                                            style={{ width: "1rem", height: "2.5rem !important" }}
                                            onChange={(date) => setTermsAndConditionsBillingFilter(date)}
                                            showMonthYearPicker
                                            dateFormat="MMM yyyy"
                                        />
                                    </div>}
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="infoFotter">
                    <div className="d-flex gap-3">
                        <button className="btn btn-secondary" onClick={() => props.setPage(1)}>Back</button>
                        <button className="btn btn-secondary" onClick={() => setEdit(!edit)}>{edit ? "Cancel" : "Edit"}</button>
                        {edit && <button className="btn btn-secondary" disabled={
                            hsnCodeError !== "" || cgstError !== "" || sgstError !== "" || igstError !== ""
                        } onClick={handleUpdateService}>Update</button>}
                    </div>
                </div>
            </div>
            {/* <Modal keyboard={false} backdrop="static" show={showBackConfirmation} onHide={() => setShowBackConfirmation(false)} centered className="popper-modal" >
                <Modal.Body className="text-center">
                    <h5>Are you sure you want to go back?</h5><p className="mb-3">All unsaved changes will be lost</p>
                </Modal.Body>
                <Modal.Footer className="footer" >
                <Button variant="secondary" className="confirmation" onClick={handleBack} >Cancel</Button>
                    <Button variant="secondary" className="confirmation-left" onClick={() => props.setPage(1)} >Proceed</Button>
                </Modal.Footer></Modal> */}


            {getServiceDetailsError && <Modal keyboard={false} backdrop="static" show={getServiceDetailsError} onHide={() => {
                dispatch({
                    type: GET_SERVICE_DETAILS_FAILURE,
                    payload: null
                })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                    <h5>Error!</h5>
                    <p className="mb-3">{getServiceDetailsError}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch({
                            type: GET_SERVICE_DETAILS_FAILURE,
                            payload: null
                        })
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {updateServiceSuccess && <Modal keyboard={false} backdrop="static" show={updateServiceSuccess} onHide={() => {
                dispatch({
                    type: UPDATE_SERVICE_DETAILS_SUCCESS,
                    payload: null
                })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <img src="./images/icons/popper.svg" alt="Party Popper" />
                    <h5>Success!</h5>
                    <p className="mb-3">{updateServiceSuccess}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch({
                            type: UPDATE_SERVICE_DETAILS_SUCCESS,
                            payload: null
                        })
                        props.setReload(true)
                        props.setPage(1)
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {updateServiceError && <Modal keyboard={false} backdrop="static" show={updateServiceError} onHide={() => {
                dispatch({
                    type: UPDATE_SERVICE_DETAILS_FAILURE,
                    payload: null
                })
            }} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                    <h5>Error!</h5>
                    <p className="mb-3">{updateServiceError}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => {
                        dispatch({
                            type: UPDATE_SERVICE_DETAILS_FAILURE,
                            payload: null
                        })
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {viewHsnRecordsModal && <Modal keyboard={false} backdrop="static" size='lg' show={viewHsnRecordsModal} onHide={() => setViewHsnRecordsModal(false)} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <h5>Previous HSN Records</h5>
                    <div style={{ overflow: "auto" }}>
                        <TableComponent
                            headers={[{
                                name: "Service Code",
                                type: "text",
                                key: "service_code"
                            }, {
                                name: "HSN Code",
                                type: "text",
                                key: "hsn_code"
                            }, {
                                name: "Start Date",
                                type: "date",
                                key: "start_date"
                            }, {
                                name: "End Date",
                                type: "date",
                                key: "end_date"
                            }, {
                                name: "Updated By",
                                type: "text",
                                key: "updated_by"
                            }, {
                                name: "Last Updated At",
                                type: "time_stamp",
                                key: "updated_at"
                            }]}
                            data={hsnCodes}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => setViewHsnRecordsModal(false)} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {hsnCodeErrorModal && <Modal keyboard={false} backdrop="static" show={hsnCodeErrorModal} onHide={() => setHsnCodeErrorModal(null)} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                    <h5>Error!</h5>
                    <p className="mb-3">{hsnCodeErrorModal}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => setHsnCodeErrorModal(null)} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {viewTaxRatesModal && <Modal keyboard={false} backdrop="static" size='lg' show={viewTaxRatesModal} onHide={() => setViewTaxRatesModal(false)} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <h5>Previous Tax Rates Records</h5>
                    <div style={{ overflow: "auto" }}>
                        <TableComponent
                            headers={[{
                                name: "Service Code",
                                type: "text",
                                key: "service_code"
                            }, {
                                name: "CGST",
                                type: "number",
                                key: "cgst"
                            }, {
                                name: "SGST",
                                type: "number",
                                key: "sgst"
                            }, {
                                name: "IGST",
                                type: "number",
                                key: "igst"
                            }, {
                                name: "Start Date",
                                type: "date",
                                key: "start_date"
                            }, {
                                name: "End Date",
                                type: "date",
                                key: "end_date"
                            }, {
                                name: "Updated By",
                                type: "text",
                                key: "updated_by"
                            }, {
                                name: "Last Updated At",
                                type: "time_stamp",
                                key: "updated_at"
                            }]}
                            data={taxRates}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => setViewTaxRatesModal(false)} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {taxRatesError && <Modal keyboard={false} backdrop="static" show={taxRatesError} onHide={() => setTaxRatesError(null)} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                    <h5>Error!</h5>
                    <p className="mb-3">{taxRatesError}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => setTaxRatesError(null)} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {viewInvoiceTermsModal && <Modal keyboard={false} backdrop="static" size='lg' show={viewInvoiceTermsModal} onHide={() => setViewInvoiceTermsModal(false)} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <h5>Previous Invoice Terms Records</h5>
                    <div style={{ overflow: "auto" }}>
                        <TableComponent
                            headers={[{
                                name: "Service Code",
                                type: "text",
                                key: "service_code"
                            }, {
                                name: "Document Type",
                                type: "text",
                                key: "document_type"
                            }, {
                                name: "Document Name",
                                type: "document",
                                key: "document_key"
                            }, {
                                name: "Start Date",
                                type: "date",
                                key: "start_date"
                            }, {
                                name: "End Date",
                                type: "date",
                                key: "end_date"
                            }, {
                                name: "Updated By",
                                type: "text",
                                key: "updated_by"
                            }, {
                                name: "Last Updated At",
                                type: "time_stamp",
                                key: "updated_at"
                            }]}
                            data={invoiceTerms}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => setViewInvoiceTermsModal(false)} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {invoiceTermsError && <Modal keyboard={false} backdrop="static" show={invoiceTermsError} onHide={() => setInvoiceTermsError(null)} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
                    <h5>Error!</h5>
                    <p className="mb-3">{invoiceTermsError}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" >
                    <Button variant="secondary" className="secondary" onClick={() => setInvoiceTermsError(null)} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {loading && <Loader />}
            <Footer />
        </div>

    )
}

import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import NavBarTop from "../Reusable_Components/NavBarTop";
import LeftNavBar from "../Reusable_Components/LeftNavBar";
import TableComponent from "../Reusable_Components/TableComponent";
import Pagination from "../Reusable_Components/Pagination";
import { GoSearch } from 'react-icons/go';
import { RECORDS_PER_PAGE_METER_MASTER } from "../../Config";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UploadComponent from '../Reusable_Components/UploadComponent';
import axios from 'axios';
import {
    getInvoicePayments,
    commercialHeadGetAllFacilitiesForNav, 
    SET_ERROR_FALSE
} from '../../Redux/Actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { base_url } from '../../utilities';
import SearchableDropdown from '../Reusable_Components/SearchableDropdown';
import AlertPopUp from '../Reusable_Components/Alert';
import {formatNumericparseFloatInput} from '../Reusable_Components/ReusableFunctions'

const FinanceManagerPayments = () => {
    const dispatch = useDispatch();

    useEffect(() => {
            dispatch(commercialHeadGetAllFacilitiesForNav())
        }, [dispatch]);

    const error = useSelector(state => state.error);
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])
    const [alertAction, setAlertAction] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setErrorValue(error?.response?.data.message);
        if (error?.response?.data.message || error?.data?.data[1]?.message) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.data?.data[1] ? error?.data?.data[1].message : error?.response?.data.message)
            setAlertButtons([{ name: 'Close', action: () => { 
                setAlertShow(false) 
                dispatch({
                    type: SET_ERROR_FALSE,
                    payload: null
                })
            } }])
        }
        setIsLoading(false);
    }, [error])

    const [facilityList, setFacilityList] = useState([]);
    const [errorValue, setErrorValue] = useState('');
    const [selectedfacility, setSelectedFacility] = useState(`${localStorage.getItem("selected_city_name")}, ${localStorage.getItem("selected_facility_name")}`);
    const facilitiesListForNav = useSelector(state => state.facilitiesListForNav);
    const [selectedFacilityId, setSelectedFacilityId] = useState(localStorage.getItem("selected_facility_id"));
    const [selectedFacilityName, setSelectedFacilityName] = useState(localStorage.getItem("selected_facility_name"));
    const [selectedCityName, setSelectedCityName] = useState(localStorage.getItem("selected_city_name"));
    const [isFacilitySelected, setIsFacilitySelected] = useState(selectedfacility); 


    useEffect(() => {
        if (facilitiesListForNav) {
            const options = facilitiesListForNav?.map((facility) => `${facility.city}, ${facility.name}`);
            setFacilityList(options);          
        }
    }, [facilitiesListForNav]);

    const [searchText, setSearchText] = useState('');
    const [amountDue, setAmountDue] = useState();

    
    

    const [invoiceTypeFilter, setInvoiceTypeFilter] = useState('');
    const [paymentStatusFilter, setPaymentStatusFilter] = useState('due');
    const [billingMonthFilter, setBillingMonthFilter] = useState(dayjs().month() + 1);
    const [billingYearFilter, setBillingYearFilter] = useState(dayjs().year());


    useEffect(() => {
        let filter_condition_validate = {};
        if(invoiceTypeFilter === '') {
            filter_condition_validate = {
                facility_id: localStorage.getItem('selected_facility_id'),
                invoice_month: billingMonthFilter,
                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        } else {
            filter_condition_validate = {
                facility_id: localStorage.getItem('selected_facility_id'),
                invoice_type: invoiceTypeFilter,
                invoice_month: billingMonthFilter,
                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        }
        dispatch(getInvoicePayments(
            {
                page_number: 1,
                items_per_page: RECORDS_PER_PAGE_METER_MASTER,
                search_text: searchText,
                filter_condition: filter_condition_validate
            }
        ))
    }, [dispatch])

    const totalRecords = useSelector((state) => state.allInvoicePayments?.info?.total_no_of_records);
    const currentPaginationPage = useSelector((state) => state.allInvoicePayments?.info?.page_number);
    const allInvoicePayments = useSelector((state) => state.allInvoicePayments?.data);
    
    
    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSendNotification = (data) => {
      console.log('Send notification for:', data);
      
    };

    const [contractName, setContractName] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [invoiceType, setInvoiceType] = useState('');
    const [issueDate, setIssueDate] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [invoicedAmount, setInvoicedAmount] = useState('');
    const [totalReceivedAmount, setTotalReceivedAmount] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [selectedPaymentId, setSelectedPaymentId] = useState('');
    

    const handleViewActionClicked = (data) => {
        console.log('View action clicked for:', data,{
            filter_condition: {
                payment_id: data.payment_id
            }
        });
        axios.get(base_url + "payments", {
            params: {
                filter_condition: JSON.stringify({
                    payment_id: data.payment_id
                })
            }
        })
        .then((response) => {
            console.log(response.data.data, "specific payments")
            const data1 = response.data.data[0];
            setContractName(data1.contract_name);
            setInvoiceNumber(data1.invoice_number);
            setInvoiceType(data1.invoice_type);
            setIssueDate(data1.issue_date);
            setDueDate(data1.due_date);
            setInvoicedAmount(data1.invoiced_amount);
            setTotalReceivedAmount(data1.total_received_amount);
            setPaymentStatus(data1.status);
            setAmountDue(data1.amount_due);
            setPaymentRecordsRows(data1.payment_details);
            setSelectedPaymentId(data1.payment_id);
        })
        .catch((error) => {
            console.log(error)
            
        })
        

        setCurrentPage(2)
    }

    const handleEditActionClicked = (data) => {
        console.log('Edit action clicked for:', data);
    }

    const handledPaginationNextClicked = () => {
        console.log('Next clicked');
        let filter_condition_validate = {};
        if(invoiceTypeFilter === '') {
            filter_condition_validate = {
                facility_id: selectedFacilityId,
                invoice_month: billingMonthFilter,
                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        } else {
            filter_condition_validate = {
                facility_id: selectedFacilityId,
                invoice_type: invoiceTypeFilter,
                invoice_month: billingMonthFilter,

                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        }
        dispatch(getInvoicePayments({
            page_number: currentPaginationPage + 1,
            items_per_page: RECORDS_PER_PAGE_METER_MASTER,
            search_text: searchText,
            filter_condition: filter_condition_validate
        }))
        
    }

    const handledPaginationPrevClicked = () => {
        console.log('Prev clicked');
        dispatch(getInvoicePayments({
            page_number: currentPaginationPage - 1,
            items_per_page: RECORDS_PER_PAGE_METER_MASTER,
            search_text: searchText,
            filter_condition: {
                facility_id: localStorage.getItem('selected_facility_id'),
                invoice_type: invoiceTypeFilter,
                invoice_month: billingMonthFilter,
                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        }))
        
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState('1');

    const [paymentRecordsRows, setPaymentRecordsRows] = useState(
      [{
          payment_date: "",
          amount_paid: "",
          account_no:"",
          instrumentation_type: "",
            note:"",
      }]
    );

    const handleAddPaymentRecordRow = () => {
        const newPaymentRecordsRow = {
            payment_id: selectedPaymentId,
            payment_date: "",
            amount_paid: "",
            account_no:"",
            instrumentation_type: "",
            note:"",   
        }
        setPaymentRecordsRows([...paymentRecordsRows, newPaymentRecordsRow]);
    }

    const handleRemovePaymentRecordRow = (index) => {
        const newPaymentRecordsRows = paymentRecordsRows.filter((row, i) => i !== index);
        setPaymentRecordsRows(newPaymentRecordsRows);
    }

    const handleBillingPeriodChange = (newValue) => {
        setBillingMonthFilter(dayjs(newValue).month()+1);
        setBillingYearFilter(dayjs(newValue).year());
    }

    const applyFilterClicked = () => {
        console.log('Apply filter clicked');
        localStorage.setItem("selected_facility_id", selectedFacilityId);
        localStorage.setItem("selected_facility_name", selectedFacilityName);
        localStorage.setItem("selected_city_name", selectedCityName);

        let filter_condition_validate = {};

        if(invoiceTypeFilter === '') {
            filter_condition_validate = {
                facility_id: selectedFacilityId,
                invoice_month: billingMonthFilter,
                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        } else {
            filter_condition_validate = {
                facility_id: selectedFacilityId,
                invoice_type: invoiceTypeFilter,
                invoice_month: billingMonthFilter,
                invoice_year: billingYearFilter,
                status: paymentStatusFilter
            }
        }
        dispatch(getInvoicePayments(
            {
                page_number: 1,
                items_per_page: RECORDS_PER_PAGE_METER_MASTER,
                search_text: searchText,
                filter_condition: filter_condition_validate
            }
        ))
    }

    const clearFilterClicked = () => {
        console.log('Clear filter clicked');
        setInvoiceTypeFilter('');
        setPaymentStatusFilter('due');
        setBillingMonthFilter(1);
        setBillingYearFilter(2025);
        setSearchText('');
        dispatch(getInvoicePayments(
            {
                page_number: 1,
                items_per_page: RECORDS_PER_PAGE_METER_MASTER,
                search_text: '',
                filter_condition: {
                    facility_id: localStorage.getItem('selected_facility_id'),
                    invoice_month: dayjs().month() + 1,
                    invoice_year: dayjs().year(),
                    status: 'due'
                }
            }
        ))
    }

    const handlePaymentRecordsRowChange = (e, index, key) => {
        const newPaymentRecordsRows = paymentRecordsRows.map((row, i) => {
            if (i === index) {
                return {
                    ...row,
                    [key]: key === 'amount_paid' ? parseFloat(formatNumericparseFloatInput(e.target.value)) : e.target.value
                }
            }
            return row;
        });
        setPaymentRecordsRows(newPaymentRecordsRows);
    }

    const handleSavePayment = () => {
        console.log({
            contract_name: contractName,
            invoice_number: invoiceNumber,
            invoice_type: invoiceType,
            issue_date: issueDate,
            due_date: dueDate,
            invoiced_amount: invoicedAmount,
            total_received_amount: totalReceivedAmount,
            status: paymentStatus,
            amount_due: amountDue,
            payment_details: paymentRecordsRows
        })
        axios.put(base_url + "payments", {
            payments: paymentRecordsRows
        })
        .then((response) => {
            console.log(response, "payment records saved")
        })
        .catch((error) => {
            console.log(error)
            
        })
    }




    return (
      <>
            <>
                <NavBarTop />
                <LeftNavBar />

            </>
            {
              currentPage === 1 &&
            <div className='meter_reports_container'>
                <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
              <div className='d-flex justify-content-between align-items-center flex-wrap'>
                  <div className='d-flex align-items-center gap-3 mb-3'>
                      <h3 className="invoice-heading" >Payments</h3>
                  </div>
              </div>
              


                <div className='fm-invoice-con-filters'>
                    <div style={{ width: '280px' }} className="fin_man_billing_period">
                        <p style={{ marginBottom: '-15px' }}>Facility:&nbsp;</p>
                        <SearchableDropdown
                        style={{zIndex: 9999}}
                            // className = "searchable-dropdown2"
                            options = {facilityList? facilityList:[]} //facilityList is the array of facilities
                            selectedOption = {selectedfacility}
                            noOptionsAvailable = "Facility unavailable"
                            onChange = {(e) => {
                                setSelectedFacility(e.value)
                                const selectedFacility = facilitiesListForNav?.find((facility) => `${facility.city}, ${facility.name}` === e.value);
                                setSelectedFacilityName(selectedFacility?.name);
                                setSelectedCityName(selectedFacility?.city);
                                setSelectedFacilityId(selectedFacility?.facility_id);
                                setIsFacilitySelected(true);
                            }}
                            
                            placeholder = "Select Facility"
                            isDisabled = {false}
                        />
                    </div>
                    <div style={{ width: '140px' }} className="fin_man_billing_period">
                            <p style={{ marginBottom: '5px' }}>Invoice Type:&nbsp;</p>
                            <select
                                value={invoiceTypeFilter}
                                onChange={(e) => {
                                    setInvoiceTypeFilter(e.target.value);
                                }}
                                className="invoice-select-date"
                                style={{ width: "100%", height: "2.5rem" }}
                            >
                                <option value={""}>All Invoices</option>
                                <option value="water">Water</option>
                                <option value="electricity">Electricity</option>
                                <option value="slf">SLF</option>
                                <option value="fms">FMS</option>
                                <option value="miscellaneous">Miscellaneous</option>
                                { }
                            </select>
                        </div>
                        <div style={{ width: '120px' }} className="fin_man_billing_period">
                            <p style={{ marginBottom: '5px' }}>Payment Status:&nbsp;</p>
                            <select
                                value={paymentStatusFilter}
                                onChange={(e) => {
                                    setPaymentStatusFilter(e.target.value);
                                }}
                                className="invoice-select-date"
                                style={{ width: "100%", height: "2.5rem" }}
                            >
                                <option value="due">Due</option>
                                <option value="partiallypaid">Partially-Paid</option>
                                <option value="fullypaid">Fully-Paid</option>
                                
                                { }
                            </select>
                        </div>
                        <div className="fin_man_billing_period" style={{ width: '180px' }}>
                            <p style={{ marginBottom: '1px' }}>Billing Period:&nbsp;</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label=""
                                value={dayjs(`${billingYearFilter}-${billingMonthFilter}`)}
                                views={['month', 'year']}
                                // minDate={dayjs('2022-01-01')} // Set minimum selectable date
                                // maxDate={dayjs('2025-01-08')} // Set minimum selectable date
                                onChange={(newValue) => {
                                    handleBillingPeriodChange(newValue);
                                }
                                }
                                sx={{
                                  '.MuiInputBase-root': {
                                    height: '40px', // Adjust height
                                    backgroundColor: 'white', // Set background to white
                                    borderRadius: '6px', // Optional: Rounded corners
                                    fontSize: '14px', // Optional: Adjust text size
                                  },
                                }}
                                slotProps={{
                                  textField: {
                                    InputProps: {
                                      readOnly: true, // Prevent typing in the input field
                                    },
                                    onKeyDown: (e) => e.preventDefault(), // Block keyboard inputs
                                  },
                                }}
                              />
                            </LocalizationProvider>


                        </div>
                        <div className='d-flex align-items-center gap-3 mb-3'>
                            <div className='fin_man_meter_search_bar_container'>
                                <input style={{ marginTop: '20px', height: '2.5rem' }} type='search' placeholder='Search' value={searchText}
                                    onChange={handleSearchChange}
                                    className='fin_man_meter_search_bar' />
                                <GoSearch
                                    
                                    style={{ marginTop: '17px' }} className='fin_man_meter_search_bar_icon C-pointer' />
                            </div>

                        </div>
                        <div className='d-flex align-items-center gap-3'>
                        <button className="invoice_btn" style={{ marginTop: '4px', color: 'white', height: '20px !important' }} onClick={() => {
                            applyFilterClicked()
                        }}>Apply</button>
                        <button className="invoice_btn" style={{ marginTop: '4px', color: 'white', height: '20px !important' }} 
                        onClick={() => {
                            clearFilterClicked()
                        }}
                            >Clear</button>

                        </div>
                        
                </div>
     
     
                <div className='table_container_commercial_head'>
                          <TableComponent
                            headers = {[
                              {
                                name: "Contract Name ",
                                type: "text",
                                key: "contract_name",
                                sort: true
                            },
                              {
                                name: "Invoice Number",
                                type: "text",
                                key: "invoice_number",
                                sort: true
                            }, {
                                name: "Invoice Type ",
                                type: "text",
                                key: "invoice_type",
                                sort: true
                            }, 
                            {
                                name:"Issue Date",
                                type:"date",
                                key:"issue_date",
                                sort:true
                            }, 
                            {
                                name: "Due Date",
                                type: "date",
                                key: "due_date",
                                sort: true
                            },
                            {
                                name: "Invoiced Amount",
                                type: "number",
                                key: "invoiced_amount",
                                sort: true
                            },
                            {
                                name: "Total Received Amount",
                                type: "number",
                                key: "total_received_amount",
                                sort: true
                            }, {
                                name: "Amount Due",
                                type: "number",
                                key: "amount_due",
                                sort: true
                            }, 
                            {
                              name: "Payment Status",
                              type: "text",
                              key: "status",
                              sort: true
                          },
                          {
                            name: "Updated By",
                            type: "text",
                            key: "updated_by",
                            sort: true
                        },
                        {
                          name: "Last Updated At",
                          type: "time_stamp",
                          key: "updated_at",
                          sort: true
                      },
                            {
                                name: "Action",
                                type: "button",
                                buttons: [{
                                    name: "View",
                                    icon: "./images/icons/Vectoredit.svg",
                                    action: (data) => handleViewActionClicked(data)
                                }, 
                                {
                                    name: "Edit",
                                    icon: "./images/icons/sendnotification.svg",
                                    action: (data) => handleEditActionClicked(data)
                                }
                            
                            ]
                            }] }

                            data={allInvoicePayments}
                            />
                    
                </div>
                         <Pagination
                                        handledPaginationNextClicked={handledPaginationNextClicked}
                                        handledPaginationPrevClicked={handledPaginationPrevClicked}
                                        isApplyClicked
                                        totalRecords={totalRecords}
                                        recordsPerPage={RECORDS_PER_PAGE_METER_MASTER}
                                        currentPaginationPage={currentPaginationPage}
                                    />
            </div>
            }
            {
                currentPage === 2 &&
                <div className="newPaymentContainer">
                        <div style={{display:"flex", flexDirection:"column", margin:'10px', marginBottom:"100px"}}>
                            <div>
                                <h2>Payment Details</h2>
                            </div>
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <label className='add-facility-modal-label' style={{flexWrap:"nowrap", marginRight:"10px"}}>Contract Name: </label>
                                     <spam><strong>{"Contract Name"}</strong></spam>
                            </div>
                            <div style={{marginTop:"20px"}}>
                                <Tabs
                                    activeKey={activeTab}
                                    onSelect={(k) => setActiveTab(k)}
                                    id="uncontrolled-tab-example"
                                    className="mb-0"
                                    >
                                    <Tab eventKey="1" title="Payment Details" 
                                    className="assetTabs" 
                                    // disabled={!viewClicked && !editClicked}
                                    >
                                      <div className="infoBody">
                                          <div>
                                              <h7 style={{ fontWeight: "bold" }}>Invoice Information</h7>
                                              <div className="infoBodyInputs">
                                                  <div className="responsiveInputSection">
                                                      <div >
                                                          <label className='add-facility-modal-label' 
                                                          >Contract Name </label>
                                                          <input type='text'
                                                          value={contractName}
                                                          disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                          
                                                      </div>
                                                      <div >
                                                          <label className='add-facility-modal-label' 
                                                          >Invoice Number </label>
                                                          <input type='text'
                                                            value={invoiceNumber}
                                                          disabled={true}
                                                          className='form-control responsive-add-facility-input-fields' />
                                                          
                                                      </div>
                                                      <div>
                                                          <label className='add-facility-modal-label'>Invoice Type</label>
                                                          <input type='text'
                                                            value={invoiceType}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                      </div>
                                                  </div>
                                                  <div className="responsiveInputSection">
                                                      <div >
                                                          <label className='add-facility-modal-label' 
                                                          >Issued Date </label>
                                                          <input type='date'
                                                            value={issueDate}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                      </div>
                                                      <div >
                                                          <label className='add-facility-modal-label' 
                                                          >Due Date </label>
                                                          <input type='date'
                                                            value={dueDate}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                      </div>
                                                      <div>
                                                          <label className='add-facility-modal-label'>Payment Status</label>
                                                          <input type='text'
                                                          value={paymentStatus}
                                                          disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                      </div>
                                                  </div>
                                                  <div className="responsiveInputSection">
                                                      <div >
                                                          <label className='add-facility-modal-label' 
                                                          >Invoiced Amount</label>
                                                          <input type='number'
                                                            value={invoicedAmount}
                                                            disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                          
                                                      </div>
                                                      <div >
                                                          <label className='add-facility-modal-label' 
                                                          >Total Amount Received </label>
                                                          <input 
                                                          type='number' 
                                                          value={totalReceivedAmount}
                                                          disabled={true}
                                                          className='form-control responsive-add-facility-input-fields' />
                                                      </div>
                                                      <div>
                                                          <label className='add-facility-modal-label'>Due Amount</label>
                                                          <input type='text'
                                                            value={amountDue}
                                                          disabled={true}
                                                            className='form-control responsive-add-facility-input-fields' />
                                                      </div>
                                                  </div>
                                                  
                                              </div>
                                          </div>
                                          <hr></hr>
                                          <div>
                                              <h7 style={{ fontWeight: "bold" }}>Payment Records</h7>
                                              <div className="infoBodyInputs">
                                                  <div className='license_fee_details_table'>
                                                      <table>
                                                          <thead className='fin_man_table_head commercial-head-table text-center'>
                                                              <tr>
                                                                  <th>S.No</th>
                                                                  <th>Payment Date <span style={{ color: "red" }}>*</span></th>
                                                                  <th>Payment Amount (<span><img src="./images/icons/rupee.svg" alt='ruppee symbol' /></span>)<span style={{ color: "red" }}>*</span></th>
                                                                  <th>Account No. <span style={{ color: "red" }}>*</span></th>
                                                                  <th style={{ textWrap: "nowrap" }}>Mode Of Payment<span style={{ color: "red" }}>*</span></th>
                                                                  <th style={{ textWrap: "nowrap" }}>note</th>
                                                                  
                                                              </tr>
          
                                                          </thead>
                                                          <tbody className='fin_man_table_body'>
                                                              {
                                                                paymentRecordsRows.length > 0 ?
                                                                // <>
                                                                  paymentRecordsRows.map((row, index) => {
                                                                      return (
                                                                          <tr key={index}>
                                                                              <td>{index+1}</td>
                                                                              <td>
                                                                                  <input type='date'
                                                                                      value={row.payment_date}
                                                                                      disabled={row?.payment_detail_id}
                                                                                      onChange={(e) => handlePaymentRecordsRowChange(e, index, 'payment_date')}
                                                                                      className='form-control'
                                                                                  />
                                                                              </td>
                                                                              <td>
                                                                                  <input type='number'
                                                                                      value={row.amount_paid}
                                                                                      disabled={row?.payment_detail_id}
                                                                                      onChange={(e) => handlePaymentRecordsRowChange(e, index, 'amount_paid')}
                                                                                      className='form-control'
                                                                                  />
                                                                              </td>
                                                                                <td>
                                                                                    <input type='text'
                                                                                        value={row.account_no}
                                                                                        disabled={row?.payment_detail_id}
                                                                                        onChange={(e) => handlePaymentRecordsRowChange(e, index, 'account_no')}
                                                                                        className='form-control'
                                                                                    />
                                                                                </td>
                                                                              <td>
                                                                                  <input type='text'
                                                                                      value={row.instrumentation_type}
                                                                                      disabled={row?.payment_detail_id}
                                                                                      onChange={(e) => handlePaymentRecordsRowChange(e, index, 'instrumentation_type')}
                                                                                      className='form-control'
                                                                                  />
                                                                              </td>
                                                                              <td>
                                                                                  <input type='text'
                                                                                      value={row.note}
                                                                                      disabled={row?.payment_detail_id}
                                                                                      onChange={(e) => handlePaymentRecordsRowChange(e, index, 'note')}
                                                                                      className='form-control'
                                                                                  />
                                                                              </td>

                                                                          </tr>
                                                                      )
                                                                  })
                                                                //   </>
                                                                  :
                                                                    <tr>
                                                                        <td colSpan={5} className='text-center'>No Records Found, Please add a record</td>
                                                                    </tr>
                                                              } 
                                                              
                                                          </tbody>
                                                      </table>
                                                  </div>
                                                    <div style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
                                                        <img style={{ height: "18px", marginRight: "10px" }}
                                                            onClick={handleAddPaymentRecordRow}
                                                            src="./images/icons/PlusaddItem.svg" alt="add"
                                                            title="Add row below"
                                                        />
                                                        {
                                                            ((paymentRecordsRows.length > 1) && !(paymentRecordsRows[paymentRecordsRows.length - 1]?.payment_detail_id)) &&
                                                        <img style={{ height: "18px" }} src="./images/icons/MinusremoveItem.svg"
                                                            onClick={() => handleRemovePaymentRecordRow(paymentRecordsRows.length - 1)}
                                                            alt="remove"
                                                            title="Delete last row"
                                                        />
                                                        }
                                                    </div> 
                                                  </div>
                                              </div>
                                              <div style={{marginTop:"20px"}}>
                                                <UploadComponent
                                                    // onFilesSelected={handleFilesSelected}
                                                    uploadMethod="single"
                                                    // viewMode={viewClicked}
                                                    // uploadedFileData={documentsListArray}
                                                    // uploadActionClicked={handleDocumentUpload}
                                                    // deleteSpecificFileMain={confirmDeleteDocument}
                                                    maxNumberOfFiles={5}
                                                    // handleErrorFromDocument={handleErrorFromDocument}
                                                    idType={"asset"}
                                                    acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                                                />
                                            </div>
                                          </div>
                                        
                                    </Tab>
                                </Tabs>
                            </div>
                            <div style={{marginTop:"20px",}}>
                            <div style={{display:"flex", justifyContent:"end", }}>
                                <button className="invoice_btn" style={{marginTop:"20px", color:"white"}}
                                onClick={handleSavePayment}
                                >Save</button>
                                <button className="invoice_btn"
                                onClick={()=>{setCurrentPage(1)}}
                                style={{marginTop:"20px", color:"white", marginLeft:"10px"}}>Cancel</button>
                               </div>
                                </div>
                        </div>
                    </div>
            }
      </>
         
    )
}
export default FinanceManagerPayments;

import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import Webcam from "react-webcam";
import { Button, Table } from "react-bootstrap";
import {useSelector, useDispatch} from 'react-redux';
import { IoMdInformationCircleOutline } from "react-icons/io";
import {  technicianSubmitMeterReadings, technicianSubmitMeterImage, technicianUpdateMeterReadings, commercialHeadGetContractsForDropdownTechnician } from "../../Redux/Actions/Actions";
import axios from "../../axios-interceptor"
import Loader from "../Reusable_Components/loader";
import ImageUpload from "./uploadImage";
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import AlertPopUp from "../Reusable_Components/Alert";
import { base_url } from "../../utilities";
import { formatDateView } from "../Reusable_Components/ReusableFunctions";
import { formatNumericInput, handleKeyDownForMeterReadings } from "../Reusable_Components/ReusableFunctions";
import Footer from "../Login/Footer";


const WaterMeterReadings = () => {
    const facilityName = localStorage.getItem("selected_facility_name")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [isPhotoCaptured ,setIsPhotoCaptured] = useState(false)
  const [capturedImage,setCapturedImage] = useState()
  const camRef = useRef(null)

  const [previewPopUp,setPreviewPopUp] = useState(false)
  const [selectMeterImage,setSelectedMeterImage] = useState('')
  const [spaceName,setSpaceName]  = useState('')
  const [listOfMeterValues,setListOfMeterValues] = useState([])
   const [contractName,setContractName] = useState('')
  const [errorPopUp,setErrorPopUp] = useState(false)
  const [errorPopUpMessage,setErrorPopUpMessage] = useState('')
  const [selectedMeterIndex,setSelectedMeterIndex] = useState(0) 
  const [documentsUploadData,setDocumentsUploadData] = useState([])
  const [cameraMeterName,setCameraMeterName] = useState('')
  const [validSubmit,setValidSubmit] = useState(false)
  const [confirmMeterReading,setConfirmMeterReading] = useState('')

  const allContracts = useSelector((state) => state.contractsForDropdownTechnician);
  const contractApiLoader = useSelector((state) => state.contractApiLoader);
  const dispatch = useDispatch('');
  const [errorValue,setErrorValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const error = useSelector((state) => state.error);
const isSubmittedMeter = useSelector((state) => state.submittedMeter)
const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showSubmitPopAlert, setSubmitPopAlert] = useState(false)
  const [showUploadPopUp,setShowUploadPopUp] = useState(false)
  const [todayDate,setTodayDate] = useState(()=>{
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate
    // const today = new Date(date);
    // const yesterday = new Date(today);
    // yesterday.setDate(yesterday.getDate() + 1);
    // const yesterdayDate = yesterday.toISOString().split('T')[0];
    // return yesterdayDate
  })
  const [uploadedAlert,setUploadedAlert] = useState(false)
  //for alert pop up
    const [alertShow,setAlertShow] = useState(false)
    const [alertType,setAlertType] = useState('')
    const [alertMessage,setAlertMessage] = useState('')
    const [alertValue,setAlertValue] = useState('')
    const [alertButtons,setAlertButtons] = useState([])
    const [alertAction,setAlertAction] = useState([])


      useEffect(()=>{
        if(errorPopUpMessage==='duplicate'||errorPopUpMessage==='overwrite'){
            uploadIamgeFunction()
            setTimeout(()=>setErrorPopUpMessage(''),1000)
        }
    },[errorPopUpMessage])

  useEffect(()=>{
    if(allContracts.length!==0){
        setIsLoading(false)
    }
  },[allContracts])

// useEffect(()=>{
//     setListOfMeterValues()
//     // setFacilityName("ACLC-I")
//     // setSpaceName("UNIT-12")
// },[])
//checking meters valid or not
useEffect(() => {
    const isValid = listOfMeterValues.every(meter =>
        meter.assigned_name &&
        meter.reading_type &&
        meter.meter_reading !== '' &&
        meter.meter_image !== ''
    );
    setValidSubmit(isValid);
}, [listOfMeterValues]);

    useEffect(()=>{
        console.log(documentsUploadData, 'documentsUploadData over written')
        documentsUploadData?.forEach(e=>{
            const index = listOfMeterValues.findIndex(meter=>meter.meter_id===e.id_value)
            if(index!==-1){
                const updatedMeters = [...listOfMeterValues]
                updatedMeters[index].image_url = e.key
                setListOfMeterValues(updatedMeters)
            }
        })
         if(uploadedAlert){
            setTimeout(()=>handleUpdate(),2000)
        }else{
            setTimeout(()=>handleSubmit(),2000)
        }
    },[documentsUploadData])



useEffect(() => {
    // dispatch(commercialHeadGetContracts());
    dispatch(commercialHeadGetContractsForDropdownTechnician({facility_id: localStorage.getItem("selected_facility_id")}))
},[]);

//after submit meter api success
useEffect(()=>{
    if(isSubmittedMeter === 'success'){
        setIsLoading(false)
        // setShowSuccessModal(true)
        setAlertShow(true)
        setAlertType('success')
        setAlertMessage('Success')
        setAlertValue(uploadedAlert?'Meter Readings are Updated Successfully':'Meter Readings are Submitted Successfully')
        setAlertButtons([
            {name:'Close',action: ()=>{
            setAlertShow(false)
            window.location.reload()
            }},
            // {name:'Close',action: ()=>{
            // setAlertShow(false)
            // window.location.reload()
            // }}
        ])
    }
},[isSubmittedMeter]);

useEffect(() => {
    if(allContracts.length !== 0){
        setIsLoading(false);
    }
},[allContracts]);

useEffect(() =>{
    setErrorValue(error?.response?.data.message);
    if(error?.response?.data.message){
    setAlertShow(true)
    setAlertType('error')
    setAlertMessage('Error')
    setAlertValue(error?.response?.data.message)
    setAlertButtons([{name:'Close',action: ()=>{setAlertShow(false)}}])
    }
    setIsLoading(false);
},[error]);


//for rear camera view 
const videoConstraints = {
    // Use 'user' for front camera, 'environment' for back camera
    facingMode: 'environment', 
  };

  const  takePicture = () => {
    setCapturedImage(camRef?.current?.getScreenshot());
    setIsPhotoCaptured(true)
  }
  const retakePicture = () => {
    setIsPhotoCaptured(false)
  }
  const [selectedContractId, setSelectedContractId] = useState("")
const changeContract = (id) => {
    const selectedContract = allContracts?.filter(e => e.contract_id === id)[0];
    setSelectedContractId(id)
    setSpaceName(selectedContract.space_name);
    setContractName(selectedContract.conttract_name)
    setListOfMeterValues([])
    setIsLoading(true)
    setUploadedAlert(false)
    getMeters(id)
};

const getMeters= (id) => {
     const yesterday = new Date(todayDate);
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayDate = yesterday.toISOString().split('T')[0];

    axios.get(base_url+"facilities/meterReadings/meterReadingsDetails?filter_condition=%7B%22contract_id%22%3A%22"+id+"%22%2C%22reading_date%22%3A%22"+todayDate+"%22%7D")
    .then(response=>{
        if(response?.data?.data[0]==='error'){
            setErrorValue(response?.data?.data[1].message)
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(response?.data?.data[1].message)
            setAlertButtons([
                {name:'Close',action: ()=>{
                setAlertShow(false)
                // window.location.reload()
                }},
            ])
            setIsLoading(false)
        }
        else{
        const data = response.data.data
        const waterData = data.filter(e=>e.meter_type === 'water')
        const waterDatawithImages = waterData.filter(e=>e.image_url!==null)
        const waterDataWithoutImages = waterData.filter(e=>e.image_url===null)
        waterDataWithoutImages.forEach(e=>{
            e.toBeCreated = true
        })
        const firstData = waterDatawithImages[0]
        
        if(firstData?.reading_date===todayDate && firstData?.meter_reading!==null){
            setUploadedAlert(true)

            // for electricity meter images
            let url = '%7B%22s3_keys%22%3A%5B%22'
            url = url+firstData.image_url
            waterDatawithImages?.slice(1)?.forEach(e => {
                url = url + '%22%2C%22' + e.image_url;
            });
            url = url+'%22%5D%7D'
            axios.get(base_url+'documents?filter_condition='+url)
            .then(response=>{
                const imageList = response.data.data
                imageList.forEach(async (e,index)=>{
                    if(index<waterData.length){
                       waterData[index].meter_image = 'data:image/webp;base64,'+e.file.split(`webpbase64`)[1].slice(0,-1)
                    }
                })
                const newDataForMeterValues = waterDatawithImages.map(item => ({ ...item }));
                waterDataWithoutImages.forEach(e=>{
                    e.meter_image = ''
                    e.reading_type = 'water'
                    e.reading_date= todayDate
                    e.meter_image=''
                    e.meter_reading = 0.0
                    e.reading_unit='kL'
                })
                newDataForMeterValues.push(...waterDataWithoutImages)
                const newDataForMeterValues1 = newDataForMeterValues.filter(item=> item.meter_reading !== null);
                setListOfMeterValues([...newDataForMeterValues1]);
            })
            .catch(error=>{
                setErrorValue(error?.response?.data.message);
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{name:'Close',action: ()=>{
                    setAlertShow(false)
                // window.location.reload()
            }}])
            setIsLoading(false)
            })
        }else if(firstData?.reading_date===todayDate){
            waterData.forEach(e=>{
                e.reading_type = e.meter_type
                e.reading_date= todayDate
                e.meter_image=''
                e.meter_reading = 0.0
                e.reading_unit='kL'
            })
            const newDataForMeterValues = waterData.map(item => ({ ...item }));
            setListOfMeterValues(newDataForMeterValues);
            setIsLoading(false)
        }//if it is not today's date
        else if(firstData?.reading_date===yesterdayDate){
            waterData.forEach(e=>{
                e.reading_type = e.meter_type
                e.reading_date= todayDate
                e.meter_image=''
                e.reading_unit='kL'
            })
            const newDataForMeterValues = waterData.map(item => ({ ...item }));
            setListOfMeterValues(newDataForMeterValues);
            setIsLoading(false)
        }else{
            waterData.forEach(e=>{
                e.reading_type = e.meter_type
                e.reading_date= todayDate
                e.meter_image=''
                e.meter_reading = 0.0
                e.reading_unit='kL'
            })
        }


        if(waterData.length!==0){
            const newDataForMeterValues = waterData.map(item => ({ ...item }));
            setListOfMeterValues(newDataForMeterValues);
        }
        setIsLoading(false)
    }
    })
    .catch(error=>{
        setErrorValue(error?.response?.data.message);
        setAlertShow(true)
        setAlertType('error')
        setAlertMessage('Error')
        setAlertValue(error?.response?.data.message)
        setAlertButtons([{name:'Close',action: ()=>{
            setAlertShow(false)
            // window.location.reload()
        }}])
        setIsLoading(false)
    })
  }


const handleSubmit = () => {
    const dataArray = [];
    listOfMeterValues.forEach(e => {
        dataArray.push({
            "meter_id": e.meter_id,
            "meter_name": e.assigned_name,
            "meter_reading": e.meter_reading,
            "reading_type": e.reading_type,
            "reading_date": e.reading_date,
            "image_url" : e.image_url,
            "reading_unit" : e.reading_unit,
        });
    });
    if(dataArray.length!==0){
        const data = {"meter_readings_list": [...dataArray]}
        dispatch(technicianSubmitMeterReadings(data))
        setIsLoading(true)
    }
}

const handleUpdate = () => {
    const dataArrayUpdate = [];
    const dataArrayCreate = [];
    listOfMeterValues.forEach(e => {
        if(e.toBeCreated){
            dataArrayCreate.push({
                "meter_id": e.meter_id,
                "meter_name": e.assigned_name,
                "meter_reading": e.meter_reading,
                "reading_type": e.reading_type,
                "reading_date": e.reading_date,
                "image_url" : e.image_url,
                "reading_unit" : e.reading_unit,
            });
        }else{
        dataArrayUpdate.push({
            "meter_id": e.meter_id,
            "meter_name": e.assigned_name,
            "meter_reading": e.meter_reading,
            "reading_type": e.reading_type,
            "reading_date": e.reading_date,
            "image_url" : e.image_url,
            "reading_unit" : e.reading_unit,
        });
        }
    });
     if(dataArrayUpdate.length!==0){
        const data = {"meter_readings_list": [...dataArrayUpdate]}
        dispatch(technicianUpdateMeterReadings(data))
        if(dataArrayCreate.length!==0){
            const dataCreate = {"meter_readings_list": [...dataArrayCreate]}
            dispatch(technicianSubmitMeterReadings(dataCreate))
        }
        setIsLoading(true)
    }
}

  const uploadIamgeFunction = () => {
  ImageUpload(selectedContractId,listOfMeterValues,[],false,contractName,setErrorPopUp,setDocumentsUploadData,errorPopUpMessage,setErrorValue,setIsLoading,setAlertShow,setAlertType,setAlertMessage,setAlertValue,setAlertButtons)

  }


    return (
        <>
        <>
                      <NavBarTop />
                      <LeftNavBar />
                    </>
        {/* {error || errorValue ?
        <Alert show={errorValue} key={"danger"} variant={"danger"}
          style={{ position: "absolute", width: "96%", zIndex: "1000", margin: "10px" }}>
          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
            {errorValue}
            <img src="./images/icons/Close.svg" style={{ height: "20px" }} alt="close" onClick={() => {
              setErrorValue(null);
            }} />
          </span>
        </Alert>
        : null
      } */}

        <div className="meter-readings">
            <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
           <div className="meter-reading-heading">
                <h1>Water Meter Readings</h1>
                <Link to={'/technicianDashboard'} className="link-tags"><img src="./images/icons/Close.svg"/></Link>
           </div>
           <div className="meter-reading-body">
                <p className='meter-reading-header'>FACILITY : {facilityName} 13</p>
                <p className='meter-reading-header2'>Date : {formatDateView(todayDate)}</p>
                <div className="meter-reading-inner-div">
                    <p>Contract Name <span style={{color:"red"}}>*</span></p>
                            <select onChange={(event) => changeContract(event.target.value)}>
                                <option hidden>{allContracts?.length > 0 ? 'Select' : 'No Contracts'}</option>
                                {allContracts?.map((e,index) =>(
                                    <option key={index} value={e.contract_id}>{e.contract_name}</option>
                                ))}
                                {/*  */}
                            </select>
                </div>
                <div className="meter-reading-inner-div">
                    <p>Space</p>
                    <input value={spaceName} disabled={true}/>
                </div>
                
                {listOfMeterValues.length !== 0 ?
                <div>
                    {uploadedAlert && <p className="input-error-text-1"><IoMdInformationCircleOutline /> Your meter readings are already Uploaded for the day</p>}
                    {/* Meter Readings */}
                    <Table className="meter-reading-table">
                    <thead className="meter-reading-thead">
                        <tr>
                            <td style={{flexGrow:2}}>Meter Name</td>
                            <td style={{flexGrow:1}}>Reading <span style={{color:"red"}}>*</span></td>
                            <td>Image <span style={{color:"red"}}>*</span></td>
                        </tr>
                    </thead>
                    <tbody className="meter-reading-tbody">
                        {listOfMeterValues.map((each,index)=>{
                            return (
                            <tr id={index} key={index}>
                                <td style={{flexGrow:3}}>{each.assigned_name} </td>
                                <td  style={{flexGrow:0}}>
                                    <div className='meter-reading'>
                                        <input className="table-meter-reading" value={each.meter_reading} 
                                          onChange={e=>{
                                            const input = e.target.value;
                                            const formattedInput = formatNumericInput(input, 3);
                                            const updatedReading = [...listOfMeterValues]
                                            updatedReading[index].meter_reading = formattedInput
                                            updatedReading[index].meter_image = ''
                                            updatedReading[index].image_url = ''
                                            setListOfMeterValues(updatedReading)
                                          }}
                                          onKeyDown={handleKeyDownForMeterReadings}
                                          />
                                        <select className="meter-reading-select" 
                                        onChange={(e)=>{
                                            const updatedReading = [...listOfMeterValues]
                                            updatedReading[index].reading_type = e.target.value
                                            setListOfMeterValues(updatedReading)
                                        }}
                                        value={each.reading_type}>
                                            <option value='kL'>kL</option>
                                            
                                        </select>
                                    </div>
                                </td>
                                <td >
                                <div className="d-flex justify-content-center">
                                    {each.meter_image?.length === 0  ?
                                    each.meter_reading==='' ?
                                        <img  src='./images/icons/camera.png' style={{ cursor: 'pointer',opacity:'50%' }}  alt='camera icon'/> 
                                        :
                                        <img  src='./images/icons/camera.png' style={{ cursor: 'pointer' }} 
                                        onClick={()=>{
                                            setSelectedMeterIndex(index)
                                            setCameraMeterName(each.assigned_name)
                                            setIsPhotoCaptured(false)
                                            setCapturedImage('')
                                            setConfirmMeterReading(each.meter_reading)
                                            setShow(true)
                                        }} alt='camera icon'/>
                                         
                                     : 
                                    <div>
                                        <img  src='./images/icons/captured.svg' style={{ cursor: 'pointer' }} 
                                        alt="captured" 
                                        onClick={()=>{
                                            setSelectedMeterImage(each)
                                            setPreviewPopUp(true)
                                        }}/>
                                        <img src='./images/icons/Close.svg' 
                                         className="close-icon"
                                         onClick={()=>{
                                            const updatedMeters = [...listOfMeterValues]
                                            updatedMeters[index].meter_image = ''
                                            setListOfMeterValues(updatedMeters)
                                         }}
                                         alt="close icon"
                                        />
                                    </div>
                                    }
                                </div>
                                </td>
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {
                    uploadedAlert?
                    <div className="meter-readings-buttons">
                        <Link to={'/technicianDashboard'} className="link-tags meter-readings-cancel">Cancel</Link>
                        <button className="meter-readings-save"
                        disabled={!validSubmit}
                        style={!validSubmit?{opacity:'50%'}:{opacity:'100%'}}
                        onClick={()=>setShowUploadPopUp(true)}
                        >Update</button>
                </div>
                    :
                <div className="meter-readings-buttons">
                        <Link to={'/technicianDashboard'} className="link-tags meter-readings-cancel">Cancel</Link>
                        <button className="meter-readings-save"
                        disabled={!validSubmit}
                        style={!validSubmit?{opacity:'50%'}:{opacity:'100%'}}
                        onClick={()=>setSubmitPopAlert(true)}
                        >Submit</button>
                </div>
                }
                </div>
                :
                spaceName !== '' && !isLoading ?
                <div className="meter-readings-inner-div d-flex justify-content-center mt-3">
                    <p>There are no meters for selected contract</p>
                    </div>
                    :
                    <div className="meter-readings-inner-div d-flex justify-content-center mt-3">
                        {allContracts?.length > 0 && <p> Please Select a Contract</p>}
                        </div>
                    }
                

            </div>
            {/* popup to take pic */}
            <Modal keyboard={false} backdrop="static" centered show={show}
             onHide={handleClose} animation={false}>
                <Modal.Header>
                <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="show-camera">
                           {!isPhotoCaptured && <Webcam className="show-camera-div" ref={camRef} videoConstraints={videoConstraints}/>}
                           {isPhotoCaptured && <img className="show-camera-div-img" src={capturedImage} alt="meter-reading"/>}
                        </div>
                        {isPhotoCaptured ? 
                        <button className="show-camera-button" onClick={()=>retakePicture()}>Retake the image</button> :
                        <button className="show-camera-button" onClick={()=>takePicture()}>take a photo</button> 
                        }
                        <div className="mt-2">
                            <p className="d-flex justify-content-between align-items-center">Confirm Reading : 
                            <input className="" value={confirmMeterReading}
                                    onChange={(e)=>{
                                        const input = e.target.value
                                        const parts = input.replace(/[^0-9.]/g, '').split('.');
                                        const integerPart = parts[0];
                                        const decimalPart = parts.length > 1 ? '.' + parts[1].substring(0, 2) : '';
                                        const formattedInput = integerPart + decimalPart;
                                        setConfirmMeterReading(formattedInput)
                                    }}/></p>
                        </div>
                        <div className="show-camera-buttons">
                            <button onClick={()=>{
                                setShow(false)
                                setIsPhotoCaptured(false)
                                }} className="meter-readings-cancel">Back</button>
                                
                            <button className="meter-readings-save"
                            onClick={()=>{
                                const updatedMeters = [...listOfMeterValues]
                                 updatedMeters[selectedMeterIndex].meter_image = capturedImage
                                 updatedMeters[selectedMeterIndex].meter_reading = confirmMeterReading
                                 setListOfMeterValues(updatedMeters)
                                 setShow(false)
                             }}
                            disabled={capturedImage?.length<=0}
                            style={capturedImage?.length<=0?{opacity:'50%'}:{opacity:'100%'}}
                            >Save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* popup to confirm  */}
            <Modal keyboard={false} backdrop="static" centered show={previewPopUp} onHide={()=>{
                setPreviewPopUp(false)
                setSelectedMeterImage('')
                }} animation={false}>
                <Modal.Header>
                <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Meter Name : {selectMeterImage?.assigned_name}</p>
                        <div className="show-camera">
                           <img className="show-camera-div-img" src={selectMeterImage?.meter_image} alt="meter-reading"/>
                        </div>
                        
                        <div className="mt-2">
                            <p className="d-flex justify-content-between align-items-center">Confirmed Reading : 
                            {selectMeterImage?.meter_reading}</p>
                        </div>
                        <div className="show-camera-buttons">
                            <button onClick={()=>{
                                setPreviewPopUp(false)
                                setSelectedMeterImage('')
                                }} className="meter-readings-cancel">Back</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> 
            {(isLoading && !errorValue ) && <Loader /> }
            {contractApiLoader && <Loader />}
            {/* success modal */}
            {/* <Modal keyboard={false} backdrop="static" backdrop="static" show ={showSuccessModal} onHide={()=>setShowSuccessModal(false)} centered className="popper-modal">
                <Modal.Body className="text-center mt-4">
                    <img src= "./images/icons/popper.svg" alt="Success" />
                    <h5>Success!</h5>
                    {
                        uploadedAlert ? <p>Meter Readings are Updated for Contract <b>{contractName}</b></p>
                        :
                        <p className="mb-1">Meter Readings are Submitted for Contract <b>{contractName}</b></p>
                    }
                </Modal.Body>
                <Modal.Footer className="footer d-flex justify-content-center">
                    <Button variant ="secondary" className="secondary" onClick = {()=>{
                        setShowSuccessModal(false)
                        window.location.reload()
                    }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}
            <Modal keyboard={false} backdrop="static"  show ={errorPopUp} onHide={()=>setShowSuccessModal(false)} centered className="popper-modal">
                <Modal.Body className="text-center mt-4">
                    {/* <img src= "./images/icons/popper.svg" alt="Party Popper" /> */}
                    <h5>Warning!</h5>
                    <p className="mb-1">You already have this meter Readings</p>
                </Modal.Body>
                <Modal.Footer className="footer d-flex justify-content-center">
                    <Button variant ="secondary" className="secondary" onClick = {()=>{
                        setErrorPopUp(false)
                        // window.location.reload()
                    }} >
                        Cancel
                    </Button>
                    <Button variant ="secondary" className="secondary" onClick = {()=>{
                       setErrorPopUpMessage('duplicate')
                    }} >
                        Duplicate
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* submit popup */}
            <Modal keyboard={false} backdrop="static" show={showSubmitPopAlert} onHide={()=>{setSubmitPopAlert(false)}} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <h5>{`Are you sure you want to Submit these readings?`}</h5>
                </Modal.Body>
                <Modal.Footer className="footer">
                    <Button variant="secondary" className="confirmation" onClick={() => {setSubmitPopAlert(false)}}>Cancel</Button>
                    <Button variant="secondary" className="confirmation-left" onClick={() => {
                        uploadIamgeFunction()
                        setSubmitPopAlert(false)
                    }
                    }>Proceed</Button>
                </Modal.Footer>
            </Modal>
            {/* update popup */}
            <Modal keyboard={false} backdrop="static" show={showUploadPopUp} onHide={()=>{setShowUploadPopUp(false)}} centered className="popper-modal">
                <Modal.Body className="text-center">
                    <h5>{`Are you sure you want to Update?`}</h5>
                </Modal.Body>
                <Modal.Footer className="footer">
                    <Button variant="secondary" className="confirmation" onClick={() => {setShowUploadPopUp(false)}}>Cancel</Button>
                    <Button variant="secondary" className="confirmation-left" onClick={() => {
                        uploadIamgeFunction()
                        setShowUploadPopUp(false)
                    }
                    }>Proceed</Button>
                </Modal.Footer>
            </Modal>
        </div>
        <Footer/>
        </>
        
    )
}

export default WaterMeterReadings
import React, { useEffect, useState } from 'react'
import Task from './Task';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';

export default function WorkLaw({ data, facilitiesList, columns, sections, handelSectionChange, handleChangeTaskStatus, showTaskModal }) {

    const [statuses, setStatuses] = useState([]);
    const [tasks, setTasks] = useState([]);


    useEffect(() => {
        setStatuses(columns);
        setTasks(data);
    }, [data]);

    const handleManageSection = (tab) => {
        handelSectionChange(tab);
    }

    const handleOpenTask = (task) => {
        showTaskModal(task)
    };

    const handleDrop = (item, status) => {
        handleChangeTaskStatus(item, status);
    }

    const getInitials = (name) => {
        return name?.split(' ')
            .map(word => word[0].toUpperCase())
            .join('');
    };

    return (
        <>
            <div style={{ overflow: "auto", flex: "1 1 auto" }}>
                <DndProvider backend={HTML5Backend}>
                    <table style={{ width: "100%" }}>
                        <thead style={{ position: "sticky", top: 0 }}>
                            <tr>
                                {columns?.map((column, index) => {
                                    const taskCount = Object.values(tasks).flat().filter(task => task.task_status.toLowerCase() === column.toLowerCase()).length;
                                    return (
                                        <th key={index} className='tasks-header-items-container'>
                                            <div className='tasks-header-items'>
                                                {column.charAt(0).toUpperCase() + column.slice(1)} ({taskCount})
                                            </div>
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {tasks && Object?.entries(tasks)?.map(([category, tasks], index) => (
                                <React.Fragment key={index}>
                                    <tr role='button' onClick={() => tasks?.length > 0 && handleManageSection(category)} style={{ position: "sticky", top: "40px" }}>
                                        <td style={{ backgroundColor: "white", minHeight: "40px", display: "flex", alignItems: "center", gap: "20px", padding: "0px 10px" }}>
                                            <img
                                                src={'./images/icons/RightArrow.svg'}
                                                alt={sections[index]?.isOpen ? "Down arrow" : "Right arrow"}
                                                className={`arrow ${sections[index]?.isOpen ? 'arrow-down' : 'arrow-right'}`}
                                            />
                                            <div className='d-flex gap-2'>
                                                <span>{category}</span>
                                                <span>({tasks.length} tasks)</span>
                                            </div>
                                        </td>
                                        {statuses.map((status, index) => (
                                            <td key={index} style={{ backgroundColor: "white", minHeight: "40px" }}></td>
                                        )
                                        )}
                                    </tr>
                                    {tasks.length > 0 && sections[index]?.isOpen && (
                                        <tr>
                                            {statuses.map(status => (
                                                <TaskContainer task={tasks} key={status} status={status} tasks={tasks} handleOpenTask={handleOpenTask} facilitiesList={facilitiesList} changeStatus={handleDrop} />
                                            ))}
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </DndProvider>
            </div>
        </>
    )
}


const TaskContainer = ({ status, tasks, handleOpenTask, facilitiesList, changeStatus }) => {

    const handleDrop = (item) => {
        changeStatus(item, status); // Pass the status (column name) to the changeStatus function
    }

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'TASK',
        drop: (item, monitor) => handleDrop(item, monitor),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    return (
        <td key={status} className='tasks-body-items' ref={drop}>
            {tasks.filter(task => task.task_status.toLowerCase() === status.toLowerCase()).map((task, i) => {
                const facilityColor = facilitiesList.find(facility => facility.name === task.facility)?.backgroundColor;
                return <Task key={i} task={{ ...task, facilityColor }} handleOpenTask={handleOpenTask} />
            })}
        </td>
    )
}
import React from 'react'
import { Link } from 'react-router-dom'
import { COMPANY_NAME } from '../../Config'

export default function PageNotFound() {
  return (
    <div style={{ backgroundColor: "#F5F5F", height: "100vh" }} className="d-flex justify-content-center align-items-center">
      <div style={{ padding: "10px" }} className="text-center">
      <h1 style={{fontWeight: "bolder", fontSize:"60px"}}>{COMPANY_NAME}</h1>
        <h1 style={{ fontSize: "100px" }}>404</h1>
        <h1 style={{ fontSize: "28px" }}>PAGE NOT FOUND !</h1>
        <h1 style={{ fontSize: "14px" }}>The page you are looking for could not be found</h1>
        <Link to={localStorage.getItem("group") === "Commercial Head" ? "/commercialHeadDashboard" :
          localStorage.getItem("group") === "Finance Manager" ? "/financeManagerDashboard" :
            localStorage.getItem("group") === "Facility Manager" ? "/facilityManagerMeterMaster" :
              localStorage.getItem("group") === "CEO" ? "/adminControls" :
                localStorage.getItem("group") === "Technician" ? "/technicianDashboard" : "/"} style={{ fontSize: "14px" }
                }>Go to Home</Link>
      </div>
    </div>
  )
}

import React from 'react'
import {Link} from 'react-router-dom'
import { COMPANY_NAME } from '../../Config'

export default function Unauthorized() {
  return (
    <div style={{ backgroundColor: "#F5F5F", height: "100vh" }} className="d-flex justify-content-center align-items-center">
    <div style={{ padding: "10px" }} className="text-center">
    <h1 style={{fontWeight: "bolder", fontSize:"60px"}}>{COMPANY_NAME}</h1>

      <h1 style={{ fontSize: "100px" }}>401</h1>
      <h1 style={{ fontSize: "28px" }}>Unauthorized</h1>
      <h1 style={{ fontSize: "14px" }}>Token Expired, Please <Link to={"/"}>login</Link> again</h1>
    </div>
  </div>
  )
}

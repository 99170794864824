import React, { useEffect, useState, useRef } from "react";
import { GoSearch } from "react-icons/go";
import axios from "../../axios-interceptor"
import { FaSearch } from "react-icons/fa";
import Spinner from 'react-bootstrap/Spinner';
import { Stack } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import TableComponent from "../Reusable_Components/TableComponent";
import {
    commercialHeadAddNewContract, commercialHeadUpdateDraftContract,
    commercialHeadAddNewContractDelete, commercialHeadSubmitContract,
    commercialHeadGetAllMeters, commercialHeadGetDepartments, commercialHeadGetUtilityRates,
    commercialEditCompleteContract, commercialHeadUploadDocument, commercialHeadGetBillingEntities, commercialHeadGetDocuments,
    handleDocumentLoaderTrue, handleDocumentLoaderFalse, handlePaginationLoaderTrue,
    commercialHeadGetContractsForDropdown, clearCommercialHeadDocumentContent,
    PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
    UPDATE_COMPLETE_CONTRACT_SUCCESS,
    UPDATE_CH_DRAFT_CONTRACT_SUCCESS,
    COMMERCIAL_HEAD_GET_DOCUMENTS_SUCCESS,
    PUT_CH_SUBMIT_CONTRACT_SUCCESS,
    SET_ERROR_FALSE,
    CREATE_NEW_COMPANY_SUCCESS,
    getAllCompaniesList,
    createNewCompany,
    PUT_ADD_MISC_INVOICE_FAILURE,
    updateCompanyDetails,
    UPDATE_COMPANY_SUCCESS,
    getAllCompaniesListDropdown
} from '../../Redux/Actions/Actions'
import { commercialHeadGetContracts } from "../../Redux/Actions/Actions";
import { commercialHeadGetDraftContracts } from "../../Redux/Actions/Actions";
import Loader from "../Reusable_Components/loader";
import { IoMdInformationCircleOutline } from "react-icons/io";
import Alert from 'react-bootstrap/Alert';
import { base_url } from "../../utilities";
import UploadComponent from '../Reusable_Components/UploadComponent';

import { MdInfoOutline } from "react-icons/md";
import NavBarTop from "../Reusable_Components/NavBarTop";
import LeftNavBar from "../Reusable_Components/LeftNavBar";
import AlertPopUp from "../Reusable_Components/Alert";
import { GrView } from "react-icons/gr";
import Pagination from "../Reusable_Components/Pagination";
import { RECORDS_PER_PAGE } from "../../Config";
import { set } from "date-fns";
import {
    timeElapsed, validateValue, formatNumericInput, formatNumericParseIntInput,
    validEmailCheck, formatNumericparseFloatInput, processAndValidateEmail,
    licenseFeeDateValidationByStartDateEndDate,
    eleminateSpecialCharactersAtStartOfInputField, eleminateSpacesAtStartOfInputField, handleCinInputChange, validateGstNumber,
    checkUniqueness, handleKeyPressForGst, handleKeyDownForMeterReadings
} from "../Reusable_Components/ReusableFunctions";

import { MdErrorOutline } from 'react-icons/md';
import Footer from "../Login/Footer";

export default function CompaniesListPage() {
  const dispatch = useDispatch();
  const contractApiLoader = useSelector(state => state.contractApiLoader);
    const getAllCompaniesForDropdown = useSelector(state => state.getAllCompaniesForDropdown)
  const steps = [
    {
        name: "1. Company Information",
        description: "Company and Contact Details"
    },
]
  useEffect(() => {
    dispatch(getAllCompaniesListDropdown()) 
    dispatch(getAllCompaniesList({serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: 1 }))
  }, [dispatch])

  const getAllCompanies = useSelector(state => state.getAllCompanies)
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [contactName, setContactName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
const [isValidPhone, setIsValidPhone] = useState(true);
const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
const [isContactNameValid, setIsContactNameValid] = useState(true);
  const [viewClicked, setViewClicked] = useState(false);
  const [email, setEmail] = useState('');
const [isValidEmail, setIsValidEmail] = useState(true);
const [gstNumber, setGstNumber] = useState('');
const [isValidGstNumber, setIsValidGstNumber] = useState(true);
const [gstLoader, setGstLoader] = useState(false);
const [companyName, setCompanyName] = useState('');
const [errorValue, setErrorValue] = useState('');
const [panName, setPanName] = useState('');
const [isLoading, setIsLoading] = useState(false);
const [alertShow, setAlertShow] = useState(false);
const [alertButtons, setAlertButtons] = useState([]);
const [alertValue, setAlertValue] = useState('');
const [alertMessage, setAlertMessage] = useState('');
const [alertType, setAlertType] = useState('');
const [registeredStateProvince, setRegisteredStateProvince] = useState('');
const [registeredPostalCode, setRegisteredPostalCode] = useState('');
const [shippingStateProvince, setShippingStateProvince] = useState('');
const [registeredStreetAddress, setRegisteredStreetAddress] = useState('');
const [registeredStreetAddressLane2, setRegisteredStreetAddressLane2] = useState('');
const [registeredCity, setRegisteredCity] = useState('');
const [billingStateProvince, setBillingStateProvince] = useState('');
const [cinNumber, setCinNumber] = useState('');
const [isCinValid, setIsCinValid] = useState(true);
const [billingStreetAddress, setBillingStreetAddress] = useState('');
const [billingStreetAddressLane2, setBillingStreetAddressLane2] = useState('');
const [billingCity, setBillingCity] = useState('');
const [isPincodeValid, setIsPincodeValid] = useState(true);
const [isCityValid, setIsCityValid] = useState(true);
const [billingPostalCode, setBillingPostalCode] = useState('');
const [shippingStreetAddress, setShippingStreetAddress] = useState('');
const [shippingStreetAddressLane2, setShippingStreetAddressLane2] = useState('');
const [shippingCity, setShippingCity] = useState('');
const [isShippingPincodeValid, setIsShippingPincodeValid] = useState(true);
const [isRegisteredPincodeValid, setIsRegisteredPincodeValid] = useState(true);
const [isRegisteredStateProvinceValid, setIsRegisteredStateProvinceValid] = useState(true);
const [isStreetAdd1Valid, setIsStreetAdd1Valid] = useState(true);
const [isStreetAdd2Valid, setIsStreetAdd2Valid] = useState(true);
const [shippingPostalCode, setShippingPostalCode] = useState('');
const error = useSelector(state => state.error)
const [registeredAddId, setRegisteredAddId] = useState('');
const [billingAddId, setBillingAddId] = useState('');
const [shippingAddId, setShippingAddId] = useState('');

const createNewCompanyResponse = useSelector(state => state.createNewCompanyResponse)
const updateCompanyResponse = useSelector(state => state.updateCompanyResponse)

const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false);

const handleCloseCloseConfirmationModal = () => {
    setShowCloseConfirmationModal(false)
}



useEffect(() => {
    if (createNewCompanyResponse) {
        setAlertShow(true)
        setAlertType('success')
        setAlertMessage('Success')
        setAlertValue(<>
        <p className="mb-3">Company <strong>{createNewCompanyResponse?.company_name}</strong> created Successfully</p>
        </>)
        setAlertButtons([{
            name: 'Close', action: () => {
                setAlertShow(false)
                dispatch({
                    type: CREATE_NEW_COMPANY_SUCCESS,
                    payload: null
                })
                backfunction()
                dispatch(getAllCompaniesListDropdown())
                dispatch(getAllCompaniesList({serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPage }))
            }
        }])
    }

}, [createNewCompanyResponse])

useEffect(() => {
    if (updateCompanyResponse) {
        setAlertShow(true)
        setAlertType('success')
        setAlertMessage('Success')
        setAlertValue(<>
        <p className="mb-3">Company <strong>{selectedItem?.company_name}</strong> updated Successfully</p>
        </>)
        setAlertButtons([{
            name: 'Close', action: () => {
                setAlertShow(false)
                dispatch({
                    type: UPDATE_COMPANY_SUCCESS,
                    payload: null
                })
                backfunction()
                dispatch(getAllCompaniesListDropdown())
                dispatch(getAllCompaniesList({serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPage }))
            }
        }])
    }
}, [updateCompanyResponse])

useEffect(() => {
    dispatch({
        type: PUT_ADD_MISC_INVOICE_FAILURE,
        payload: null
    })
    if (error) {
        setAlertShow(true)
        setAlertType('error')
        setAlertMessage('Error')
        setAlertValue(error)
        setAlertButtons([{
            name: 'Close', action: () => {
                setAlertShow(false)
                dispatch({
                    type: PUT_ADD_MISC_INVOICE_FAILURE,
                    payload: null
                })
            }
        }])
    }
    setIsLoading(false);
}, [error]);

const handleCinNumberChange = (e) => {
  handleCinInputChange(e, setCinNumber, setIsCinValid);
};

const [isGstInList, setIsGstInList] = useState(false);

const handleGstNumberChange = (e) => {
  const input = e.target.value;
  const { isValid, uppercasedInput } = validateGstNumber(input);
  const isGstInList = getAllCompaniesForDropdown.some(
    (company) => company.gst_number === uppercasedInput
);
if(uppercasedInput.length === 0){
    setIsGstInList(false)
}
else{
    if(selectedItem?.gst_number === uppercasedInput){
        setIsGstInList(false)
    }
    else{

        setIsGstInList(isGstInList);
    }

}
  setGstNumber(uppercasedInput);
  setIsValidGstNumber(isValid);
};

  const handleEmailChange = (e) => {
    const input = e.target.value;

    const { processedInput, isValid } = processAndValidateEmail(input, email);

    setEmail(processedInput);
    setIsValidEmail(isValid);
};

  const changePageNext = () => {
    // extractContractNameNumber();

    if (currentPage <= steps.length) {
        setCurrentPage(currentPage + 1)
    }
    else {
        setCurrentPage(1)
        // setViewClicked(false)
        // window.location.reload();
        // dispatch({
        //     type: PUT_CH_ADD_NEW_CONTRACT_SUCCESS,
        //     payload: null,
        // })
        // backfunction()
        // searchFunc(searchText)
    }
}

const handleGstClick = (gstId) => {
  setGstLoader(true)
  axios.get(base_url + "gstPortal/verifyGstin?gst_number=" + gstId)
      .then((response) => {
          if (response?.data?.data[0] === 'error') {
              setErrorValue(response?.data?.data[1].message)

              setAlertShow(true)
              setAlertType('error')
              setAlertMessage('Error')
              setAlertValue(response?.data?.data[1].message)
              setAlertButtons([
                  {
                      name: 'Close', action: () => {
                          setAlertShow(false)
                      }
                  },
              ])
              setIsLoading(false)
              setGstLoader(false);
          }
          else {
              const details = response.data.data
              setCompanyName(details?.company_name)
              setPanName(details?.pan_number)
              setRegisteredStateProvince(details?.registered_address?.state)
              setRegisteredPostalCode(details?.registered_address?.postal_code)
              setRegisteredStreetAddress(details?.registered_address?.door_number + details?.registered_address?.street_address_1)
              setRegisteredStreetAddressLane2(details?.registered_address?.street_address_2)
              setRegisteredCity(details?.registered_address?.city)

              setBillingStateProvince(details?.registered_address?.state);
             
              setShippingStateProvince(details?.registered_address?.state);
             
              setGstLoader(false)
          }
      })
      .catch((error) => {
          const errorDetails = JSON.parse(error.response.data.error_logs.status_desc);
          const errorMessage = errorDetails[0].ErrorMessage
          setErrorValue(errorMessage);

          setAlertShow(true);
          setAlertType('error');
          setAlertMessage('Error');
          setAlertValue(errorMessage);
          setAlertButtons([
              {
                  name: 'Close',
                  action: () => {
                      setAlertShow(false);
                  }
              }
          ]);
          setIsLoading(false);
          setGstLoader(false);
      });
}

const GstLoader = () => {
  return <Spinner style={{ height: "18px", width: "18px" }} animation="border" variant="light" />
}

const sameAsRegisteredAddress = () => {
  setBillingStreetAddress(registeredStreetAddress)
  setBillingStreetAddressLane2(registeredStreetAddressLane2)
  setBillingCity(registeredCity)
  setBillingStateProvince(registeredStateProvince)
  setBillingPostalCode(registeredPostalCode)
}

const sameAsBillingAddress = () => {
  setShippingStreetAddress(billingStreetAddress)
  setShippingStreetAddressLane2(billingStreetAddressLane2)
  setShippingCity(billingCity)
  setShippingStateProvince(billingStateProvince)
  setShippingPostalCode(billingPostalCode)
}

const [listOfContacts, setListOfContacts] = useState([{
  name: "",
  phone_number: "",
  email: "",
  designation: "",
}])

const [listOfContactsValidation, setListOfContactsValidation] = useState([{
  isContactNameValid: true,
  isDesignationValid: true,
  isValidPhone: true,
  isValidEmail: true,
  isEmailNotDuplicate: true
}])

const handleAddAnotherContactValidation = () => {
  setListOfContactsValidation([...listOfContactsValidation, {
      isContactNameValid: true,
      isDesignationValid: true,
      isValidPhone: true,
      isValidEmail: true,
      isEmailNotDuplicate: true
  }])

}

const handleAddAnotherContact = () => {
  setListOfContacts([...listOfContacts, {
      name: "",
      phone_number: "",
      email: "",
      designation: "",
  }])

}
const [showDeleteContractContact, setShowDeleteContractContact] = useState(false);
    const [deleteContractContactSelected, setDeleteContractContactSelected] = useState();
    const [deleteContractContactIdSelected, setDeleteContractContactIdSelected] = useState();

const deleteSpecificContact = (id, contact) => {

  if (contact.contact_id) {
      setShowDeleteContractContact(true)
      setDeleteContractContactSelected(contact)
      setDeleteContractContactIdSelected(id)
  }
  else {
      const newArray = listOfContacts.filter((_, index) => index !== id);
      const newVaildArray = listOfContactsValidation.filter((_, index) => index !== id);
      setListOfContacts([...newArray]);
      setListOfContactsValidation([...newVaildArray])
  }


}
const [listOfContactEmails, setListOfContactEmails] = useState([]);


const handleEmailUpdateChange = (e, index) => {

  const input = e.target.value;
  const currentEmail = listOfContactEmails[index] ? listOfContactEmails[index] : '';
  const { processedInput, isValid } = processAndValidateEmail(input, currentEmail);

  const updatedContacts = [...listOfContacts];
  const updatedContactsValidation = [...listOfContactsValidation];
  const isUnique = checkUniqueness(listOfContactEmails, currentEmail, processedInput);

  updatedContacts[index] = { ...updatedContacts[index], email: processedInput };
  updatedContactsValidation[index] = { ...updatedContactsValidation[index], isValidEmail: isValid, isEmailNotDuplicate: !isUnique };

  setListOfContacts(updatedContacts);
  setListOfContactsValidation(updatedContactsValidation);
};

const onEmailInputBlur = (e) => {
  const emailArray = []
  listOfContacts.forEach((contact) => {
      if (!emailArray.includes(contact.email) && contact.email !== "") {
          emailArray.push(contact.email)
      }
  })
  setListOfContactEmails([...emailArray])
}

const handleConfirmDeleteContractContact = () => {
  setShowDeleteContractContact(false);
  axios.delete(base_url + `facilities/companies/companyContactRef`,
      {
          params: {
              contact_id: deleteContractContactSelected.contact_id,
              company_id: selectedCompanyId
          }
      }

  )
      .then((response) => {
          if (response?.data?.data[0] === 'error') {
              setErrorValue(response?.data?.data[1].message)
              setAlertShow(true)
              setAlertType('error')
              setAlertMessage('Error')
              setAlertValue(response?.data?.data[1].message)
              setAlertButtons([
                  {
                      name: 'Close', action: () => {
                          setAlertShow(false)
                      }
                  },
              ])
              setIsLoading(false)
          }
          else {
              const newArray = listOfContacts.filter((_, index) => index !== deleteContractContactIdSelected);
              const newVaildArray = listOfContactsValidation.filter((_, index) => index !== deleteContractContactIdSelected);
              setListOfContacts([...newArray]);
              setListOfContactsValidation([...newVaildArray])
          }
      })
      .catch((error) => {
          setErrorValue(error?.response?.data.message);
          setAlertShow(true)
          setAlertType('error')
          setAlertMessage('Error')
          setAlertValue(error?.response?.data.message)
          setAlertButtons([{
              name: 'Close', action: () => {
                  setAlertShow(false)
              }
          }])
          setIsLoading(false)
      })

}

const currentPaginationPage = useSelector(state => state.getAllCompanies?.info?.page_number)



const handledPaginationNextClicked = () => {
    
    handlePaginationLoaderTrue()
    dispatch(getAllCompaniesList({serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPage + 1 }))
}

const handledPaginationPrevClicked = () => {
    
    dispatch(getAllCompaniesList({serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPage - 1 }))
    

}

const searchFunc = (value) => {
    if (validateValue(value)) {
        setSearchText(value);
        dispatch(getAllCompaniesList({serach_text: value, items_per_page: RECORDS_PER_PAGE, page_number: 1 }))
    }

};

 const handleSubmit = () => {
    let submitObj = {
        company_name: companyName.trim(),
        primary_contact: phoneNumber.trim(),
        primary_contact_email: email.trim(),
        primary_contact_name:   contactName.trim(),
        pan_number: panName.trim(),
        gst_number: gstNumber.trim(),
        cin: cinNumber.trim(),
        registered_address: {
            street_address_1: registeredStreetAddress.trim(),
            street_address_2: registeredStreetAddressLane2.trim(),
            city: registeredCity.trim(),
            door_number: "door2",
            country: "India",
            state: registeredStateProvince.trim(),
            postal_code: registeredPostalCode,
        },
        "billing_address": {
            street_address_1: billingStreetAddress.trim(),
            street_address_2: billingStreetAddressLane2.trim(),
            door_number: "door1",
            country: "India",
            city: billingCity.trim(),
            state: billingStateProvince.trim(),
            postal_code: billingPostalCode,
        },
        "shipping_address": {
            street_address_1: shippingStreetAddress.trim(),
            street_address_2: shippingStreetAddressLane2.trim(),
            door_number: "door1",
            country: "India",
            city: shippingCity.trim(),
            state: shippingStateProvince.trim(),
            postal_code: shippingPostalCode,
        },
        "contacts": listOfContacts,
    }
    if(!selectedCompanyId){
        dispatch(createNewCompany(submitObj));
    }
    else{
        submitObj.company_id = selectedCompanyId;
        submitObj.registered_address.address_id = registeredAddId;
        submitObj.billing_address.address_id = billingAddId;
        submitObj.shipping_address.address_id = shippingAddId;
        
        dispatch(updateCompanyDetails(submitObj));
    }
 }

 const backfunction = () => {
    setSelectedItem(null)
    setSelectedCompanyId(null)
    setCurrentPage(1)
    setViewClicked(false)
    setCompanyName('')
    setIsCinValid(true)
    setIsContactNameValid(true)
    setIsCompanyNameValid(true)
    setIsValidPhone(true)
    setIsValidEmail(true)
    setIsValidGstNumber(true)
    setContactName('')
    setPhoneNumber('')
    setEmail('')
    setGstNumber('')
    setPanName('')
    setCinNumber('')
    setRegisteredAddId('')
    setBillingAddId('')
    setShippingAddId('')
    setRegisteredStateProvince('')
    setIsStreetAdd1Valid(true)
    setIsStreetAdd2Valid(true)
    setIsCityValid(true)
    setIsRegisteredStateProvinceValid(true)
    setIsRegisteredPincodeValid(true)
    setIsPincodeValid(true)
    setIsShippingPincodeValid(true)
    setRegisteredPostalCode('')
    setRegisteredStreetAddress('')
    setRegisteredStreetAddressLane2('')
    setRegisteredCity('')
    setBillingStateProvince('')
    setBillingPostalCode('')
    setBillingStreetAddress('')
    setBillingStreetAddressLane2('')
    setIsBillingStreetAdd1Valid(true)
    setIsBillingStreetAdd2Valid(true)
    setIsBillingCityValid(true)
    setIsBillingStateValid(true)
    setIsShippingStreetAdd1Valid(true)
    setIsShippingStreetAdd2Valid(true)
    setIsShippingCityValid(true)
    setIsShippingStateValid(true)
    setBillingCity('')
    setSelectedCompanyId(null)
    setShippingStateProvince('')
    setShippingPostalCode('')
    setShippingStreetAddress('')
    setShippingStreetAddressLane2('')
    setShippingCity('')
    handleCloseCloseConfirmationModal()
    setIsGstInList(false)
    setIsCompanyInList(false)
    setListOfContacts([{
        name: "",
        phone_number: "",
        email: "",
        designation: "",
    }])
    setListOfContactsValidation([{
        isContactNameValid: true,
        isDesignationValid: true,
        isValidPhone: true,
        isValidEmail: true,
        isEmailNotDuplicate: true
    }])
    setListOfContactEmails([])
    setSelectedItem(null)
 }

 const [selectedCompanyId, setSelectedCompanyId] = useState(null);
 const [selectedItem, setSelectedItem] = useState(null);

 const handleViewClicked = (data) => {
    setIsLoading(true)
    setViewClicked(true)
    setSelectedItem(data)
    setSelectedCompanyId(data.company_id)
    axios.get(base_url + "facilities/companies?filter_condition=%7B%22company_id%22%3A%20%22" + data.company_id + "%22%7D")
    .then((response) => {
        if (response?.data?.data[0] === 'error') {
            setErrorValue(response?.data?.data[1].message)

            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(response?.data?.data[1].message)
            setAlertButtons([
                {
                    name: 'Close', action: () => {
                        setAlertShow(false)
                    }
                },
            ])
            setIsLoading(false)
        }
        else {
            
            const details = response.data.data[0]
            setContactName(details?.primary_contact_name)
            setPhoneNumber(details?.primary_contact)
            setEmail(details?.primary_contact_email)
            setGstNumber(details?.gst_number)
            setCinNumber(details?.cin)
            

            setCompanyName(details?.company_name)
            setPanName(details?.pan_number)
            setRegisteredAddId(details?.registered_address?.address_id)
            setRegisteredStateProvince(details?.registered_address?.state)
            setRegisteredPostalCode(details?.registered_address?.postal_code)
            setRegisteredStreetAddress(details?.registered_address?.street_address_1)
            setRegisteredStreetAddressLane2(details?.registered_address?.street_address_2)
            setRegisteredCity(details?.registered_address?.city)

            setBillingAddId(details?.billing_address?.address_id)
            setBillingStateProvince(details?.billing_address?.state);
            setBillingPostalCode(details?.billing_address?.postal_code);
            setBillingStreetAddress(details?.billing_address?.street_address_1);
            setBillingStreetAddressLane2(details?.billing_address?.street_address_2);
            setBillingCity(details?.billing_address?.city);

            setShippingAddId(details?.shipping_address?.address_id)
            setShippingStateProvince(details?.shipping_address?.state);
            setShippingPostalCode(details?.shipping_address?.postal_code);
            setShippingStreetAddress(details?.shipping_address?.street_address_1);
            setShippingStreetAddressLane2(details?.shipping_address?.street_address_2);
            setShippingCity(details?.shipping_address?.city);

            if (details?.contacts === "None" || !details?.contacts) {
                setListOfContacts([{
                    contactName: "",
                    phoneNumber: "",
                    email: "",
                    designation: "",
                }]);
            }
            else {
                let contactsString = details?.contacts;

                const parsedContacts = contactsString;
                setListOfContacts(parsedContacts)


                const listOfEmailArray = parsedContacts.map((contact) => contact.email)
                setListOfContactEmails([...listOfEmailArray])
                const defaultContactsValidation = Array.from({ length: parsedContacts.length }, () => ({
                    isContactNameValid: true,
                    isDesignationValid: true,
                    isValidPhone: true,
                    isValidEmail: true,
                    isEmailNotDuplicate: true
                }));
                setListOfContactsValidation([...defaultContactsValidation])
            }
            setIsLoading(false)
            changePageNext()

        }
    }
    )
    .catch((error) => {
        setErrorValue(error?.response?.data.message);

        setAlertShow(true)
        setAlertType('error')
        setAlertMessage('Error')
        setAlertValue(error?.response?.data.message)
        setAlertButtons([{
            name: 'Close', action: () => {
                setAlertShow(false)
            }
        }])
        setIsLoading(false)
    });

 }
 const [isCompanyInList,setIsCompanyInList] = useState(false)


 const handleEditClicked = (data) => {
    setSelectedCompanyId(data.company_id)
    setSelectedItem(data)
    setIsLoading(true)
    setViewClicked(false)
    axios.get(base_url + "facilities/companies?filter_condition=%7B%22company_id%22%3A%20%22" + data.company_id + "%22%7D")
    .then((response) => {
        if (response?.data?.data[0] === 'error') {
            setErrorValue(response?.data?.data[1].message)

            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(response?.data?.data[1].message)
            setAlertButtons([
                {
                    name: 'Close', action: () => {
                        setAlertShow(false)
                    }
                },
            ])
            setIsLoading(false)
        }
        else {
            const details = response.data.data[0]
            setContactName(details?.primary_contact_name)
            setPhoneNumber(details?.primary_contact)
            setEmail(details?.primary_contact_email)
            setGstNumber(details?.gst_number)
            setCinNumber(details?.cin)
            

            setCompanyName(details?.company_name)
            setPanName(details?.pan_number)
            setRegisteredAddId(details?.registered_address?.address_id)
            setRegisteredStateProvince(details?.registered_address?.state)
            setRegisteredPostalCode(details?.registered_address?.postal_code)
            setRegisteredStreetAddress(details?.registered_address?.street_address_1)
            setRegisteredStreetAddressLane2(details?.registered_address?.street_address_2)
            setRegisteredCity(details?.registered_address?.city)

            setBillingAddId(details?.billing_address?.address_id)
            setBillingStateProvince(details?.billing_address?.state);
            setBillingPostalCode(details?.billing_address?.postal_code);
            setBillingStreetAddress(details?.billing_address?.street_address_1);
            setBillingStreetAddressLane2(details?.billing_address?.street_address_2);
            setBillingCity(details?.billing_address?.city);

            setShippingAddId(details?.shipping_address?.address_id)
            setShippingStateProvince(details?.shipping_address?.state);
            setShippingPostalCode(details?.shipping_address?.postal_code);
            setShippingStreetAddress(details?.shipping_address?.street_address_1);
            setShippingStreetAddressLane2(details?.shipping_address?.street_address_2);
            setShippingCity(details?.shipping_address?.city);

            if (details?.contacts === "None" || !details?.contacts) {
                setListOfContacts([{
                    contactName: "",
                    phoneNumber: "",
                    email: "",
                    designation: "",
                }]);
            }
            else {
                let contactsString = details?.contacts;
                // contactsString = contactsString.replace(/'/g, '"');
                // contactsString = contactsString.replace(/True/g, 'true').replace(/False/g, 'false');

                const parsedContacts = contactsString;
                setListOfContacts(parsedContacts)


                const listOfEmailArray = parsedContacts.map((contact) => contact.email)
                setListOfContactEmails([...listOfEmailArray])
                const defaultContactsValidation = Array.from({ length: parsedContacts.length }, () => ({
                    isContactNameValid: true,
                    isDesignationValid: true,
                    isValidPhone: true,
                    isValidEmail: true,
                    isEmailNotDuplicate: true
                }));
                setListOfContactsValidation([...defaultContactsValidation])
            }
                
            setIsLoading(false)
            changePageNext()

        }
    }
    )
    .catch((error) => {
        setErrorValue(error?.response?.data.message);

        setAlertShow(true)
        setAlertType('error')
        setAlertMessage('Error')
        setAlertValue(error?.response?.data.message)
        setAlertButtons([{
            name: 'Close', action: () => {
                setAlertShow(false)
            }
        }])
        setIsLoading(false)
    });
 }

 const contactValidationsFunc = () => {
    const isValidContactListValidations = listOfContactsValidation.some(e =>
        e.isContactNameValid === false ||
        e.isDesignationValid === false ||
        e.isValidPhone === false ||
        e.isValidEmail === false
    );
    const isValidContactList = listOfContacts.some(e =>
        !e.name ||
        // !e.phone_number ||
        !e.email
        // ||
        // !e.designation 
    );

    console.log(isValidContactList, isValidContactListValidations, "validating")
    console.log((!isValidContactList && !isValidContactListValidations), "verify")
    return (!isValidContactList && !isValidContactListValidations)
}


 const missingFieldsCalculatePage2 = () => {
    return `${!email ? "Email, " : ""}
            ${!isValidEmail ? "Email, " : ""}
            ${!contactName ? "Primary Contact Name, " : ""}
            ${!isContactNameValid ? "Contact Name, " : ""}
            ${!phoneNumber ? "Phone Number, " : ""}
            ${!isValidPhone ? "Phone Number, " : ""}
             
             ${!companyName ? "Company Name, " : ""} 
                ${!isCompanyNameValid ? "Company Name, " : ""}
             ${!panName ? "PAN Name, " : ""}
             ${!registeredStreetAddress ? "Registered Street Address, " : ""} 
             ${!isStreetAdd1Valid ? "Registered Street Address, " : ""}
             ${!isStreetAdd2Valid ? "Registered Street Address Lane 2, " : ""}
                ${!isCityValid ? "Registered City, " : ""}
                ${!isRegisteredStateProvinceValid ? "Registered State Province, " : ""}
                ${!isRegisteredPincodeValid ? "Registered Postal Code, " : ""}
             ${!registeredStreetAddressLane2 ? "Registered Street Address Lane 2, " : ""} 
             ${!registeredCity ? "Registered City, " : ""} 
             ${!registeredStateProvince ? "Registered State Province, " : ""} 
             ${!registeredPostalCode ? "Registered Postal Code, " : ""}
             ${!isValidGstNumber ? "GST Number, " : ""} 
             ${!isCinValid ? "CIN, " : ""}
             ${!billingStreetAddress ? "Billing Street Address, " : ""}
            ${!billingStreetAddressLane2 ? "Billing Street Address Lane 2, " : ""}
            ${!billingCity ? "Billing City, " : ""}
            ${!billingStateProvince ? "Billing State Province, " : ""}
            ${!billingPostalCode ? "Billing Postal Code, " : ""}
            ${!isBillingStreetAdd1Valid ? "Billing Street Address, " : ""}
            ${!isBillingStreetAdd2Valid ? "Billing Street Address Lane 2, " : ""}
            ${!isBillingCityValid ? "Billing City, " : ""}
            ${!isBillingStateValid ? "Billing State Province, " : ""}
            ${!isPincodeValid ? "Billing Postal Code, " : ""}
            ${!shippingStreetAddress ? "Shipping Street Address, " : ""}
            ${!shippingStreetAddressLane2 ? "Shipping Street Address Lane 2, " : ""}
            ${!shippingCity ? "Shipping City, " : ""}
            ${!shippingStateProvince ? "Shipping State Province, " : ""}
            ${!shippingPostalCode ? "Shipping Postal Code, " : ""}
            ${!isShippingStreetAdd1Valid ? "Shipping Street Address, " : ""}
            ${!isShippingStreetAdd2Valid ? "Shipping Street Address Lane 2, " : ""}
            ${!isShippingCityValid ? "Shipping City, " : ""}
            ${!isShippingStateValid ? "Shipping State Province, " : ""}
            ${!isShippingPincodeValid ? "Shipping Postal Code, " : ""}
            ${!contactValidationsFunc() ? "Missing Fields In Contacts List, " : ""}`


}

const checkmarkVisibilityCalculationPage2 = () => {
    return ( email && isValidEmail &&
        contactName && isContactNameValid &&
        phoneNumber && isValidPhone &&
        companyName && isCompanyNameValid && panName && registeredStreetAddress && isStreetAdd1Valid 
        && isStreetAdd2Valid && isCityValid && isRegisteredStateProvinceValid && isRegisteredPincodeValid &&
        registeredStreetAddressLane2 &&
        registeredCity && registeredStateProvince && registeredPostalCode
        && isValidGstNumber && !isCompanyInList && !isGstInList
        && isCinValid && billingStreetAddress && billingStreetAddressLane2 
        && billingCity && billingStateProvince 
        && billingPostalCode && isBillingStreetAdd1Valid && isBillingStreetAdd2Valid && isBillingCityValid && isBillingStateValid
        && isPincodeValid
        && shippingStreetAddress && shippingStreetAddressLane2 
        && shippingCity && shippingStateProvince 
        && shippingPostalCode 
        && isShippingStreetAdd1Valid && isShippingStreetAdd2Valid && isShippingCityValid && isShippingStateValid && isShippingPincodeValid
        && contactValidationsFunc()
    )
}

const handlePrimaryPhoneChange = (e) => {
    const input = e.target.value;
    const formattedInput = input.replace(/\D/g, '');
    let trimmedInput = formattedInput.slice(0, 15);
    if (trimmedInput.length === 0 || trimmedInput.length >= 10) {
        setIsValidPhone(true);

    } else {
        setIsValidPhone(false);
    }
    if(trimmedInput.length === 0){
        trimmedInput = ''
    }
    setPhoneNumber(trimmedInput);
}

const [isBillingStreetAdd1Valid ,setIsBillingStreetAdd1Valid] = useState(true)
const [isBillingStreetAdd2Valid ,setIsBillingStreetAdd2Valid] = useState(true)
const [isBillingCityValid ,setIsBillingCityValid] = useState(true)
const [isBillingStateValid ,setIsBillingStateValid] = useState(true)

const [isShippingStreetAdd1Valid ,setIsShippingStreetAdd1Valid] = useState(true)
const [isShippingStreetAdd2Valid ,setIsShippingStreetAdd2Valid] = useState(true)
const [isShippingCityValid ,setIsShippingCityValid] = useState(true)
const [isShippingStateValid ,setIsShippingStateValid] = useState(true)




  return (
    <>
            <>
                <NavBarTop />
                <LeftNavBar />

            </>
           {
            contractApiLoader && <Loader />
           }
           
            <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
            {currentPage === 1 &&
                <div className='meter_reports_container'>
                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                        <div className='d-flex align-items-center gap-3 mb-3'>
                            <h3 className="invoice-heading" >Companies</h3>

                            
                        </div>
                        <div className='d-flex align-items-center gap-3 mb-3'>
                            <div className='fin_man_meter_search_bar_container'>
                                <input type='search' placeholder='Search' value={searchText} className='fin_man_meter_search_bar' 
                                onChange={(e) => searchFunc(e.target.value)}
                                 />
                                <GoSearch className='fin_man_meter_search_bar_icon' />
                            </div>
                            <button className='invoice_btn' onClick={() => {
                                changePageNext()
                            }} style={{ color: "white" }}><span className='generate_invoice'><img src={"./images/icons/newContract.svg"} style={{ marginRight: "10px" }} alt="+" />Add New Company</span><span className='generate'><img src={"./images/icons/newContract.svg"} style={{ marginRight: "10px" }} alt="+" />Add</span></button>
                        </div>
                    </div>
                    

                    <div className='table_container_commercial_head'>
                        <TableComponent
                            headers={[{
                                name: "Company Name",
                                type: "text",
                                key: "company_name",
                                sort: true
                            }, {
                                name: "Primary Contact Name",
                                type: "text",
                                key: "primary_contact_name",
                                sort: true

                            }, {
                                name: "Primary Contact Number",
                                type: "number",
                                key: "primary_contact"
                            }, 
                            {
                                name: "Primary Contact Email",
                                type: "text",
                                key: "primary_contact_email"
                            },
                            {
                                name: "GST Number",
                                type: "number",
                                key: "gst_number"
                            },
                            {
                                name: "Created By",
                                type:"text",
                                key: "created_by",
                                sort: true
                            },
                            {
                                name:"Updated By",
                                type:"text",
                                key: "updated_by",
                                sort: true
                            },
                            {
                                name: "Last Updated At",
                                type: "time_stamp",
                                key: "updated_at",
                                sort: true

                            },
                            {
                              name: "Action",
                              type: "button",
                              buttons: [{
                                  name: "View",
                                  icon: "./images/icons/ViewViewIconEye.svg",
                                  action: (data) => handleViewClicked(data)
                              },
                              {
                                name: "Edit",
                                icon: "./images/icons/Edit.svg",
                                action: (data) => handleEditClicked(data)
                            },
                            
                            ]
                          }
                          
                          ]}
                            data={getAllCompanies?.data}
                        />
                    </div>
                    <Pagination
                        handledPaginationNextClicked={handledPaginationNextClicked}
                        handledPaginationPrevClicked={handledPaginationPrevClicked}
                        isApplyClicked
                        totalRecords={getAllCompanies?.info?.total_no_of_records}
                        recordsPerPage={RECORDS_PER_PAGE}
                        currentPaginationPage={currentPaginationPage}
                    />
                </div>

            }

            {currentPage === 2 ?
                <div className="newContractContainer">
                    <div className="stepsContainer">
                        <div className="stepsTitle">
                            <h6 style={{ padding: "20px 20px 0px 20px", lineHeight: "0px" }}>Company Creation Steps</h6>
                            <span style={{ fontSize: "10px", padding: "20px", lineHeight: "0px" }}><MdInfoOutline /> Please fill all the fields in every step to create a Company</span>
                        </div>
                        <div className="listOfCards">
                            {steps && steps.map((item, index) => {
                                return (
                                    <div key={index} className={`${currentPage - 1 === index + 1 ? 'stepsCardActive' : 'stepsCard'}`}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "medium" }}>{item.name}</span>
                                            {
                                                index === 0 && 
                                                email && isValidEmail 
                                                && contactName && isContactNameValid 
                                                && phoneNumber && isValidPhone &&
                                                    companyName && isCompanyNameValid && panName && registeredStreetAddress &&
                                                    registeredStreetAddressLane2 &&
                                                    registeredCity && registeredStateProvince && registeredPostalCode &&
                                                     isValidGstNumber && !isCompanyInList && !isGstInList
                                                    && isCinValid && billingStreetAddress && billingStreetAddressLane2 && billingCity 
                                                    && billingStateProvince && billingPostalCode && isBillingStreetAdd1Valid && isBillingStreetAdd2Valid
                                                    && isBillingCityValid && isBillingStateValid && isPincodeValid
                                                    && shippingStreetAddress 
                                                    && shippingStreetAddressLane2 && shippingCity && shippingStateProvince && shippingPostalCode 
                                                    && isShippingStreetAdd1Valid && isShippingStreetAdd2Valid && isShippingCityValid && isShippingStateValid && isShippingPincodeValid
                                                    && contactValidationsFunc()
                                                    ?
                                                    <img src={"./images/icons/CirclecircleWithTick.svg"} style={{ height: "16px" }} alt="ok" />
                                                    :
                                                    <>

                                                        {
                                                            index === 0 ?
                                                                <MdErrorOutline title="Please fill all the fields in this step" style={{ color: "orange" }} fontSize={18} />
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }



                                        </div>

                                        <span style={{ fontSize: "small", marginTop: "10px" }}>{item.description}</span>
                                        {
                                            checkmarkVisibilityCalculationPage2()
                                                ?
                                                null :
                                                <>
                                                    {
                                                        index === 0 ?
                                                            <spam style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>
                                                                Missing Fields: <br />{missingFieldsCalculatePage2()}
                                                            </spam>
                                                            :
                                                            null
                                                    }
                                                </>
                                        }

                                    </div>
                                )
                            })

                            }

                        </div>
                    </div>
                    <div className="infoContainer">
                        <div>
                            {steps && steps.map((item, index) => {
                                return (
                                    <>
                                        {currentPage - 1 === index + 1 ?
                                            <div key={index} className="infoTitle">
                                                <h6 style={{ padding: "20px", lineHeight: "0px" }}>{item.name}</h6>
                                                <h6 style={{ padding: "20px", lineHeight: "0px" }}>{index + 1} of {steps.length}</h6>
                                            </div>

                                            : null}
                                    </>
                                )
                            })}
                        </div>

                        <div className="infoBody">
                            <div>
                                <h6 style={{ fontWeight: "bold" }}>Company Details</h6>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">
                                        <div >
                                            <label className='add-facility-modal-label'>Primary Contact Name {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' 
                                            value={contactName} 
                                            disabled={viewClicked}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const formattedInput = value.replace(/[^A-Za-z\s]/g, '');
                                                    const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                    if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                        setIsContactNameValid(false);
                                                    } else {
                                                        setIsContactNameValid(true);
                                                    }
                                                    setContactName(trimmedInput);
                                                }} 
                                                className='form-control responsive-add-facility-input-fields' />
                                            {!isContactNameValid && <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Primary Phone Number {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' value={phoneNumber}
                                                disabled={viewClicked}
                                                onChange={handlePrimaryPhoneChange}
                                                className='form-control responsive-add-facility-input-fields' />
                                            {!isValidPhone && <span className="input-error-text">Invalid Phone Number</span>}

                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Primary Email {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' 
                                            disabled={viewClicked} 
                                            value={email}
                                                onChange={handleEmailChange}
                                                className='form-control responsive-add-facility-input-fields' />
                                            {!isValidEmail && <span className="input-error-text">Invalid email address</span>}
                                        </div>

                                    </div>
                                    <hr></hr>
                                    <div className="responsiveInputSection">
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <label className='add-facility-modal-label'>GST Number</label>
                                                <input disabled={viewClicked} type='text' value={gstNumber} onChange={handleGstNumberChange} onKeyDown={handleKeyPressForGst} className='form-control ' style={{ width: "90%", border: "0.5px solid #9D9D9C" }} />
                                                {!isValidGstNumber && <span className="input-error-text">Invalid GST number</span>}
                                                {isValidGstNumber && isGstInList && <span className="input-error-text">GST number already exists</span>}
                                            </div>
                                            {/* >>> */}
                                            {
                                                !viewClicked ? (
                                                    <div style={{ marginBottom: "0px", display: "flex", alignItems: "start", marginTop: "20px" }}>
                                                        <div
                                                            style={{
                                                                background: "grey",
                                                                padding: "9px 10px",
                                                                marginLeft: "-20px",
                                                                borderRadius: "5px",
                                                                opacity: gstNumber === '' || !isValidGstNumber ? 0.3 : 1,
                                                                pointerEvents: gstNumber === '' || !isValidGstNumber ? 'none' : 'auto'
                                                            }}
                                                            className="C-pointer"
                                                            onClick={() => {
                                                                if (gstNumber !== '' && isValidGstNumber) {
                                                                    setGstLoader(true);
                                                                    // dispatch(GstNumberDetails(gstNumber))
                                                                    handleGstClick(gstNumber);
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                gstLoader && !errorValue ? (
                                                                    <GstLoader />
                                                                ) : (
                                                                    <FaSearch color="white" size={20} style={{ fontWeight: "bolder" }} />
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                        <div>
                                            <label className='add-facility-modal-label'>CIN
                                                {/* {<span style={{ color: "red" }}>*</span>} */}
                                            </label>
                                            <input type='text' disabled={viewClicked} value={cinNumber} onChange={handleCinNumberChange} className='form-control responsive-add-facility-input-fields' />
                                            {!isCinValid && <span className="input-error-text">Invalid CIN</span>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">
                                        <div>
                                            <label className='add-facility-modal-label'>Company Name {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' disabled={viewClicked} value={companyName} onChange={(e) => {
                                                        const value = e.target.value;
                                                        const formattedInput = value.replace(/[^A-Za-z\s]/g, '');
                                                        const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                        if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                            setIsCompanyNameValid(false);
                                                        } else {
                                                            setIsCompanyNameValid(true);
                                                        }
                                                        const isNameInList = getAllCompaniesForDropdown.some(
                                                            (company) => company.company_name.toLowerCase() === trimmedInput.trim().toLowerCase()
                                                        );
                                                        if(selectedItem?.company_name === trimmedInput){
                                                            setIsCompanyInList(false);
                                                        }
                                                        else{
                                                            setIsCompanyInList(isNameInList);
                                                        }
                                                        setCompanyName(trimmedInput);
                                                    }
                                                } className='form-control responsive-add-facility-input-fields' />
                                                {!isCompanyNameValid && <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                                {isCompanyNameValid && isCompanyInList && <span className='input-error-text'>Company name already exists</span>}
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>PAN {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' disabled={viewClicked} value={panName} onChange={(e) => {
                                                    const value = e.target.value;
                                                    const formattedInput = value.replace(/[^A-Za-z0-9]/g, '');
                                                    const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                    setPanName(trimmedInput)
                                                }
                                                } className='form-control responsive-add-facility-input-fields' />
                                        </div>
                                        <div>
                                            <label className='add-facility-modal-label'>Street Address {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' disabled={viewClicked} value={registeredStreetAddress} onChange={(e) => {
                                                const value = e.target.value;
                                                const formattedInput = value;
                                                const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                    setIsStreetAdd1Valid(false);
                                                } else {
                                                    setIsStreetAdd1Valid(true);
                                                }
                                                setRegisteredStreetAddress(trimmedInput);
                                            }
                                            } className='form-control responsive-add-facility-input-fields' />
                                            {!isStreetAdd1Valid && <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                        </div>
                                    </div>
                                    <div className="responsiveInputSection">

                                        <div >
                                            <label className='add-facility-modal-label'>Street Address Lane 2 {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' disabled={viewClicked} value={registeredStreetAddressLane2} onChange={(e) => {
                                                const value = e.target.value;
                                                const formattedInput = value;
                                                const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                    setIsStreetAdd2Valid(false);
                                                }
                                                else {
                                                    setIsStreetAdd2Valid(true);
                                                }
                                                setRegisteredStreetAddressLane2(trimmedInput);
                                            }
                                                
                                                } className='form-control responsive-add-facility-input-fields' />
                                            {!isStreetAdd2Valid && <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>City {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' disabled={viewClicked} value={registeredCity} onChange={(e) =>
                                            {
                                                const value = e.target.value;
                                                const formattedInput = value.replace(/[^A-Za-z\s]/g, '');
                                                const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                    setIsCityValid(false);
                                                }
                                                else {
                                                    setIsCityValid(true);
                                                }
                                                setRegisteredCity(trimmedInput);
                                            }
                                                 } className='form-control responsive-add-facility-input-fields' />
                                            {!isCityValid && <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>State {<span style={{ color: "red" }}>*</span>} </label>
                                            <input type='text' disabled={viewClicked} value={registeredStateProvince} onChange={(e) => 
                                            {
                                                const value = e.target.value;
                                                const formattedInput = value.replace(/[^A-Za-z\s]/g, '');
                                                const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                    setIsRegisteredStateProvinceValid(false);
                                                }
                                                else {
                                                    setIsRegisteredStateProvinceValid(true);
                                                }

                                                setRegisteredStateProvince(trimmedInput)
                                            }
                                                } className='form-control responsive-add-facility-input-fields' />
                                            {!isRegisteredStateProvinceValid && <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                        </div>
                                    </div>
                                    <div className="responsiveInputSection">

                                        <div >
                                            <label className='add-facility-modal-label'>Pincode {<span style={{ color: "red" }}>*</span>}</label>
                                            <input type='text' disabled={viewClicked} value={registeredPostalCode} onChange={(e) => 
                                            {
                                                const input = e.target.value
                                                const formattedInput = input.replace(/[^0-9]/g, '')
                                                const trimmedInput = formattedInput.trim();
                                                if (trimmedInput === '') {
                                                    setIsRegisteredPincodeValid(true);
                                                } else if (trimmedInput.length !== 6) {
                                                    setIsRegisteredPincodeValid(false);
                                                } else {
                                                    setIsRegisteredPincodeValid(true);
                                                }
                                                setRegisteredPostalCode(trimmedInput)
                                            }
                                                } className='form-control responsive-add-facility-input-fields' />
                                            {!isRegisteredPincodeValid && <span className="input-error-text">Invalid pincode</span>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", }}>
                                    <h6 style={{ fontWeight: "bold" }}>Billing Address</h6>
                                    {
                                        !viewClicked ?
                                            <span style={{ textDecoration: "underline", }} className="C-pointer" onClick={sameAsRegisteredAddress}>Same as GST address</span>
                                            :
                                            null
                                    }
                                </div>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">
                                        <div>
                                            <label className='add-facility-modal-label'>Street Address {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={billingStreetAddress} 
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const formattedInput = value;
                                                const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                    setIsBillingStreetAdd1Valid(false);
                                                } else {
                                                    setIsBillingStreetAdd1Valid(true);
                                                }
                                                if(trimmedInput.length === 0){
                                                    setIsBillingStreetAdd2Valid(true)
                                                }
                                                    setBillingStreetAddress(trimmedInput)
                                                }
                                            } 
                                            className='form-control responsive-add-facility-input-fields' />
                                            {!isBillingStreetAdd1Valid && <span className="input-error-text">Must be min of 3 to max of 200 characters</span>}
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Street Address Lane 2 {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={billingStreetAddressLane2} 
                                            onChange={(e) => 
                                            {
                                                const value = e.target.value;
                                                const formattedInput = value;
                                                const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                    setIsBillingStreetAdd2Valid(false);
                                                } else {
                                                    setIsBillingStreetAdd2Valid(true);
                                                }
                                                if(trimmedInput.length === 0){
                                                    setIsBillingStreetAdd2Valid(true)
                                                }
                                                setBillingStreetAddressLane2(trimmedInput)
                                            }
                                            } 
                                            className='form-control responsive-add-facility-input-fields' />
                                            {!isBillingStreetAdd2Valid && <span className="input-error-text">Must be min of 3 to max of 200 characters</span>}
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>City {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={billingCity} 
                                            onChange={(e) => 
                                            {
                                                const value = e.target.value;
                                                const formattedInput = value;
                                                const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                    setIsBillingCityValid(false);
                                                } else {
                                                    setIsBillingCityValid(true);
                                                }
                                                if(trimmedInput.length === 0){
                                                    setIsBillingCityValid(true)
                                                }
                                                setBillingCity(trimmedInput)
                                            }
                                            } 
                                            className='form-control responsive-add-facility-input-fields' />
                                            {!isBillingCityValid && <span className="input-error-text">Must be min of 3 to max of 200 characters</span>}
                                        </div>
                                    </div>
                                    <div className="responsiveInputSection">
                                        <div >
                                            <label className='add-facility-modal-label'>State {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={billingStateProvince} 
                                            onChange={(e) => 
                                            {
                                                const value = e.target.value;
                                                const formattedInput = value;
                                                const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                    setIsBillingStateValid(false);
                                                } else {
                                                    setIsBillingStateValid(true);
                                                }
                                                if(trimmedInput.length === 0){
                                                    setIsBillingStateValid(true)
                                                }
                                                setBillingStateProvince(trimmedInput)
                                            }
                                            } 
                                            className='form-control responsive-add-facility-input-fields' />
                                            {!isBillingStateValid && <span className="input-error-text">Must be min of 3 to max of 200 characters</span>}
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Pincode {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={billingPostalCode} onChange={(e) => {
                                                const input = e.target.value
                                                const formattedInput = input.replace(/[^0-9]/g, '')
                                                const trimmedInput = formattedInput.trim();
                                                if (trimmedInput === '') {
                                                    setIsPincodeValid(true);
                                                } else if (trimmedInput.length !== 6) {
                                                    setIsPincodeValid(false);
                                                } else {
                                                    setIsPincodeValid(true);
                                                }
                                                setBillingPostalCode(trimmedInput)
                                            }} className='form-control responsive-add-facility-input-fields' />
                                            {!isPincodeValid && <span className="input-error-text">Invalid pincode</span>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div style={{ display: "flex", justifyContent: "space-between", }}>
                                <h6 style={{ fontWeight: "bold" }}>Shipping Address</h6>
                                {
                                    !viewClicked ?
                                        <span style={{ textDecoration: "underline", }} className="C-pointer" onClick={sameAsBillingAddress}>Same as Billing address</span>
                                        :
                                        null
                                }
                            </div>
                            <div>
                                <div className="infoBodyInputs">
                                    <div className="responsiveInputSection">
                                        <div>
                                            <label className='add-facility-modal-label'>Street Address {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={shippingStreetAddress} 
                                            onChange={(e) => 
                                            {
                                                const value = e.target.value;
                                                const formattedInput = value;
                                                const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                    setIsShippingStreetAdd1Valid(false);
                                                } else {
                                                    setIsShippingStreetAdd1Valid(true);
                                                }
                                                if(trimmedInput.length === 0){
                                                    setIsShippingStreetAdd1Valid(true)
                                                }
                                                setShippingStreetAddress(trimmedInput)   
                                            }
                                            } 
                                            className='form-control responsive-add-facility-input-fields' />
                                            {!isShippingStreetAdd1Valid && <span className="input-error-text">Invalid street address</span>}
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Street Address Lane 2 {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={shippingStreetAddressLane2} 
                                            onChange={(e) => 
                                            {
                                                const value = e.target.value;
                                                const formattedInput = value;
                                                const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                    setIsShippingStreetAdd2Valid(false);
                                                } else {
                                                    setIsShippingStreetAdd2Valid(true);
                                                }
                                                if(trimmedInput.length === 0){
                                                    setIsShippingStreetAdd2Valid(true)
                                                }
                                                setShippingStreetAddressLane2(trimmedInput)
                                            }
                                            }
                                             className='form-control responsive-add-facility-input-fields' />
                                            {!isShippingStreetAdd2Valid && <span className="input-error-text">Invalid street address</span>}
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>City {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={shippingCity} 
                                            onChange={(e) => 
                                            {
                                                const value = e.target.value;
                                                const formattedInput = value;
                                                const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                    setIsShippingCityValid(false);
                                                } else {
                                                    setIsShippingCityValid(true);
                                                }
                                                if(trimmedInput.length === 0){
                                                    setIsShippingCityValid(true)
                                                }
                                                setShippingCity(trimmedInput)
                                            }
                                            } 
                                            className='form-control responsive-add-facility-input-fields' />
                                            {!isShippingCityValid && <span className="input-error-text">Invalid city</span>}
                                        </div>
                                    </div>
                                    <div className="responsiveInputSection">
                                        <div >
                                            <label className='add-facility-modal-label'>State {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={shippingStateProvince} 
                                            onChange={(e) => 
                                            {
                                                const value = e.target.value;
                                                const formattedInput = value;
                                                const trimmedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                if (trimmedInput.length !== 0 && (trimmedInput.length < 3 || trimmedInput.length > 200)) {
                                                    setIsShippingStateValid(false);
                                                } else {
                                                    setIsShippingStateValid(true);
                                                }
                                                if(trimmedInput.length === 0){
                                                    setIsShippingStateValid(true)
                                                }
                                                setShippingStateProvince(trimmedInput)
                                            }
                                            } 
                                            className='form-control responsive-add-facility-input-fields' />
                                            {!isShippingStateValid && <span className="input-error-text">Invalid state</span>}
                                        </div>
                                        <div >
                                            <label className='add-facility-modal-label'>Pincode {<span style={{ color: "red" }}>*</span>}</label>
                                            <input disabled={viewClicked} type='text' value={shippingPostalCode} onChange={(e) => {
                                                const input = e.target.value
                                                const formattedInput = input.replace(/[^0-9]/g, '')
                                                const trimmedInput = formattedInput.trim();
                                                if (trimmedInput === '') {
                                                    setIsShippingPincodeValid(true);
                                                } else if (trimmedInput.length !== 6) {
                                                    setIsShippingPincodeValid(false);
                                                } else {
                                                    setIsShippingPincodeValid(true);
                                                }
                                                setShippingPostalCode(trimmedInput)
                                            }} className='form-control responsive-add-facility-input-fields' />
                                            {!isShippingPincodeValid && <span className="input-error-text">Invalid pincode</span>}
                                        </div>

                                    </div>
                                </div>
                                <hr></hr>
                                <div style={{ display: "flex", justifyContent: "space-between", }}>
                                    <h6 style={{ fontWeight: "bold" }}>Contact Information</h6>
                                    {!viewClicked ?
                                        <span style={{
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                            opacity: (listOfContactsValidation[listOfContactsValidation?.length - 1]?.isValidEmail === true &&
                                                listOfContacts[listOfContacts?.length - 1].email !== '') ? '100%' : '50%'
                                        }}
                                            className="C-pointer"
                                            onClick={() => {
                                                if (
                                                    listOfContactsValidation[listOfContactsValidation?.length - 1]?.isValidEmail === true &&
                                                    listOfContacts[listOfContacts?.length - 1].email !== ''
                                                ) {
                                                    handleAddAnotherContact()
                                                    handleAddAnotherContactValidation()
                                                }

                                            }} > {listOfContactsValidation[listOfContactsValidation?.length - 1]?.isValidEmail === true &&
                                                listOfContacts[listOfContacts?.length - 1].email !== ''}Add another contact</span>
                                        :
                                        null}
                                </div>

                                {listOfContacts && listOfContacts?.map((contact, index) => (
                                    <div key={index} className="infoBodyInputs">
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }}>
                                            <p style={{ fontWeight: "bold" }}>Contact {index + 1}</p>
                                            {index !== 0 && !viewClicked ? (
                                                <div>
                                                    <img src="./images/icons/crossWithCircle.svg" className="C-pointer" alt="cross"
                                                        onClick={() => deleteSpecificContact(index, contact)} />
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="responsiveInputSection" style={{ marginTop: "0px" }}>
                                            <div >
                                                <label className='add-facility-modal-label'>Contact Name {<span style={{ color: "red" }}>*</span>}</label>
                                                <input type='text' disabled={viewClicked} className='form-control responsive-add-facility-input-fields' value={contact.name}
                                                    onChange={(e) => {
                                                        const updatedContacts = [...listOfContacts];
                                                        const updatedContactsValidation = [...listOfContactsValidation];
                                                        const rawInput = e.target.value;
                                                        const formattedInput = rawInput.replace(/[^A-Za-z\s]/g, '');
                                                        const processedInput = eleminateSpacesAtStartOfInputField(formattedInput);
                                                        updatedContacts[index].name = processedInput;
                                                        if (processedInput.length === 0 || processedInput.length < 3 || processedInput.length > 200) {
                                                            updatedContactsValidation[index].isContactNameValid = false;
                                                        } else {
                                                            updatedContactsValidation[index].isContactNameValid = true;
                                                        }
                                                        setListOfContacts(updatedContacts);
                                                        setListOfContactsValidation(updatedContactsValidation);
                                                    }} />
                                                {!listOfContactsValidation[index]?.isContactNameValid && <span className="input-error-text">Must be min of 3 to max of 200 characters</span>}
                                            </div>
                                            <div >
                                                <label className='add-facility-modal-label'>Designation </label>
                                                <input disabled={viewClicked} type='text' className='form-control responsive-add-facility-input-fields' value={contact.designation}
                                                    onChange={(e) => {
                                                        const updatedContacts = [...listOfContacts];
                                                        const updatedContactsValidation = [...listOfContactsValidation];
                                                        const input = e.target.value;
                                                        const trimmedInput = eleminateSpacesAtStartOfInputField(input);
                                                        if (trimmedInput.length !== 0 && (trimmedInput.length > 200)) {
                                                            updatedContactsValidation[index].isDesignationValid = false;
                                                        } else {
                                                            updatedContactsValidation[index].isDesignationValid = true;
                                                        }

                                                        const formattedInput = trimmedInput.replace(/[^a-zA-Z ]+/g, '');
                                                        updatedContacts[index].designation = formattedInput;

                                                        setListOfContacts(updatedContacts);
                                                        setListOfContactsValidation(updatedContactsValidation);
                                                    }} />
                                                {!listOfContactsValidation[index]?.isDesignationValid && <span className="input-error-text">Must be max of 200 characters</span>}
                                            </div>
                                            <div >
                                                <label className='add-facility-modal-label'>Phone Number</label>
                                                <input type='text' disabled={viewClicked} className='form-control responsive-add-facility-input-fields' value={contact.phone_number}
                                                    onChange={(e) => {
                                                        const updatedContactsValidation = [...listOfContactsValidation]
                                                        const input = e.target.value;
                                                        const updatedContacts = [...listOfContacts];

                                                        const formattedInput = input.replace(/\D/g, '');
                                                        const trimmedInput = formattedInput.slice(0, 15);
                                                        updatedContactsValidation[index].isValidPhone = trimmedInput.length === 0 || (trimmedInput.length >= 10 && trimmedInput.length <= 15);
                                                        updatedContactsValidation[index].errorMsg = trimmedInput.length < 10 && trimmedInput.length > 0 ? 'Invalid phone number' : '';

                                                        updatedContacts[index].phone_number = trimmedInput
                                                        setListOfContactsValidation(updatedContactsValidation)
                                                        setListOfContacts(updatedContacts);
                                                    }} />
                                                {!listOfContactsValidation[index]?.isValidPhone && <span className="input-error-text">Invalid Phone Number</span>}
                                            </div>
                                        </div>
                                        <div className="responsiveInputSection">


                                            <div >
                                                <label className='add-facility-modal-label'>Email <span style={{ color: "red" }}>*</span></label>
                                                <input type='text' disabled={viewClicked} className='form-control responsive-add-facility-input-fields'
                                                    value={contact.email}

                                                    onChange={(e) => handleEmailUpdateChange(e, index)}
                                                    onBlur={onEmailInputBlur}
                                                />
                                                {!listOfContactsValidation[index]?.isValidEmail && <span className="input-error-text">Invalid email address</span>}
                                                {/* un comment the bellow line for duplicate email validation, tick and disable functionality should be added */}
                                                {/* {!listOfContactsValidation[index]?.isEmailNotDuplicate && <span className="input-error-text">Email already exists</span>} */}
                                            </div>

                                        </div>
                                        <hr></hr>
                                    </div>
                                ))}

                            </div>
                            <div>

                            </div>
                            <Modal keyboard={false} backdrop="static" show={showDeleteContractContact} onHide={() => { setShowDeleteContractContact(false) }} centered className="popper-modal">
                                <Modal.Body className="text-center">
                                    <h5>{`Are you sure you want to delete this contact?`}</h5>
                                </Modal.Body>
                                <Modal.Footer className="footer">
                                    <Button variant="secondary" className="confirmation" onClick={() => { setShowDeleteContractContact(false) }}>Cancel</Button>
                                    <Button variant="secondary" className="confirmation-left" onClick={() => handleConfirmDeleteContractContact()}>Proceed</Button>
                                </Modal.Footer>
                            </Modal>


                        </div>

                        <div className="infoFotter">
                            
                                <>
                                    
                                    <button className="infoNextBtn" style={{ marginRight: "20px" }} 
                                    onClick={() => {
                                        if(viewClicked){
                                            backfunction()
                                        }
                                        else{
                                            setShowCloseConfirmationModal(true)
                                        }
                                    }
                                    }
                                    >Close</button>
                                    {
                                        viewClicked ? null : 
                                    <button disabled={
                                        !contactName || !isContactNameValid 
                                        || !email || !isValidEmail 
                                        || !phoneNumber || !isValidPhone
                                        || !companyName || !isCompanyNameValid 
                                        || isCompanyInList || isGstInList 
                                        || !panName 
                                        || !registeredStreetAddress || !registeredStreetAddressLane2 || !registeredCity || !registeredStateProvince || !registeredPostalCode
                                        || !isStreetAdd1Valid || !isStreetAdd2Valid || !isCityValid || !isRegisteredStateProvinceValid || !isRegisteredPincodeValid
                                         || !isValidGstNumber || !isCinValid 
                                         || !billingStreetAddress || !billingStreetAddressLane2 || !billingCity || !billingStateProvince || !billingPostalCode
                                         || !isBillingStreetAdd1Valid || !isBillingStreetAdd2Valid || !isBillingCityValid || !isBillingStateValid || !isPincodeValid
                                         || !shippingStreetAddress || !shippingStreetAddressLane2 || !shippingCity || !shippingStateProvince || !shippingPostalCode
                                        || !isShippingStreetAdd1Valid || !isShippingStreetAdd2Valid || !isShippingCityValid || !isShippingStateValid || !isShippingPincodeValid
                                         
                                        || !contactValidationsFunc()
                                    }
                                        

                                      className="infoNextBtn" style={{ marginRight: "20px" }}  onClick={handleSubmit}>{selectedCompanyId ? "Update" : "Submit"}</button>
                                    }
                                </>
                            
                        </div>
                        <Modal keyboard={false} backdrop="static"
                            show={showCloseConfirmationModal} onHide={handleCloseCloseConfirmationModal}
                            centered className="popper-modal">
                            <Modal.Body className="text-center">
                                <h5>Are you sure you want to CLOSE? Your progress will  not  be saved.</h5>
                            </Modal.Body>
                            <Modal.Footer className="footer">
                                <Button variant="secondary" className="confirmation" onClick={handleCloseCloseConfirmationModal}>  Cancel </Button>
                                <Button variant="secondary" className="confirmation-left" onClick={backfunction} > Proceed</Button>
                            </Modal.Footer></Modal>
                       {/*modal for confirmation*/}
                        

                    </div>
                </div>
                :
                null

            }

            {isLoading ? <Loader /> : null}
            <Footer />
        </>
  )
}

import React, { useEffect } from 'react'

export default function Textarea({ object, handleFormData, formUse, data, prefilledData, viewOnly }) {

    useEffect(() => {
        formUse && handleFormData && handleFormData(object.content, "")
    }, [])

    return (
        <div>
            <label>{object.content}</label>
            <textarea className='form-control'
                onChange={(e) => formUse && handleFormData && handleFormData(object.content, e.target.value)}
                value={prefilledData && prefilledData[object.content] ? prefilledData[object.content] : data && data[object.content]}
                disabled={prefilledData && prefilledData[object?.content] ? true : viewOnly ? viewOnly : false}
            />
        </div>
    )
}

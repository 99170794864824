import React, { useEffect, useState } from 'react'

export default function DashboardCard({ data }) {

    const [activeContractsCount, setActiveContractsCount] = useState(0);
    const [pendingContractsCount, setPendingContractsCount] = useState(0);
    const [draftContractsCount, setDraftContractsCount] = useState(0);

    useEffect(() => {
        if (data) {
            const activeCount = data.filter(contract => contract.contract_status === 'active');
            setActiveContractsCount(activeCount.length > 0 && activeCount[0].contract_count);
            let pendingCount = data.filter(contract => contract.contract_status === 'pending');
            setPendingContractsCount(pendingCount.length > 0 && pendingCount[0].contract_count);
            let draftCount = data.filter(contract => contract.contract_status === 'draft');
            setDraftContractsCount(draftCount.length > 0 && draftCount[0].contract_count);
        }
    }, [data]);
    return (
        <div className="dashboard-card-container d-flex gap-3">
            <div className='d-flex flex-column justify-content-center align-items-start'>
                <div className='total-count'>
                    {data && data.length > 0 && data[0]?.total_contracts}
                </div>
                <div style={{ fontSize: "13px", fontWeight: "600" }}>
                    Total Contracts
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center gap-1'>
                <div className='d-flex'>
                    <div style={{ width: "80px", fontSize: "10px" }}>Active</div>
                    <div style={{ fontWeight: "600" }}>
                        {activeContractsCount}
                    </div>
                </div>
                <div className='d-flex'><div style={{ width: "80px", fontSize: "10px" }}>Pending</div> <div style={{ fontWeight: "600" }}>{pendingContractsCount}</div></div>
                <div className='d-flex'><div style={{ width: "80px", fontSize: "10px" }}>Draft</div> <div style={{ fontWeight: "600" }}>{draftContractsCount}</div></div>
            </div>
        </div>
    )
}

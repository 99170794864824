import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PieChart({ facilities, selectedFacility, handleChangeFacility, areaData }) {

    const totalArea = areaData
        .filter(data => data["Facility Name"] === selectedFacility)
        .reduce((sum, contract) => sum + contract.Area, 0);

    const occupiedAreaSum = areaData
        .filter(data => data["Facility Name"] === selectedFacility)
        .reduce((sum, contract) => sum + contract.Area, 0);
    const remainingArea = totalArea - occupiedAreaSum;

    const generateColorsByIndex = (numColors) => {
        const premiumColors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56', '#C9CBCF', '#36A2EB'];
        const uniqueColors = new Set(premiumColors);

        while (uniqueColors.size < numColors) {
            uniqueColors.add(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
        }

        return Array.from(uniqueColors).slice(0, numColors);
    };


    const filteredAreaData = areaData.filter(data => data["Facility Name"] === selectedFacility);

    const colors = generateColorsByIndex(filteredAreaData.length + 1);

    const data = {
        labels: [...filteredAreaData.map(contract => contract["Customer Name"]), 'Unoccupied Area'],
        datasets: [
            {
                label: 'Area Occupied',
                data: [...filteredAreaData.map(contract => contract.Area), remainingArea],
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
            },
            tooltip: {
                callbacks: {
                    footer: () => `Total Area: ${totalArea}`,
                },
            },
        },
    };

    const totalAreaPlugin = {
        id: 'totalAreaPlugin',
        afterDraw: (chart) => {
            const { ctx, chartArea: { left, right, bottom } } = chart;
            ctx.save();
            ctx.font = '16px Arial';
            ctx.textAlign = 'center';
            ctx.fillText(`Total Area: ${totalArea}`, (left + right) / 2, bottom + 20);
            ctx.restore();
        },
    };

    return (
        <div style={{ minHeight: "400px", height: "100%", width: "100%", backgroundColor: "#FFFFFF", padding: "10px" }} >
            <h6 style={{ textAlign: "center" }}>Area Distribution by Contract</h6>
            <div className='d-flex gap-2 justify-content-end my-3'>
                <div>
                    <label htmlFor="facilitySelect" className="form-label">Select Facility</label>
                    <select id="facilitySelect" className="form-select form-select-sm" value={selectedFacility} onChange={handleChangeFacility}>
                        {facilities?.map(facility => (
                            <option key={facility} value={facility}>{facility}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: "280px", width: "100%" }}>
                <Pie data={data} options={options} plugins={[totalAreaPlugin]} />
            </div>
        </div>
    );
}

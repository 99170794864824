import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { MdErrorOutline, MdOutlineDelete } from "react-icons/md";
import { Alert, Button, Modal } from 'react-bootstrap';
import Element from './Element';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_PANEL, DELETE_PANEL_ERROR, FETCHING_ERROR, SHOW_MODAL, deletePanel, getAllPanels } from '../../Redux/Actions/Actions';
import FormLoader from '../Reusable_Components/FromLoader';
import { sorting_lists, timeElapsed } from '../Reusable_Components/ReusableFunctions';
import AlertPopUp from '../Reusable_Components/Alert';
import TableComponent from '../Reusable_Components/TableComponent';
import Search from '../Reusable_Components/Search';

export default function PanelsList() {

    const dispatch = useDispatch()
    const panelsList = useSelector((state) => state.panelsList)
    const loading = useSelector((state) => state.loading)
    const modal = useSelector((state) => state.showModal)
    const deletePanelSuccess = useSelector((state) => state.deletePanelSuccess)
    const fetchingError = useSelector((state) => state.fetchingError)
    const deletePanelError = useSelector((state) => state.deletePanelError)

    const [panels, setPanels] = useState([]);
    const [preview, setPreview] = useState(false);
    const [previewObject, setPreviewObject] = useState([])
    const [currentTime, setCurrentTime] = useState(new Date())
    const [deleteId, setDeleteId] = useState("")
    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        dispatch(getAllPanels())
    }, [])

    useEffect(() => {
        setPanels(sorting_lists(panelsList))
    }, [panelsList]);

    return (
        <div>
            {preview ? <div className='d-flex flex-column p-3' style={{ width: "210mm", backgroundColor: "white" }}>
                {previewObject?.map((previewObj, index) => {
                    return <div key={index} id={previewObj.id + " " + index} className='d-flex'>
                        {previewObj.items.map((item) => {
                            return <Element object={item} preview={true} />
                        })}
                    </div>
                })}
                <div className='d-flex justify-content-end w-100'>
                    <button className='btn btn-secondary' onClick={() => {
                        setPreview(false)
                        setPreviewObject([])
                    }}>Back</button>
                </div>
            </div> : <>
                <div className='d-flex justify-content-between my-2'>
                    <h3>Panels</h3>
                    <div className='d-flex align-items-center gap-1'>
                        <Search
                            value={searchText}
                            placeholder='Search Fields'
                            handleSearch={(e) => {
                                setSearchText(e.target.value)
                                const filteredData = panelsList.filter((panel) => panel.panel_name.toLowerCase().includes(e.target.value.toLowerCase()))
                                setPanels(filteredData)
                            }}
                        />
                        <Link to={'/form/panels'}><button className='btn btn-secondary mx-4'>Create new Panel</button></Link>
                    </div>
                </div>
                <TableComponent
                    headers={[
                        {
                            name: "Panel Name",
                            type: "text",
                            key: "panel_name"
                        }, {
                            name: "Description",
                            type: "text",
                            key: "description"
                        }, {
                            name: "Created By",
                            type: "text",
                            key: "created_by",
                        }, {
                            name: "Created",
                            type: "time_stamp",
                            key: "created_at",
                            sort: true
                        },
                        {
                            name: "Action",
                            type: "button",
                            buttons: [{
                                name: "View",
                                icon: "./images/icons/ViewViewIconEye.svg",
                                action: (data) => {
                                    setPreview(true)
                                    setPreviewObject(JSON.parse(data?.json_data))
                                }
                            },
                            {
                                name: "Delete",
                                icon: "./images/icons/Delete.svg",
                                action: (data) => {
                                    dispatch({
                                        type: SHOW_MODAL,
                                        payload: true
                                    })
                                    setDeleteId(data?.panel_id)
                                }
                            }

                            ]
                        }
                    ]}
                    data={panels}
                />
            </>}
            {modal && <Modal keyboard={false} backdrop="static" show={modal} centered onHide={() => {
                dispatch({
                    type: SHOW_MODAL,
                    payload: false
                })
            }}>
                <Modal.Body>Are you sure you want to delete this Panel?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        dispatch({
                            type: SHOW_MODAL,
                            payload: false
                        })
                    }}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => dispatch(deletePanel(deleteId))}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>}
            {deletePanelSuccess && <AlertPopUp
                alertShow={deletePanelSuccess}
                alertType="success"
                alertMsg="Success"
                alertValue={deletePanelSuccess}
                alertButtons={[{ name: "Close", action: () => { dispatch({ type: DELETE_PANEL, payload: null }) } }]}
            />
            }
            {fetchingError && <AlertPopUp
                alertShow={fetchingError}
                alertType="error"
                alertMsg="Error"
                alertValue={fetchingError}
                alertButtons={[{ name: "Close", action: () => { dispatch({ type: FETCHING_ERROR, payload: null }) } }, {
                    name: "Retry", action: () => {
                        dispatch(getAllPanels())
                        dispatch({
                            type: FETCHING_ERROR,
                            payload: null
                        })
                    }
                }]}
            />}
            {deletePanelError && <AlertPopUp
                alertShow={deletePanelError}
                alertType="error"
                alertMsg="Error"
                alertValue={deletePanelError}
                alertButtons={[{
                    name: "Close", action: () => {
                        dispatch({
                            type: DELETE_PANEL_ERROR,
                            payload: null
                        })
                        dispatch({
                            type: SHOW_MODAL,
                            payload: false
                        })
                    }
                }, {
                    name: "Retry", action: () => {
                        dispatch(deletePanel(deleteId))
                        dispatch({
                            type: DELETE_PANEL_ERROR,
                            payload: null
                        })
                    }
                }]}
            />}
            {loading && <FormLoader />}
        </div>
    )
}

import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function BarChart({ datasets, facilities, selectedFacility, handleFacilityChange }) {

    const [selectedCustomer, setSelectedCustomer] = useState('');

    const customers = ['Customer 1', 'Customer 2', 'Customer 3'];

    const handleCustomerChange = (event) => {
        setSelectedCustomer(event.target.value);
    };

    const data = {
        labels: ['1 month', '2 months', '6 months', '1 year'],
        datasets: datasets,
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Number of Contracts',
                },
            },
        },
    };

    return (
        <div className="container" style={{ height: "100%", width: "100%", backgroundColor: "#FFFFFF", padding: "10px" }}>
            <h6 style={{ textAlign: "center", width: "100%" }}>Contracts Expiring</h6>
            <div className="d-flex justify-content-end gap-2 w-100">
                <div className="">
                    <label className="form-label">
                        Facility:
                        <select className="form-select form-select-sm" value={selectedFacility} onChange={handleFacilityChange}>
                            <option value="">Select Facility</option>
                            {facilities.map((facility) => (
                                <option key={facility} value={facility}>
                                    {facility}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
            </div>
            <div style={{ width: "100%", height: "280px" }}>
                <Bar data={data} options={options} />
            </div>
        </div>
    );
}

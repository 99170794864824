import React, { useEffect, useState } from "react";
import NavBarTop from "../Reusable_Components/NavBarTop";
import LeftNavBar from "../Reusable_Components/LeftNavBar";
import DashboardCard from "../Reusable_Components/Dashboard/DashboardCard";
import BarChart from "../Reusable_Components/Dashboard/BarChart";
import PieChart from "../Reusable_Components/Dashboard/PieChart";

const agingData = [
    {
        "Facility Name": "ACLC-11",
        "Customer Name": "Amazon",
        "AgeInMonths": 1,
        "Count": 2
    },
    {
        "Facility Name": "ACLC-11",
        "Customer Name": "Amazon",
        "AgeInMonths": 12,
        "Count": 4
    },
    {
        "Facility Name": "ACLC-11",
        "Customer Name": "FedEx",
        "AgeInMonths": 6,
        "Count": 1
    },
    {
        "Facility Name": "ACLC-11",
        "Customer Name": "SpiceJet",
        "AgeInMonths": 1,
        "Count": 1
    },
    {
        "Facility Name": "ACLC-111",
        "Customer Name": "UPS",
        "AgeInMonths": 1,
        "Count": 1
    },
    {
        "Facility Name": "ACLC-111",
        "Customer Name": "ABC",
        "AgeInMonths": 12,
        "Count": 1
    },
    {
        "Facility Name": "ACLC-111",
        "Customer Name": "XYZ",
        "AgeInMonths": 1,
        "Count": 1
    },
    {
        "Facility Name": "ACLC-111",
        "Customer Name": "Amazon",
        "AgeInMonths": 6,
        "Count": 1
    }
]

const areasData = [
    {
        "Facility Name": "ACLC-II",
        "Customer Name": "Amazon",
        "Area": 67000
    },
    {
        "Facility Name": "ACLC-II",
        "Customer Name": "Spice Jet",
        "Area": 34000
    },
    {
        "Facility Name": "ACLC-III",
        "Customer Name": "UPS",
        "Area": 25000
    },
    {
        "Facility Name": "ACLC-III",
        "Customer Name": "ABC",
        "Area": 22000
    }
]

const CommercialHeadDashboard = () => {

    const [invoiceBool, setInvoiceBool] = useState(false);
    const [userDetails, setUserDetails] = useState([])
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isMEdiumScreen, setIsMediumScreen] = useState(false);

    const [agingReportData, setAgingReportData] = useState(agingData);
    const [barChartFacilitiesList, setBarChartFacilitiesList] = useState([]);
    const [barChartSelectedFacility, setBarChartSelectedFacility] = useState('');
    const [facilitiesList, setFacilitiesList] = useState([]);
    const [selectedFacility, setSelectedFacility] = useState('');
    const [pieChartLabels, setPieChartLabels] = useState([]);
    const [occupiedAreas, setOccupiedAreas] = useState([]);
    const [customerSpaceData, setCustomerSpaceData] = useState(areasData);




    useEffect(() => {

        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768); // Adjust the threshold as needed
            setIsMediumScreen(window.innerWidth > 768 && window.innerWidth < 1070)
        };

        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const generateUniqueColor = (index) => {
        const colors = [
            '#FFCD56', '#4BC0C0', '#9966FF', '#FF9F40',
            '#FF5733', '#36A2EB', '#FFCE56', '#4BC0C0'

        ];
        return colors[index % colors.length];
    };


    useEffect(() => {
        const groupedData = agingData.reduce((acc, item) => {
            const facility = item["Facility Name"];
            if (!acc[facility]) {
                acc[facility] = { "Facility Name": facility, "Counts": [0, 0, 0, 0] };
            }
            const ageIndex = [1, 2, 6, 12].indexOf(item.AgeInMonths);
            if (ageIndex !== -1) {
                acc[facility]["Counts"][ageIndex] += item.Count;
            }
            return acc;
        }, {});

        const transformedData = Object.values(groupedData);
        const data = transformedData.map((item, index) => {
            return {
                label: item["Facility Name"],
                data: item["Counts"],
                backgroundColor: generateUniqueColor(index),
                borderColor: generateUniqueColor(index),
            }
        })
        setAgingReportData(data);
        const facilitiesFromAgingData = agingData.map(item => item["Facility Name"]);
        setBarChartFacilitiesList([...new Set(facilitiesFromAgingData)]);
    }, [])

    useEffect(() => {
        const facilities = areasData.map(item => item["Facility Name"]);
        setFacilitiesList([...new Set(facilities)]);
        setSelectedFacility(facilities[0]);
        const initialFilteredAreas = areasData.filter(item => item["Facility Name"] === facilities[0]);
        setOccupiedAreas(initialFilteredAreas.map(item => ({ label: item["Customer Name"], area: item["Area"] })));
        setPieChartLabels([...(initialFilteredAreas.map(item => item.label)), 'Unoccupied Area']);
    }, [])

    const handleChangeFacility = (e) => {
        setSelectedFacility(e.target.value);

        const filteredAreas = areasData.filter(item => item["Facility Name"] === e.target.value);
        setOccupiedAreas(filteredAreas.map(item => ({ label: item["Customer Name"], area: item["Area"] })));

    }


    const rawData = [
        {
            "contract_status": "active",
            "contract_count": "1600",
            "total_contracts": "2920"
        },
        {
            "contract_status": "pending",
            "contract_count": "933",
            "total_contracts": "2920"
        },
        {
            "contract_status": "draft",
            "contract_count": "387",
            "total_contracts": "2920"
        }
    ]


    const handleBarChartFacilityChange = (e) => {
        setBarChartSelectedFacility(e.target.value);
        const filteredData = e.target.value ? agingData.filter(item => item["Facility Name"] === e.target.value) : agingData;
        const groupedData = filteredData.reduce((acc, item) => {
            const key = e.target.value ? item["Customer Name"] : item["Facility Name"];
            if (!acc[key]) {
                acc[key] = { "Name": key, "Counts": [0, 0, 0, 0] };
            }
            const ageIndex = [1, 2, 6, 12].indexOf(item.AgeInMonths);
            if (ageIndex !== -1) {
                acc[key]["Counts"][ageIndex] += item.Count;
            }
            return acc;
        }, {});

        const transformedData = Object.values(groupedData);
        const data = transformedData.map((item, index) => {
            return {
                label: item["Name"],
                data: item["Counts"],
                backgroundColor: generateUniqueColor(index),
                borderColor: generateUniqueColor(index),
            }
        });
        setAgingReportData(data);
    }

    return (
        <>
            <NavBarTop />
            <LeftNavBar />
            <div className="fm-dashboard-container">
                <h1 className="fm-dashboard-heading">Dashboard</h1>
                <div className="dashboard_cards">
                    <DashboardCard data={rawData} />
                </div>
                <div style={{ marginRight: "10px" }} className={`d-flex gap-2 ${isSmallScreen ? 'flex-column' : ''}`}>
                    <div style={{ flex: 1 }}>
                        <BarChart
                            datasets={agingReportData}
                            facilities={barChartFacilitiesList}
                            selectedFacility={barChartSelectedFacility}
                            handleFacilityChange={(e) => handleBarChartFacilityChange(e)}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <PieChart
                            areaData={customerSpaceData}
                            facilities={facilitiesList}
                            selectedFacility={selectedFacility}
                            handleChangeFacility={handleChangeFacility} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommercialHeadDashboard
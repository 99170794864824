import React, { useEffect, useState } from 'react'
import FormsList from './FormsList'
import TemplatesList from './TemplatesList'
import PanelsList from './PanelsList'
import FieldsList from './FieldsList'
import { useLocation } from 'react-router-dom'
import ArchiveForms from './ArchiveForms'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'

export default function FormBuilder() {

    const [activeTab, setActiveTab] = useState("Forms")

    const tabs = ["Filled Forms", "Forms", "Templates", "Panels", "Fields"]
    const location = useLocation()
    let data = location?.state

    useEffect(() => {
        if (data !== null) {
            setActiveTab(data.name)
        } else {
            setActiveTab("Forms")
        }
    }, [])

    const handleSetActiveTab = (tab) => {
        setActiveTab(tab)
    }

    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>
            <div className='formbuilder-homepage' style={{ display: "flex", flexDirection: "column" }}>
                <h1 style={{ flex: "0 1 auto" }}>Form Builder</h1>
                <div className='d-flex flex-wrap my-3' style={{ borderBottom: "1px solid lightgray", width: "fit-content", flex: "0 1 auto" }}>
                    {tabs.map((tab) => {
                        return <span key={tab} className={`${activeTab === tab ? "formbuiler-activeTab" : ""} formbuilder-tab`} onClick={() => {
                            setActiveTab(tab)
                        }}>{tab}</span>
                    })}
                </div>
                <div style={{ flex: "1 1 auto", overflow: "auto" }}>
                    {activeTab === "Forms" ? <FormsList handleSetActiveTab={handleSetActiveTab} /> : activeTab === "Templates" ? <TemplatesList /> : activeTab === "Panels" ? <PanelsList /> : activeTab === "Fields" ? <FieldsList /> : <ArchiveForms />}
                </div>
            </div>
        </>
    )
}

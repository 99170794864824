import React, { useEffect } from 'react'

export default function Dropdown({ object, handleFormData, formUse, data, prefilledData, viewOnly }) {

  useEffect(() => {
    formUse && handleFormData && handleFormData(object.content, "")
  }, [])

  return (
    <div className='d-flex align-items-center justify-content-between gap-4 w-100' style={{ padding: "8px" }}>
      <label>{object.content}</label>
      {prefilledData && prefilledData[object?.content] ? <span style={{ borderRadius: "8px", width: "60%", fontSize: "12px", color: "gray" }}>{prefilledData[object?.content]}</span> :
        viewOnly ? <span style={{ borderRadius: "8px", width: "60%", fontSize: "12px", color: "gray" }}>{data[object?.content]}</span> : <select className='form-control p-2' style={{ borderRadius: "8px", width: "60%" }}
          value={data && data[object.content]}
          onChange={(e) => handleFormData && handleFormData(object.content, e.target.value)}>
          <option hidden>Select</option>
          {object.options.map((option, index) => {
            return <option key={index} value={option}>{option}</option>
          })}
        </select>}
    </div>
  )
}

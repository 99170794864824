import React, { useState, useRef, useEffect } from "react";
import { FaFilePdf } from "react-icons/fa";
import { GrDocumentPdf } from "react-icons/gr";

import Modal from "react-bootstrap/Modal";
import { IoImage } from "react-icons/io5";
import { Button } from "react-bootstrap";
import axios from "../../axios-interceptor"
import { base_url } from "../../utilities";
import Loader from "../Reusable_Components/loader";
import { set } from "date-fns";
import { useSelector } from "react-redux";
import Alert from 'react-bootstrap/Alert';
import AlertPopUp from "./Alert";

export default function UploadComponent({
  onFilesSelected, //list of files currently uploaded through the component
  uploadMethod, //single or multi file upload
  viewMode, //boolean value to determine if the component is in view mode
  uploadedFileData, //list of files that are already uploaded
  uploadActionClicked, //function to upload a specific file
  deleteSpecificFileMain, //function to delete a specific file
  maxNumberOfFiles, //maximum number of files that can be uploaded
  handleErrorFromDocument, //function to handle error from document
  idType, //type of id
  acceptedFileTypes, //accepted file types by the input format pass as string ".pdf, .jpeg, .jpg, .png, .pfx"
}) {

  const documentLoaderTrigger = useSelector((state) => state.documentLoaderTrigger);
  const [documentLoader, setDocumentLoader] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [prevUploadedFiles, setPrevUploadedFiles] = useState(uploadedFileData || null);

  const error = useSelector((state) => state.error);
  const [errorValue, setErrorValue] = useState(null)
  const [errorHandleAlert, setErrorHandleAlert] = useState(false)

  // ========================error useeffect =================
  useEffect(() => {
    setErrorValue(error?.response?.data.message);
    if (error) {
      setErrorHandleAlert(true);
    }

  }, [error])

  useEffect(() => {
    if (uploadedFileData) {
      setPrevUploadedFiles(uploadedFileData);
    }
  }, [uploadedFileData]);

  const [existingFileAlert, setExistingFileAlert] = useState(false);
  const [tempFileData, setTempFileData] = useState([])

  const handleFileChange = (e) => {
    const nameIsExistingFile = prevUploadedFiles?.some(file => e.target.files[0].name.includes(file.file_name))
    if (nameIsExistingFile) {
      setTempFileData(e.target.files)
      setErrorValue("Do you want to Overwrite the existing file?")
      setExistingFileAlert(true)

    }
    else {
      const selectedFiles = Array.from(e.target.files).map((file) => ({
        file,
        description: "",
        isUploaded: false,
        flag: null
      }));
      const updatedFileData = [...fileData, ...selectedFiles];
      setFileData(updatedFileData);
      onFilesSelected(updatedFileData);
    }

  };

  const handleOverWritingFile = () => {
    setExistingFileAlert(false)
    const selectedFiles = Array.from(tempFileData).map((file) => ({
      file,
      description: "",
      isUploaded: false,
      flag: "overwrite"
    }));
    const updatedFileData = [...fileData, ...selectedFiles];
    setFileData(updatedFileData);
    onFilesSelected(updatedFileData);
  }

  const uploadSpecificFile = (index) => {
    handleRemoveFile(index);
    fileData[index].isUploaded = true;
    uploadActionClicked(index);
  };

  const [showConfirmDeleteModel, setConfirmDeleteModel] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedKey, setSelectedKey] = useState();

  const handleRemoveFile = (index) => {
    if (fileData[index].isUploaded) {
      deleteSpecificFileMain(index);
    }
    const newFileData = fileData.filter((_, i) => i !== index);
    setFileData(newFileData);
    onFilesSelected(newFileData);
  };

  const handleRemoveUploadedFile = (index, s3_key) => {
    setSelectedIndex(index);
    setSelectedKey(s3_key);
    setConfirmDeleteModel(true);
  };

  const handleDescriptionChange = (index, description) => {
    const newFileData = fileData.map((item, i) =>
      i === index ? { ...item, description } : item
    );
    setFileData(newFileData);
    onFilesSelected(newFileData);
  };

  const renderFilePreview = (file) => {
    const fileType = file.type;
    if (fileType.startsWith("image/")) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          style={{ height: "40px", width: "40px" }}
        />
      );
    } else if (fileType === "application/pdf") {
      return <GrDocumentPdf size={40} />;
    } else {
      return <span>{file.name}</span>;
    }
  };

  const handleCloseConfirmDeleteModal = () => {
    setConfirmDeleteModel(false);
  };

  const handleDeleteUploadedElement = () => {
    setConfirmDeleteModel(false);
    setDocumentLoader(true)
    axios
      .delete(base_url + `documents`, {
        data: {
          s3_keys: [selectedKey],
        },
      })
      .then((res) => {
        setDocumentLoader(false)
        let value = prevUploadedFiles;
        const newFileData = value.filter((_, i) => i !== selectedIndex);
        setPrevUploadedFiles(newFileData);
      })
      .catch((err) => {
        setDocumentLoader(false)
        // console.log(err);
        setErrorValue(error.response.data.message)
        handleErrorFromDocument(err.response.data.message)
      });
  };

  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const handleDownloadDocument = (key_value) => {
    const encodedKeyValue = encodeURIComponent(key_value);
    axios
      .get(
        base_url +
        `documents?filter_condition=%7B%22s3_keys%22%3A%5B%22` +
        encodedKeyValue +
        "%22%5D%7D"
      )
      .then((res) => {
        setDocumentLoader(false)
        const url = window.URL.createObjectURL(
          new Blob([base64ToArrayBuffer(res.data.data[0].file.slice(2, -1))], {
            type: "application/" + res.data.data[0].file_type,
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", res.data.data[0].file_name + "." + res.data.data[0].file_type);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setDocumentLoader(false)
        // console.log(err);
        setErrorValue(err.response.data.message)
        handleErrorFromDocument(err.response.data.message)
      });
  };

  return (
    <>
      <AlertPopUp
        alertShow={existingFileAlert}
        alertType="error"
        alertMsg="File with the same name already exists!"
        alertValue={errorValue}
        alertButtons={[{ name: "Overwrite", action: () => { handleOverWritingFile() } }, { name: "Cancel", action: () => { setExistingFileAlert(false) } },]} />
      <Modal
        show={showConfirmDeleteModel}
        centered
        backdrop="static"
        onHide={handleCloseConfirmDeleteModal}
      >
        <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
          <p>Are you sure you want to delete this File?</p>
          <span style={{ fontSize: "small" }}>
            Deleting this file will permenantly remove the file from the system!
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setConfirmDeleteModel(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteUploadedElement}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      {
        documentLoader || documentLoaderTrigger ?
          <Loader />
          : null
      }

      {
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              type="file"
              id={idType}
              {...(uploadMethod === "multi" ? { multiple: true } : {})}
              onChange={handleFileChange}
              disabled={
                viewMode ||
                (maxNumberOfFiles !== 1 ? (prevUploadedFiles && prevUploadedFiles.length) +
                (fileData && fileData.length) >=
                maxNumberOfFiles : false)
              }
              style={{
                padding: "10px",
                border: "1px solid grey",
                borderRadius: "5px",
                marginBottom: "20px",
                display: "none",
                width: "100%",
              }}
              accept={acceptedFileTypes}
            />
            <label
              htmlFor={idType}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "8px 10px",
                border: "1px solid grey",
                borderRadius: "5px",
                marginBottom: "20px",
                cursor: "pointer",
                backgroundColor:
                  viewMode ||
                    (prevUploadedFiles && prevUploadedFiles.length) +
                    (fileData && fileData.length) >=
                    5
                    ? "#f0f0f0"
                    : "white",
                //  width:"100%"
              }}
            >
              <span style={{ fontWeight: "bold" }}>Add a file</span>
              <span style={{ fontSize: "10px" }}>
                *You can upload a maximum of {maxNumberOfFiles} files, only accepts {acceptedFileTypes} file types.
              </span>
            </label>
            <span
              style={{
                fontSize: "10px",
                marginTop: "-18px",
                marginBottom: "10px",
              }}
            >
              Total files uploaded:{" "}
              {prevUploadedFiles?.length + fileData?.length}
            </span>
          </div>
          <div style={{ maxHeight: "200px", overflow: "auto" }}>
            {prevUploadedFiles &&
              prevUploadedFiles.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginRight: "20px",
                      alignItems: "center",
                    }}
                  >
                    {index + 1}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "110px",
                    }}
                    onClick={() => {
                      setDocumentLoader(true)
                      handleDownloadDocument(item.s3_key)

                    }}
                    title={item.file_name + "." + item.file_type}
                  >
                    <div>
                      {item.file_type === "pdf" ? (
                        <GrDocumentPdf size={40} />
                      ) : (
                        <IoImage size={40} />
                      )}
                    </div>
                    <span
                      style={{
                        color: "green",
                        marginTop: "10px",
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100px", // adjust this value as per your requirement
                      }}
                    >
                      {item.file_name + "." + item.file_type}
                    </span>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <textarea
                      value={item.file_description}
                      // onChange={(e) => handleDescriptionChange(index, e.target.value)}
                      disabled={true}
                      placeholder="Enter file description..."
                      style={{
                        width: "100%",
                        minHeight: "100%",
                        border: "1px solid grey",
                        borderRadius: "5px",
                        fontSize: "12px",
                      }}
                      maxLength={250}
                    />
                  </div>
                  {/* {viewMode ? null : (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <button
                        style={{
                          border: "1px solid grey",
                          borderRadius: "5px",
                          marginLeft: "5px",
                        }}
                        onClick={() =>{
                            
                            handleRemoveUploadedFile(index, item.s3_key)
                        }
                        }
                      >
                        ✗
                      </button>
                    </div>
                  )} */}
                </div>
              ))}
            {fileData.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  {(prevUploadedFiles ? prevUploadedFiles.length : 0) +
                    index +
                    1}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "110px",
                  }}
                  onClick={() => {
                    setDocumentLoader(true)
                    const url = URL.createObjectURL(item.file);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = item.file.name;
                    link.click();
                    setDocumentLoader(false)
                  }}
                  title={item.file.name}
                >
                  <div>{renderFilePreview(item.file)}</div>
                  <div
                    style={{
                      color: "green",
                      marginTop: "10px",
                      fontSize: "12px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100px", // adjust this value as per your requirement
                    }}
                  >
                    {item.file.name}
                  </div>
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <textarea
                    value={item.description}
                    onChange={(e) =>
                      handleDescriptionChange(index, e.target.value)
                    }
                    placeholder="Enter file description..."
                    disabled={viewMode}
                    style={{
                      // minWidth: "200px",
                      minHeight: "100%",
                      border: "1px solid grey",
                      borderRadius: "5px",
                      fontSize: "12px",
                    }}
                    maxLength={250}
                  />
                </div>
                {viewMode ? null : (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {item.isUploaded ? null : (
                      <>
                        <button
                          style={{
                            border: "1px solid grey",
                            borderRadius: "5px",
                            marginLeft: "10px",
                          }}
                          onClick={() => uploadSpecificFile(index)}
                        >
                          ✓
                        </button>
                        <button
                          style={{
                            border: "1px solid grey",
                            borderRadius: "5px",
                            marginLeft: "10px",
                          }}
                          onClick={() => handleRemoveFile(index)}
                        >
                          ✗
                        </button>
                      </>
                    )}

                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      }
    </>
  );
}

import React, { useEffect, useMemo, useState } from 'react'
import { Modal, Button, Table } from 'react-bootstrap';
import FormElement from './FormELement';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FormCanvas from './FormCanvas';
import Fields from './Fields';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Panels from './Panels';
import Templates from './Templates';
import Forms from './Forms';
import { CREATE_NEW_PANEL, CREATE_NEW_TEMPLATE, CREATION_SUCCESS, FETCHING_ERROR, SHOW_MODAL, createField, createNewForm, createNewPanel, createNewTemplate, getAllFields, getAllPanels, getAllTemplates } from '../../Redux/Actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import FormLoader from '../Reusable_Components/FromLoader';
import { MdErrorOutline } from 'react-icons/md';
import { sorting_lists } from '../Reusable_Components/ReusableFunctions';
import AlertPopUp from '../Reusable_Components/Alert';

const elements = [
    {
        type: "text",
        label: "Header"
    },
    {
        type: "paragraph",
        label: "Paragraph"
    },
    {
        type: "input",
        label: "Input"
    },
    // {
    //     type: "inputWithLabel",
    //     label: "Input Label"
    // },
    {
        type: "checkbox",
        label: "Checkbox"
    },
    {
        type: "date",
        label: "Date"
    },
    // {
    //     type: "table",
    //     label: "Table"
    // },
    {
        type: "textarea",
        label: "Textarea"
    },
    {
        type: "radio",
        label: "Radio"
    },
    {
        type: "upload",
        label: "Upload"
    }, {
        type: "toggle",
        label: "Toggle"
    }, {
        type: "dropdown",
        label: "Dropdown"
    }
    // , {
    //     type: "toggleInput",
    //     label: "Toggle with Input"
    // }
]

export default function Form() {

    const { name } = useParams()
    const location = useLocation()
    const data = name === "templateClone" && location?.state?.data
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const newFieldCreated = useSelector((state) => state.newFieldCreated)
    const loader = useSelector((state) => state.loading)
    const modal = useSelector((state) => state.showModal)
    const fieldsList = useSelector((state) => state.fieldsList)
    const panelsList = useSelector((state) => state.panelsList)
    const templatesList = useSelector((state) => state.templatesList)
    const createPanel = useSelector((state) => state.createPanel)
    const createTemplate = useSelector((state) => state.createTemplateSuccess)
    const fetchingError = useSelector((state) => state.fetchingError)
    const creationSuccess = useSelector((state) => state.creationSuccess)

    const [elementsList, setELementsList] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [fields, setFields] = useState([])
    const [panels, setPanels] = useState([])
    const [templates, setTemplates] = useState([])
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        setELementsList(elements)
        name === "panels" && dispatch(getAllFields())
        name === "templates" && dispatch(getAllPanels())
        name === "templateClone" && dispatch(getAllPanels())
        name === "forms" && dispatch(getAllTemplates())
    }, [])

    useEffect(() => {
        setFields(fieldsList)
    }, [fieldsList])

    useEffect(() => {
        setPanels(panelsList)
    }, [panelsList])

    useEffect(() => {
        setTemplates(templatesList)
    }, [templatesList])

    useMemo(() => {
        return sorting_lists(fieldsList)
    }, [fieldsList])

    useMemo(() => {
        return sorting_lists(panelsList)
    }, [panelsList])

    useMemo(() => {
        return sorting_lists(templatesList)
    }, [templatesList])


    const handleSaveNewField = async (newField) => {
        dispatch(createField(newField))
    };

    const handleSaveNewPanel = async (newPanel) => {
        dispatch(createNewPanel(newPanel))

    };
    const handleSaveNewTemplate = async (newTemplate) => {
        dispatch(createNewTemplate(newTemplate))
    };

    const handleSaveNewForm = async (newForm) => {
        dispatch(createNewForm(newForm))
    };


    const handleSearchFilter = (e) => {
        switch (name) {
            case "fields":
                if (e.target.value === "") {
                    setELementsList(elements)
                } else {
                    const filteredElements = elements?.filter((element) => element.label.toLowerCase().includes(e.target.value.toLowerCase()))
                    setELementsList(filteredElements)
                }
                break;
            case "panels":
                if (e.target.value === "") {
                    setFields(fieldsList)
                } else {
                    const filteredFields = fieldsList?.filter((field) => field.label.toLowerCase().includes(e.target.value.toLowerCase()))
                    setFields(filteredFields)
                }
                break;
            case "templates":
                if (e.target.value === "") {
                    setPanels(panelsList)
                } else {
                    const filteredPanels = panelsList?.filter((panel) => panel.panel_name.toLowerCase().includes(e.target.value.toLowerCase()))
                    setPanels(filteredPanels)
                }
                break;
            case "templateClone":
                if (e.target.value === "") {
                    setPanels(panelsList)
                } else {
                    const filteredPanels = panelsList?.filter((panel) => panel.panel_name.toLowerCase().includes(e.target.value.toLowerCase()))
                    setPanels(filteredPanels)
                }
                break;
            case "forms":
                if (e.target.value === "") {
                    setTemplates(templatesList)
                } else {
                    const filteredTemplates = templatesList?.filter((template) => template.template_name.toLowerCase().includes(e.target.value.toLowerCase()))
                    setTemplates(filteredTemplates)
                }

            default:
                break;
        }
    }

    return (
        <div className='form_builder_container'>
            <DndProvider backend={HTML5Backend}>
                <div className='form_builder'>
                    <div className='d-flex flex-column gap-3' style={{ height: "95%", overflow: "auto" }}>
                        <h5>{name === "panels" ? "Fields" : (name === "templates" || name === "templateClone") ? "Panels" : name === "forms" ? "Templates" : "Elements"}</h5>
                        <input type='search' placeholder={name === "forms" ? "Search Templates" : name === "templates" || name === "templateClone" ? "Search panels" : name === "panels" ? "Search fields" : 'Search elements'} className='form-control' onChange={(e) => handleSearchFilter(e)} />
                        {name === "fields" && <div className='d-flex flex-column gap-2'>
                            {elementsList?.map((element, index) => {
                                return <Fields key={index} element={element} />
                            })}
                        </div>}
                        {name === "panels" && <div className='d-flex flex-column gap-2'>
                            {fields && fields?.map((field, index) => {
                                return <Panels key={index} element={field} />
                            })}
                        </div>}
                        {name === "templates" && <div className='d-flex flex-column gap-2'>
                            {panels && panels.map((panel, index) => {
                                return <Templates key={index} element={panel} />
                            })}
                        </div>}
                        {name === "templateClone" && <div className='d-flex flex-column gap-2'>
                            {panels && panels.map((panel, index) => {
                                return <Templates key={index} element={panel} />
                            })}
                        </div>}
                        {name === "forms" && <div className='d-flex flex-column gap-2'>
                            {templates && templates?.map((template, index) => {
                                return <Forms key={index} element={template} />
                            })}
                        </div>}
                    </div>
                    <div style={{ height: "5%", fontSize: "12px", display: 'flex', gap: "15px" }}>
                        <b>*Note</b>  <span>Drag elements from here</span>
                    </div>
                </div>
                <div className='form_builder_rightside_container'>
                    <FormCanvas handleSaveNewField={handleSaveNewField}
                        handleSaveNewPanel={handleSaveNewPanel}
                        name={name} handleSaveNewTemplate={handleSaveNewTemplate}
                        handleSaveNewForm={handleSaveNewForm}
                        data={name === "templateClone" && data}
                    />
                </div>
            </DndProvider>
            {loader && <h1>
                <FormLoader />
            </h1>}
            {creationSuccess && <AlertPopUp
                alertShow={creationSuccess}
                alertType="success"
                alertMsg="Success"
                alertValue={creationSuccess}
                alertButtons={[{
                    name: "Proceed", action: () => {
                        dispatch({
                            type: CREATION_SUCCESS,
                            payload: null
                        })
                        switch (name) {
                            case "fields":
                                navigate('/formbuilder', { state: { name: "Fields" } })
                                dispatch({
                                    type: SHOW_MODAL,
                                    payload: false
                                })
                                break;
                            case "panels":
                                navigate('/formbuilder', { state: { name: "Panels" } })
                                break;
                            case "templates":
                                navigate('/formbuilder', { state: { name: "Templates" } })
                                break;
                            case "templateClone":
                                navigate('/formbuilder', { state: { name: "Templates" } })
                                break;
                            case "forms":
                                navigate('/formbuilder', { state: { name: "Forms" } })
                                break;

                            default:
                                break;
                        }
                    }
                }]}
                path={true}
            />}
            {fetchingError && <Modal keyboard={false} show={fetchingError} onHide={() => {
                dispatch({
                    type: FETCHING_ERROR,
                    payload: null
                })
            }} centered>
                <Modal.Header><div className='d-flex align-items-center gap-2'><MdErrorOutline color='red' style={{ height: "25px", width: "25px" }} /><span className='fs-3'>Error</span></div></Modal.Header>
                <Modal.Body className="text-start"><span>{fetchingError}</span></Modal.Body>
                <Modal.Footer className="footer d-flex justify-content-end">
                    <Button variant="secondary" onClick={() => {
                        dispatch({
                            type: FETCHING_ERROR,
                            payload: null
                        })
                    }}
                    >Close</Button>
                    <Button variant="secondary" onClick={() => {
                        switch (name) {
                            case "panels":
                                dispatch(getAllFields())
                                break;
                            case "templates":
                                dispatch(getAllPanels())
                                break;
                            case "templateClone":
                                dispatch(getAllPanels())
                                break;
                            case "forms":
                                dispatch(getAllTemplates())
                                break;
                            default:
                                break;
                        }
                        dispatch({
                            type: FETCHING_ERROR,
                            payload: null
                        })
                    }}
                    >Retry</Button>
                </Modal.Footer>
            </Modal>}
        </div>
    )
}
import React, { useEffect } from 'react'

export default function Checkbox({ object, formUse, handleFormData, data, prefilledData, viewOnly }) {

    useEffect(() => {
        formUse && handleFormData && handleFormData(object.content, false)
    }, [])

    return (
        <div className='d-flex gap-3 align-items-center' style={{ padding: "8px" }}>
            <input type='checkbox' id={object.content}
                checked={prefilledData && prefilledData[object?.content] ? prefilledData[object?.content] : data && data[object.content] ? data[object.content] : false}
                disabled={prefilledData && prefilledData[object?.content] ? true : viewOnly ? viewOnly : false}
                onChange={(e) => { formUse && handleFormData && handleFormData(object.content, e.target.checked) }} />
            <label htmlFor={object.content}>{object.content}</label>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { RxCross2 } from 'react-icons/rx'
import Element from './Element'
import { MdOutlineInsights } from "react-icons/md";
import { Modal } from 'react-bootstrap';
import { stream } from 'xlsx';
import Summary from './Summary';
import Search from '../Reusable_Components/Search';

const format_obj = {
    "Summary": {
        "Safety": {
            "Total Safety Precautions": 0,
            "Precautions Passed": 0,
            "Precautions Failed": 0,
            "Failed Precaution Details": {
                "Row": 0,
                "Description": "",
                "Remarks": ""
            },
            "Recommendations": []
        },
        "Risk": {
            "Total Performance Checks": 0,
            "Checks Passed": 0,
            "Checks Failed": 0,
            "Failed Check Details": {
                "Row": 0,
                "Description": "",
                "Remarks": ""
            },
            "Recommendations": []
        },
        "Cost": {
            "Total Materials Required": 0,
            "Materials Used": 0,
            "Remarks": "",
            "Recommendations": []
        }
    },
    "Actions to be Taken": {
        "Immediate Actions": [
            "",
            ""
        ],
        "Long-term Actions": [
            "",
            ""
        ]
    }
}

let item = {
    level: 1,
    title: "Unintentional",
    incorporatedElements: "Unawareness, rejection",
    barriers: "Training",
    tools: "Safety",
    color: "#a7d8de"
}

export default function ArchiveForms() {

    const [usedForms, setUsedForms] = useState([])
    const [preview, setPreview] = useState(false)
    const [previewData, setPreviewData] = useState({})
    const [form, setForm] = useState([])
    const [formData, setFormData] = useState({})
    const [currentTime, setCurrentTime] = useState(new Date())
    const [footerValues, setFooterValues] = useState({})
    const [searchValue, setSearchValue] = useState("")

    const [insights, setInsights] = useState(null)
    const [showInsights, setShowInsights] = useState(false)

    // const data = {
    //     "Summary": {
    //         "Safety Perspective": {
    //             "Total Safety Precautions": 6,
    //             "Safety Precautions Followed": 5,
    //             "Safety Precautions Not Followed": 1,
    //             "Risk Level": "Moderate",
    //             "Recommendations": "Ensure all safety precautions are followed to mitigate risks."
    //         },
    //         "Risk Perspective": {
    //             "Total Risks Identified": 3,
    //             "Risks Mitigated": 2,
    //             "Risks Not Mitigated": 1,
    //             "Risk Level": "Moderate",
    //             "Recommendations": "Address the identified risks to reduce potential impact."
    //         },
    //         "Cost Perspective": {
    //             "Estimated Cost of Maintenance": "To be determined based on materials required",
    //             "Savings Potential": "Regular maintenance can help prevent costly breakdowns",
    //             "Recommendations": "Regular maintenance schedule should be followed to avoid unexpected expenses."
    //         }
    //     },
    //     "Actions": {
    //         "Safety Actions": [
    //             {
    //                 "Action": "Ensure use of insulated hand gloves while working.",
    //                 "Status": "Incomplete",
    //                 "Remarks": "no hand gloves"
    //             }
    //         ],
    //         "Risk Actions": [
    //             {
    //                 "Action": "Ensure power supply is not available at the i/p terminal of the equipment.",
    //                 "Status": "Incomplete",
    //                 "Remarks": "No power supply"
    //             }
    //         ]
    //     }
    // }

    useEffect(() => {
        let items = JSON.parse(localStorage.getItem("Used Forms"))
        items = items?.sort((a, b) => new Date(b?.time) - new Date(a?.time))
        items && setUsedForms(items)
    }, [])

    console.log(usedForms)

    const timeElapsed = (createdAt) => {
        const diff = Math.floor((currentTime - new Date(createdAt)) / 1000); // Difference in seconds

        if (diff < 60) {
            return `${diff} sec ago`;
        } else if (diff < 3600) {
            return `${Math.floor(diff / 60)} min ago`;
        } else if (diff < 86400) {
            return `${Math.floor(diff / 3600)} hours ago`;
        } else {
            return `${Math.floor(diff / 86400)} days ago`;
        }
    };

    const handleSearchForms = (e) => {
        let { value } = e.target
        setSearchValue(value)
        // let items = JSON.parse(localStorage.getItem("Used Forms"))
        // items = items?.sort((a, b) => new Date(b?.time) - new Date(a?.time))
        // items = items?.filter((item) => item.formName.toLowerCase().includes(value.toLocaleLowerCase()))
        // setUsedForms(items)
    }

    const handleGetInsights = async (form_data) => {
        // const data = await getChatGPTResponse(form_data)
        // !insights && setInsights(JSON.parse(data))
        setShowInsights(true)
        setInsights({
            "Summary": {
                "Safety": {
                    "Total Safety Precautions": 8,
                    "Precautions Passed": 5,
                    "Precautions Failed": 3,
                    "Failed Precaution Details": {
                        "Row": 3,
                        "Description": "Make sure that supply is not available at the i/p terminal of the equipment.",
                        "Remarks": "No power supply"
                    },
                    "Recommendations": [
                        "Provide power supply isolation before performing maintenance",
                        "Ensure availability of necessary safety equipment like insulated hand gloves"
                    ]
                },
                "Risk": {
                    "Total Performance Checks": 8,
                    "Checks Passed": 5,
                    "Checks Failed": 3,
                    "Failed Check Details": {
                        "Row": 4,
                        "Description": "Use of insulated hand gloves while working.",
                        "Remarks": "Hand gloves not available"
                    },
                    "Recommendations": [
                        "Ensure availability of insulated hand gloves for all maintenance personnel",
                        "Provide training on the importance of using proper PPE"
                    ]
                },
                "Cost": {
                    "Total Materials Required": 0,
                    "Materials Used": 0,
                    "Remarks": "",
                    "Recommendations": []
                }
            },
            "Actions to be Taken": {
                "Immediate Actions": [
                    "Isolate the incoming source and apply LOTO before performing any maintenance work",
                    "Provide necessary safety equipment like insulated hand gloves and hard hats"
                ],
                "Long-term Actions": [
                    "Implement regular safety training for all maintenance personnel",
                    "Establish a system for ensuring availability of all required safety equipment at the site"
                ]
            }
        })
    }

    // const getChatGPTResponse = async (form_data) => {
    //     const prompt = `${JSON.stringify(form_data)} \n Summarize from safety perspective, risk perspective, and cost perspective. Give your recommendations and actions to be taken. Return the data in the following JSON format: ${JSON.stringify(format_obj, null, 2)}`;

    //     if (!insights) {
    //         try {
    //             const response = await fetch('https://api.openai.com/v1/chat/completions', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer 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`, // Replace with your API key securely
    //                 },
    //                 body: JSON.stringify({
    //                     model: "gpt-3.5-turbo", // Specify the model
    //                     messages: [{ role: "user", content: prompt }], // Send the prompt as a user message
    //                     max_tokens: 1000, // Limit the response length
    //                     temperature: 0, // Reduce randomness
    //                     top_p: 1 // Consider the entire probability distribution
    //                 })
    //             });

    //             const responseData = await response.json();

    //             // console.log(responseData)

    //             // Check for errors in response
    //             if (response.ok) {
    //                 return responseData.choices[0].message.content; // Extract the response text
    //             } else {
    //                 console.error("Error in response:", responseData);
    //                 return "I'm having trouble fetching the next question, please provide more information.";
    //             }
    //         } catch (error) {
    //             console.error("Error fetching data from ChatGPT:", error);
    //             return "I'm having trouble fetching the next question, please provide more information.";
    //         }
    //     } else {
    //         setShowInsights(true)
    //     }
    // }


    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center' style={{ lineHeight: "normal" }}>
                    <div style={{ fontSize: "24px" }}>Total</div>
                    <span>{usedForms.length}</span>
                </div>
                <div className='d-flex align-items-center'>
                    {/* <input type='search' placeholder='Search forms...' className='form-control'
                        onChange={handleSearchForms}
                    /> */}
                    <Search
                        value={searchValue}
                        handleSearch={handleSearchForms}
                    />
                    {/* <button className='btn btn-secondary' onClick={handleSearchForms}>Search</button> */}
                </div>
            </div>
            <table style={{ width: "100%" }} className='mt-4'>
                <thead>
                    <tr className='fin_man_table_head text-center'>
                        <th>Form Name</th>
                        <th>Description</th>
                        <th>Last used</th>
                        <th>Action</th>
                    </tr>
                </thead>
                {usedForms.length > 0 ? <tbody className='fin_man_table_body'>
                    {usedForms.map((form, index) => {
                        return <tr key={index}>
                            <td>{form?.formName}</td>
                            <td>{form?.description}</td>
                            <td>{timeElapsed(form.time)}</td>
                            <td className='text-center' role='button'>
                                <img src="./images/icons/View.png" alt="search-icon" onClick={() => {
                                    let forms = JSON.parse(localStorage.getItem("Forms"))
                                    let items = forms?.filter((item) => item.id === form.formId)
                                    setForm(items[0]?.items)
                                    setPreview(true)
                                    setPreviewData({ items: form?.items })
                                    setFormData(form?.formData)
                                    setFooterValues({
                                        createdAt: form?.time,
                                        updatedAt: form?.time,
                                        createdBy: "Dinesh",
                                        updatedBy: "Dinesh"
                                    })
                                }}
                                    className='mx-2'
                                />
                                <MdOutlineInsights className='mx-2' onClick={() => handleGetInsights(form?.formData)} />
                            </td>
                        </tr>
                    })}
                </tbody> : <tbody>
                    <tr style={{ textAlign: "center", backgroundColor: "white" }}>
                        <td colSpan={4}>No Forms Found</td>
                    </tr>
                </tbody>}
            </table>
            {preview &&
                <div className='form_preview_container'>
                    <div className='form_view'>
                        <RxCross2 className='close-form' onClick={() => {
                            setPreview(false)
                        }} />
                        {form?.map((previewObj, index) => {
                            return <div key={previewObj.id} id={previewObj.id + " " + index} className='d-flex'>
                                {previewObj?.items?.map((item, i) => {
                                    return <Element preview={true} key={i} object={item} data={formData} rowIndex={index} colIndex={i} />
                                })}
                            </div>
                        })}
                        <div className='d-flex justify-content-between flex-wrap mt-3 p-2' style={{ fontSize: "10px", borderTop: "1px solid gray" }}>
                            <span><b>Created at :</b> {footerValues?.createdAt?.split("T")[0] + "  " + new Date(footerValues?.createdAt).toLocaleTimeString()}</span>
                            <span><b>Last Updated at :</b> {footerValues?.updatedAt?.split("T")[0] + "  " + new Date(footerValues?.createdAt).toLocaleTimeString()}</span>
                            <span><b>Created by :</b> {footerValues?.createdBy}</span>
                            <span><b>Last updated by :</b> {footerValues?.updatedBy}</span>
                        </div>
                    </div>
                </div>
            }
            {showInsights && <Modal show={showInsights} size='lg' onHide={() => setShowInsights(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Insights</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {insights && <pre style={{ textWrap: "wrap" }}>{JSON.stringify(insights, null, 2)}</pre>} */}
                    <Summary item={insights?.Summary} />
                </Modal.Body>
            </Modal>
            }
        </div>
    )
}

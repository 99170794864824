import React, { useEffect, useState } from 'react'
import { GoSearch } from "react-icons/go";
import { MdCurrencyRupee } from "react-icons/md";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap'
import NavBarTop from '../Reusable_Components/NavBarTop'
import LeftNavBar from '../Reusable_Components/LeftNavBar'
import { useDispatch, useSelector } from 'react-redux';
import { finmanGetMeterReadings, getEmailLogs, getGstSubmissionLogs, PUT_CH_UPDATE_METER_SUCCESS } from '../../Redux/Actions/Actions';
import { set } from 'date-fns';
import Loader from '../Reusable_Components/loader';
import AlertPopUp from '../Reusable_Components/Alert';
import { validateValue, timeElapsed } from "../Reusable_Components/ReusableFunctions";
import { SiVelog } from 'react-icons/si';
import Footer from "../Login/Footer";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export default function EmailLogs() {

    const emailLogs = useSelector(state => state.emailLogs)
    const contractApiLoader = useSelector(state => state.contractApiLoader)


    function formatDate(date) {
        const d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
    }

    const [currentPage, setCurrentPage] = useState(1)
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState({})
    const [selectMeter, setSelectMeter] = useState("all")
    const [sortOrder, setSortOrder] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        currentDate.setMonth(currentMonth - 1);
        currentDate.setDate(1);
        return formatDate(currentDate);
    });
    const [endDate, setEndDate] = useState(formatDate(new Date()));
    const meterReportsList = useSelector(state => state.meterList)
    const error = useSelector(state => state.error);
    const [isLoading, setIsLoading] = useState(false)
    //for alert pop up
    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])
    const [alertAction, setAlertAction] = useState([])

    const fetchReports = () => {
        // setIsLoading(true)
        const data = {
            start_date: startDate,
            end_date: endDate,
        };
        dispatch(getEmailLogs(data))
    }

    useEffect(() => {
        // setErrorValue(error?.response?.data.message);
        if (error?.response?.data.message || error?.data?.data[1]?.message) {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.data?.data[1] ? error?.data?.data[1].message : error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                    dispatch({
                        type: PUT_CH_UPDATE_METER_SUCCESS,
                        payload: null
                    })
                }
            }])
        }
        setIsLoading(false);
    }, [error])

    useEffect(() => {
        fetchReports()
    }, [])

    useEffect(() => {
        if (emailLogs?.length > 0) {
            setFilteredData([...emailLogs])
        } else {
            setFilteredData([])
        }
    }, [emailLogs])



    const sortContractNumber = () => {
        const sortedData = filteredData.sort((a, b) => {
            if (sortOrder) {
                return a.contract_number.localeCompare(b.contract_number)
            } else {
                return b.contract_number.localeCompare(a.contract_number)
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }

    const sortContractName = () => {
        const sortedData = filteredData.sort((a, b) => {
            if (sortOrder) {
                return a.contract_name.localeCompare(b.contract_name)
            } else {
                return b.contract_name.localeCompare(a.contract_name)
            }
        })
        setFilteredData([...sortedData]);
        setSortOrder(!sortOrder);
    }






    return (
        <>
            <>
                <NavBarTop />
                <LeftNavBar />
            </>

            {/* <> */}
            {/* {isLoading && <Loader />} */}
            {contractApiLoader && <Loader />}
            <AlertPopUp alertShow={alertShow} alertType={alertType} alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons} />
            {(currentPage === 1) && <div className='meter_container_reports '>
                <div className='fm-invoice-con'>
                    <div className='fm-invoice-heading-con  mb-1'>
                        <h3 className="invoice-heading">Email Logs</h3>
                    </div>
                </div>
                <div className='fm-invoice-con'>
                    <div className='fin-meter-reports-con'>
                        <div  style={{ width: '180px' }}>
                            <p style={{ marginBottom: '1px' }}>From date:&nbsp;</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label=""
                                value={dayjs(startDate)}
                                views={['day','month', 'year']}
                                maxDate={dayjs(new Date())} // Set minimum selectable date
                                onChange={(newValue) => {
                                    if(dayjs(endDate) < newValue){
                                        // const newEndDate = newValue.add(1, 'month')
                                        setEndDate(newValue.format("YYYY-MM-DD"))
                                    }
                                    setStartDate(newValue.format("YYYY-MM-DD"))
                                }
                                }
                                format="DD/MM/YYYY"
                                sx={{
                                  '.MuiInputBase-root': {
                                    height: '40px', // Adjust height
                                    backgroundColor: 'white', // Set background to white
                                    borderRadius: '6px', // Optional: Rounded corners
                                    fontSize: '14px', // Optional: Adjust text size
                                  },
                                }}
                                slotProps={{
                                  textField: {
                                    InputProps: {
                                      readOnly: true, // Prevent typing in the input field
                                    },
                                    onKeyDown: (e) => e.preventDefault(), // Block keyboard inputs
                                  },
                                }}
                              />
                            </LocalizationProvider>


                        </div>
                        <div  style={{ width: '180px' }}>
                            <p style={{ marginBottom: '1px' }}>To date:&nbsp;</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label=""
                                value={dayjs(endDate)}
                                views={['day','month', 'year']}
                                minDate={dayjs(startDate)} // Set minimum selectable date
                                maxDate={dayjs(new Date())} // Set minimum selectable date
                                onChange={(newValue) => {
                                    setEndDate(newValue.format("YYYY-MM-DD"))
                                }
                                }
                                format="DD/MM/YYYY"
                                sx={{
                                  '.MuiInputBase-root': {
                                    height: '40px', // Adjust height
                                    backgroundColor: 'white', // Set background to white
                                    borderRadius: '6px', // Optional: Rounded corners
                                    fontSize: '14px', // Optional: Adjust text size
                                  },
                                }}
                                slotProps={{
                                  textField: {
                                    InputProps: {
                                      readOnly: true, // Prevent typing in the input field
                                    },
                                    onKeyDown: (e) => e.preventDefault(), // Block keyboard inputs
                                  },
                                }}
                              />
                            </LocalizationProvider>


                        </div>

                        <button className="invoice_btn" style={{ marginTop: '18px', color: 'white', height: '20px !important' }} onClick={() => {
                            fetchReports()
                        }}>Apply</button>
                        <button className="invoice_btn" style={{ marginTop: '18px', color: 'white', height: '20px !important' }} onClick={() => {
                            const currentDate = new Date();
                            const currentMonth = currentDate.getMonth();
                            currentDate.setMonth(currentMonth - 1);
                            currentDate.setDate(1);
                            const startDate1 = formatDate(currentDate);
                            const endDate1 = formatDate(new Date())
                            setEndDate(endDate1);
                            setStartDate(startDate1)
                            setSelectMeter("all")
                            setSearchTerm('')
                            // setIsLoading(true)
                            const data = {
                                start_date: startDate1,
                                end_date: endDate1,
                            };
                            dispatch(getEmailLogs(data))
                        }}>Clear</button>
                    </div>
                </div>
                {/* {
                    "message_id": "010f019243e580e8-68ad87e3-864c-4f32-872a-90936b63958b-000000",
                "email_type": "User Set Password",
                "entity_id": "dinesh_karr",
                "sender": null,
                "recipients": "dinesh.dunna@turnkeylearning.com",
                "cc": null,
                "bcc": null,
                "failure_reason": null,
                "created_at": "2024-09-30 17:06:07"
        }, */}
                {selectMeter && <div className='fin_meter_reports_table' style={{ height: '58vh' }}>
                    <table>
                        <thead>
                            <tr className='fin_man_table_head commercial-head-table text-center'>
                                <th className='C-pointer' onClick={() => sortContractNumber()}>Email Type</th>
                                <th className='C-pointer' onClick={() => sortContractName()}>Entity Id</th>
                                <th>Sender</th>
                                <th>recipients</th>
                                <th>cc</th>
                                <th>bcc</th>
                                <th>Failure Reason</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody className='fin_man_table_body'>
                            {filteredData && filteredData?.length !== 0 ?
                                filteredData?.map((each, index) => {
                                    return <tr key={index} className='table_row'>
                                        <td >{each?.email_type}</td>
                                        <td >{each?.entity_id}</td>
                                        <td style={{ wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '150px' }}>{each?.sender}</td>
                                        <td style={{ wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '150px' }}>{each?.recipients}</td>
                                        <td style={{ wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '150px' }}>{each?.cc}</td>
                                        <td style={{ wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '150px' }}>{each?.bcc}</td>
                                        <td style={{ wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '150px' }}>{each?.failure_reason}</td>
                                        <td style={{ wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '150px' }}>{timeElapsed(each?.created_at)}</td>

                                    </tr>
                                })
                                :
                                <tr>
                                    <td colSpan='10' className='text-center'>No Records Found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>}
            </div>}
            {selectMeter === "all" ? <Modal keyboard={false} backdrop="static" show={showModal} style={{ height: "100vh" }} centered onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Meter Reading Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal_body'>
                    <div className='mb-4'>
                        <dl className="description-list">
                            <div className='d-flex'>
                                <span className='modal_desc'>Contract No.</span>
                                <dd>{modalData.contractNo}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>User Name</span>
                                <dd>{modalData.contract}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>Space Name</span>
                                <dd>{modalData.spaceName}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>Unit Name</span>
                                <dd>11</dd>
                            </div>

                        </dl>
                    </div>
                    <div>
                        <div className='d-flex'>
                            <span className='modal_desc'>Energy Meter No.</span>
                            <dd>{modalData.meterNumber}</dd>
                        </div>
                        <div className='d-flex'>
                            <div style={{ width: "50%" }}>
                                <p>EB Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.initialReadings}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.finalReadings}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.ratePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalAmount}</dd>
                                </div>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p>DG Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.initialReadings}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.finalReadings}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.ratePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalAmount}</dd>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className='d-flex'>
                            <span className='modal_desc'>Water Meter No.</span>
                            <dd>{modalData.waterMeterNumber}</dd>
                        </div>
                        <div className='d-flex'>
                            <div style={{ width: "50%" }}>
                                <p>EB Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.waterInitialReading}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.waterFinalReading}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalWaterUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.waterRatePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalWaterAMount}</dd>
                                </div>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p>DG Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.waterInitialReading}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.waterFinalReading}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalWaterUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.waterRatePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalWaterAMount}</dd>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='col' onClick={() => {
                        setCurrentPage(2)
                        setShowModal(false)
                    }
                    }>
                        Generate Draft Invoice
                    </Button>
                </Modal.Footer>
            </Modal> : selectMeter === "energy" ? <Modal keyboard={false} backdrop="static" show={showModal} style={{ height: "100vh" }} centered onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Energy Reading Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal_body'>
                    <div className='mb-4'>
                        <dl className="description-list">
                            <div className='d-flex'>
                                <span className='modal_desc'>Contract No.</span>
                                <dd>{modalData.contractNo}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>User Name</span>
                                <dd>{modalData.contract}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>Space Name</span>
                                <dd>{modalData.spaceName}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>Unit Name</span>
                                <dd>11</dd>
                            </div>

                        </dl>
                    </div>
                    <div>
                        <div className='d-flex'>
                            <span className='modal_desc'>Meter No.</span>
                            <dd>{modalData.meterNumber}</dd>
                        </div>
                        <div className='d-flex'>
                            <div style={{ width: "50%" }}>
                                <p>EB Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.initialReadings}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.finalReadings}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.ratePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalAmount}</dd>
                                </div>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p>DG Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.initialReadings}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.finalReadings}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.ratePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalAmount}</dd>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='col' onClick={() => {
                        setCurrentPage(2)
                        setShowModal(false)
                    }
                    }>
                        Generate Draft Invoice
                    </Button>
                </Modal.Footer>
            </Modal> : <Modal keyboard={false} backdrop="static" show={showModal} style={{ height: "100vh" }} centered onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Water Reading Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal_body'>
                    <div className='mb-4'>
                        <dl className="description-list">
                            <div className='d-flex'>
                                <span className='modal_desc'>Contract No.</span>
                                <dd>{modalData.contractNo}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>User Name</span>
                                <dd>{modalData.contract}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>Space Name</span>
                                <dd>{modalData.spaceName}</dd>
                            </div>
                            <div className='d-flex'>
                                <span className='modal_desc'>Unit Name</span>
                                <dd>11</dd>
                            </div>

                        </dl>
                    </div>
                    <div>
                        <div className='d-flex'>
                            <span className='modal_desc'>Meter No.</span>
                            <dd>{modalData.waterMeterNumber}</dd>
                        </div>
                        <div className='d-flex'>
                            <div style={{ width: "50%" }}>
                                <p>EB Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.waterInitialReading}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.waterFinalReading}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalWaterUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.waterRatePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalWaterAMount}</dd>
                                </div>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p>DG Reading</p>
                                <div className='d-flex mb-3 flex-wrap'>
                                    <div className='d-flex flex-column' style={{ width: "50%" }}>
                                        <span>Initial</span>
                                        <span className='modal_readings_box'>{modalData.waterInitialReading}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span>Final</span>
                                        <span className='modal_readings_box'>{modalData.waterFinalReading}</span>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='modal_desc' style={{ width: "60%" }}>Total units</span>
                                    <dd>{modalData.totalWaterUnits}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Rate per unit</span>
                                    <dd>{modalData.waterRatePerUnit}</dd>
                                </div>
                                <div className='d-flex'>
                                    <span style={{ width: "60%" }} className='modal_desc'>Total Amount</span>
                                    <dd>{modalData.totalWaterAMount}</dd>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='col' onClick={() => {
                        setCurrentPage(2)
                        setShowModal(false)
                    }
                    }>
                        Generate Draft Invoice
                    </Button>
                </Modal.Footer>
            </Modal>}
            <Footer />
            {/* </> */}
        </>
    )
}

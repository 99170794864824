// @component
//   @param {Array} props.events - The list of events to display in the day view. Each event should have `title` and `start_time` properties.
//   @param {Date} props.currentDay - The currently selected day to display the calendar.
 
//   @example
//  // Example usage of DayView component

//   import React, { useState } from 'react';
//   import DayView from './DayView';
  
//   const events = [
//       { 
//           title: 'Team Meeting', 
//           start_time: '2024-12-19T10:00:00' // Event at 10:00 AM 
//      },
//       { 
//           title: 'Client Call', 
//           start_time: '2024-12-19T14:30:00' // Event at 2:30 PM
//       },
//   ];
  
//   function App() {
//       const [selectedDay, setSelectedDay] = useState(new Date()); // Default to current day
  
//       return (
//          <div>
//              <h1>Day View Calendar</h1>
//              <DayView 
//                  events={events} // Pass the list of events
//                  currentDay={selectedDay} // Pass the selected day
//              />
//          </div>
//      );
//  }

import React, { useEffect, useMemo, useRef, useState } from 'react';

export default function DayView({ events = [], currentDay,clickDate,allTasks }) {


    const [tasks, setTasks] = useState(allTasks);
    const [selectedDate, setSelectedDate] = useState();
    const timeSlotRefs = useRef([]);

    useEffect(() => {
        if(allTasks){
            const tasksForDate = allTasks[selectedDate];
            setTasks(tasksForDate);
        }
    },[selectedDate ])
    // Memoized safeCurrentDay to handle invalid dates
    const safeCurrentDay = useMemo(() => {
        const date = new Date(clickDate).toLocaleDateString('en-CA');
        setSelectedDate(date);
        return currentDay instanceof Date && !isNaN(clickDate) ? new Date(clickDate) : new Date(clickDate);
    }, [clickDate]);

    // Memoized time slots to avoid unnecessary recalculations
    const timeSlots = useMemo(() => {
        const isSameDay = (date1, date2) => {
            return (
                date1.getDate() === date2.getDate() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getFullYear() === date2.getFullYear()
            );
        };

        return Array.from({ length: 24 }, (_, i) => {
            const hour = i % 12 === 0 ? 12 : i % 12; // 12-hour format
            const period = i < 12 ? 'AM' : 'PM';
            const timeLabel = `${hour}:00 ${period}`;

            const slotEvents = events.filter(event => {
                const eventDate = new Date(event.start_time);
                return eventDate.getHours() === i && isSameDay(eventDate, safeCurrentDay);
            });

            return { time: timeLabel, events: slotEvents };
        });
    }, [events, safeCurrentDay]);

    const getEventColor = (eventName) => {
        switch (eventName) {
            case 'Maintenance':
                return '#E9C46A'; 
            case 'AMC':
                return '#2A9D8F'; 
            case 'Daily Checklist':
                return '#F4A261'; 
            case 'PPM':
                return '#264653'; 
            default:
                return '#E76F51'; 
        }
    };

    useEffect(() => {
        const currentHour = new Date().getHours();
        if (timeSlotRefs.current[currentHour]) {
            timeSlotRefs.current[currentHour].scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }, [timeSlots]);

    return (
        <div className="fin_man_day_calendar_container">
            <div className="d-flex day_calendar_top_header">
                <div className="time"></div>
                <div className="task d-flex justify-content-center fs-5">
                    {safeCurrentDay.toLocaleDateString('en-IN', { weekday: 'long' })},{' '}
                    {safeCurrentDay.toLocaleDateString('en-IN', { day: 'numeric', month: 'long', year: 'numeric' })}
                </div>
            </div>

            {timeSlots.map((slot, index) => {

                const now = new Date();
                const currentHour = now.getHours();
                const minutesNow = now.getMinutes();

                const isCurrentHour = index === currentHour; //calculating if this slot is in the current hour

                // const timelabel = slot.time;
                const taskSlots = {};

                    for (let i = 0; i < 24; i++) {
                        taskSlots[i] = []; // Initialize an empty array for each hour
                    }

                    if(tasks?.length > 0){
                            tasks?.forEach(task => {
                            const taskStartTime = new Date(`1970-01-01T${task?.recurrence?.event_start_time}`);
                            const taskHour = taskStartTime.getHours();

                            if (!isNaN(taskHour)) {
                                taskSlots[taskHour].push(task);
                            }
                        });
                    }
                
                

                 return (
                    <div
                        className="d-flex"
                        key={index}
                        ref={el => (timeSlotRefs.current[index] = el)}
                        style={{ position: 'relative' }}
                    >
                        <div className="time">{slot.time}</div>
                        <div className={slot?.events?.length > 0 ? 'task' : 'task-add1'}>
                            {taskSlots[index]?.map((task, i) => (
                                <div
                                    key={i}
                                    className="task-item1"
                                    style={{
                                        backgroundColor: getEventColor(task.event_name),
                                    }}
                                >
                                    <div className="task-title">{task.event_name}</div>
                                    <div className="task-time">
                                        {task?.recurrence?.event_start_time}
                                    </div>
                                </div>
                            ))}
                            {/* Red line for the current time */}
                            {isCurrentHour && (
                                <div
                                    className="hour-line"
                                    style={{
                                        top: `calc(${(minutesNow / 60) * 100}%)`,
                                        // position: 'absolute',
                                        height: '2px',
                                        width: '86%',
                                        backgroundColor: 'red',
                                        zIndex: 10,
                                        marginLeft:'-20px'
                                    }}
                                />
                            )}
                            
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
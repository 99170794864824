import React, { useEffect } from 'react'

export default function Date({ object, data, handleFormData, formUse, prefilledData, viewOnly }) {

  useEffect(() => {
    formUse && handleFormData && handleFormData(object.content, data ? data[object?.content] : "")
  }, [])

  return (
    <div className='d-flex justify-content-between align-items-center gap-3 w-100' style={{ padding: "8px" }}>
      <label className='w-50'>{object?.content}</label>
      {prefilledData && prefilledData[object?.content] ? <span style={{ width: "85%", fontSize: "12px", color: "gray" }} >{prefilledData[object.content]}</span> :
        viewOnly ? <span style={{ width: "85%", fontSize: "12px", color: "gray" }} >{data && data[object?.content]}</span> : <input type='date' className='form-control' style={{ width: "80%" }}
          value={data && data[object.content]}
          onChange={(e) => handleFormData && handleFormData(object?.content, e.target.value)} />}
    </div>
  )
}

import { CEO_GROUP, COMMERCIAL_HEAD_GROUP, CRYPTO_SECRET_KEY, FACILITY_MANAGER_GROUP, FINANCE_MANAGER_GROUP, TECHNICIAN_GROUP } from "../../Config";
import { ACLS } from "../../Config";
import moment from 'moment';
import CryptoJS from 'crypto-js';


const convertUTCToLocal = (utcDate) => {
    const utcMoment = moment.utc(utcDate);
    const localMoment = utcMoment.local();
    return localMoment.format('YYYY-MM-DD HH:mm:ss');
};

export const getMonthName = (month) => {
    switch (month) {
        case 1:
            return "January";
        case 2:
            return "February";
        case 3:
            return "March";
        case 4:
            return "April";
        case 5:
            return "May";
        case 6:
            return "June";
        case 7:
            return "July";
        case 8:
            return "August";
        case 9:
            return "September";
        case 10:
            return "October";
        case 11:
            return "November";
        case 12:
            return "December";
        default:
            return "";
    }
};

export const timeElapsed = (createdAt) => {
    const getElapsedTimeInIST = convertUTCToLocal(createdAt)
    const diff = Math.floor((new Date() - new Date(getElapsedTimeInIST)) / 1000); // Difference in seconds

    if (diff < 0) {
        return `a moment ago`;
    } else if (diff < 60) {
        return `${diff} sec ago`;
    } else if (diff < 3600) {
        return `${Math.floor(diff / 60)} min ago`;
    } else if (diff < 86400) {
        return `${Math.floor(diff / 3600)} hours ago`;
    } else {
        return `${Math.floor(diff / 86400)} days ago`;
    }
};

export const formatDateView = (input) => {
    /**
     * This function is used to format date into DD-MM-YYYY by accepting date from string or dateTime string.
     * It will return empty string if input is null or undefined.
     * @param {String} input
     */
    if (input === null || input === undefined) {
        return '';
    }

    // Create a Date object
    const date = new Date(input);

    // Convert to India Standard Time (UTC+5:30)
    const indiaOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
    const utcTime = date.getTime() + (date.getTimezoneOffset() * 60000); // Convert to UTC
    const indiaTime = new Date(utcTime + indiaOffset); // Convert to IST

    const day = indiaTime.getDate();
    const month = indiaTime.getMonth() + 1;
    const year = indiaTime.getFullYear();

    // Format the date as DD-MM-YYYY
    const formattedDate = `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;

    return formattedDate;
};


export const getGroupName = (group) => {
    switch (group) {
        case COMMERCIAL_HEAD_GROUP:
            return "Commercial Head";
        case TECHNICIAN_GROUP:
            return "Technician";
        case FINANCE_MANAGER_GROUP:
            return "Finance Manager";
        case FACILITY_MANAGER_GROUP:
            return "Facility Manager";
        case CEO_GROUP:
            return "CEO";
        default:
            return "";
    }
};

export const getGroupNameVisaVi = (group) => {
    switch (group) {
        case "Commercial Head":
            return COMMERCIAL_HEAD_GROUP;
        case "Technician":
            return TECHNICIAN_GROUP;
        case "Finance Manager":
            return FINANCE_MANAGER_GROUP;
        case "Facility Manager":
            return FACILITY_MANAGER_GROUP;
        case "CEO":
            return CEO_GROUP;
        default:
            return "";
    }
};

export const formatNumericInput = (input, decimalDigits) => {
    const cleanedInput = input.replace(/[^0-9.]/g, '');
    const parts = cleanedInput.split('.');
    if (parts.length > 2) {
        parts.splice(1, parts.length - 2);
    }
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1].substring(0, decimalDigits) : '';
    const resultValue = integerPart + decimalPart;

    if (parseFloat(resultValue) > 1000000000) {
        return input;
    }

    return resultValue;
}

export const handleKeyDownForMeterReadings = (event) => {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Tab', 'Enter'];
    if (allowedKeys.includes(event.key)) return;
    const { value, selectionStart: cursorPosition } = event.target;
    const key = event.key;
    let newValue = value;
    if (event.key === 'Backspace') {
        newValue = value.slice(0, cursorPosition - 1) + value.slice(cursorPosition);
    } else if (event.key.length === 1) {
        newValue = value.slice(0, cursorPosition) + key + value.slice(cursorPosition);
    }
    newValue = newValue.replace(/[^0-9.]/g, '');
    if (parseFloat(newValue) > 1000000000) {
        event.preventDefault();
    }
};

export const formatNumericInputForMaxValue = (input, maxValue = 1000) => {
    const cleanedInput = input.replace(/[^0-9.]/g, '');
    const parts = cleanedInput.split('.');
    if (parts.length > 2) {
        parts.splice(1, parts.length - 2);
    }
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1].substring(0, 2) : '';
    const resultValue = integerPart + decimalPart;
    if (parseFloat(resultValue) > maxValue) {
        return '';
    }
    return resultValue;
}

export const formatNumericparseFloatInput = (input) => {
    const parts = input?.replace(/[^\d.]+|(?<=\..*)\./g, '').split('.');
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1].substring(0, 2) : '';
    const formattedInput = integerPart + decimalPart;
    // if(formattedInput > 999999999){
    //     return ''
    // }
    return formattedInput;
}

export const formatToWholeNumber = (input) => {
    const cleanedInput = input?.replace(/[^\d]+/g, '');
    
    return cleanedInput;
}


export const formatNumericParseIntInput = (input) => {
    const cleanedInput = input.replace(/\D/g, '');

    let formatedValue = parseInt(cleanedInput, 10);
    if (formatedValue > 999) {
        const truncatedValue = cleanedInput.substring(0, 3);
        formatedValue = parseInt(truncatedValue, 10);
    }

    return formatedValue;
}

export const processAndValidateEmail = (input, currentEmail) => {
    const processedInput = (input === " " && currentEmail.length === 0) ? currentEmail : input;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailRegex.test(processedInput) || processedInput === '';
    return { processedInput, isValid };
};

export const validateRoute = (route) => {
    const group = localStorage.getItem('group');
    if (group === 'Commercial Head') {
        return ACLS.IndoPortCommercialHeadGroup.some((item) => item.ROUTE === route);
    }
    if (group === 'Technician') {
        return ACLS.IndoPortTechnicianGroup.some((item) => item.ROUTE === route);
    }
    if (group === 'Finance Manager') {
        return ACLS.IndoPortFinanceManagerGroup.some((item) => item.ROUTE === route);
    }
    if (group === 'CEO') {
        return ACLS.IndoPortCEOGroup.some((item) => item.ROUTE === route);
    }
    if (group === 'Facility Manager') {
        return ACLS.IndoPortFacilityManagerGroup.some((item) => item.ROUTE === route);
    }
    return false;
}

export const validateValue = (value, regex = /^[a-zA-Z0-9]/) => {
    return regex.test(value) || value === "";
};

export const convertCurrencyToWords = (num) => {
    /**
     * This function converts a currency amount (with Rupees and Paise) into words.
     * It handles numbers with decimals and formats the output as specified.
     * @param {number} num - The currency amount.
     */
    if (num === 0) return "Zero Rupees only";
    if (!num) return '';

    const under20 = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const scales = ['', 'Thousand', 'Lakh', 'Crore'];

    // Function to convert numbers less than 1000 into words
    const makeWords = (n) => {
        if (n < 20) return under20[n - 1];
        if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 ? '-' + under20[n % 10 - 1] : '');
        if (n < 1000) return under20[Math.floor(n / 100) - 1] + ' Hundred' + (n % 100 ? ' and ' + makeWords(n % 100) : '');
        return '';
    };

    // Function to convert the integer part
    const convertIntegerPart = (num) => {
        let words = '';
        let chunks = [];
        let chunkCount = 0;

        // Split number into chunks of thousands, lakhs, crores
        while (num > 0) {
            let size = chunkCount === 0 ? 3 : 2; // First chunk is in thousands, subsequent chunks in lakhs, crores
            let chunk = num % Math.pow(10, size);
            num = Math.floor(num / Math.pow(10, size));
            chunks.push(chunk);
            chunkCount++;
        }

        // Convert each chunk to words
        for (let i = chunks.length - 1; i >= 0; i--) {
            let chunk = chunks[i];
            if (chunk) {
                words += makeWords(chunk) + (scales[i] ? ' ' + scales[i] : '');
                if (i > 0) words += ', ';
            }
        }

        return words.trim();
    };

    // Split into integer and fractional parts
    const [integerPart, fractionalPart] = num.toString().split('.').map(Number);

    // Convert integer part to words
    let words = convertIntegerPart(integerPart) + ' Rupees';

    // Convert fractional part to words (if present)
    if (fractionalPart) {
        words += ' and ' + convertIntegerPart(fractionalPart) + ' Paise';
    }

    return words + ' only';
};


export const formatToIndianRupee = (input) => {
    /**
    * This function is used to convert input into Indian currency format (adds commas into the input).
    * It will return an empty string if input is null, undefined, or NaN.
    * @param {Int/Float/String} input
    */
    if (input === null || input === undefined || isNaN(input)) return "";
    // if (input === 0 || input === 0.0 || input === 0.00 || input === "0.00") return "";

    let [integerPart, decimalPart] = input.toString().split(".");

    if (integerPart.length <= 3) {
        return integerPart + (decimalPart ? "." + decimalPart : "");
    }

    let lastThree = integerPart.substring(integerPart.length - 3);
    const otherNumbers = integerPart.substring(0, integerPart.length - 3);

    if (otherNumbers !== '') {
        lastThree = ',' + lastThree;
    }

    const formattedInteger = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;

    return formattedInteger + (decimalPart ? "." + decimalPart : "");
};




export const licenseFeeDateValidationByStartDateEndDate = (licenseFeeData, startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const hasInvalidDate = licenseFeeData.some(json => {
        const rateStartDate = new Date(json.rate_start_date);
        const rateEndDate = new Date(json.rate_end_date);

        return rateStartDate < start || rateEndDate > end || rateStartDate > rateEndDate || rateStartDate > end || rateEndDate < start;
    });

    if (hasInvalidDate) {
        return {
            error: true,
            message: `One or more start date or end date values are outside the range ${formatDateView(startDate)} to ${formatDateView(endDate)}`
        };
    }

    if (licenseFeeData[licenseFeeData.length - 1].rate_end_date !== endDate) {
        return {
            error: true,
            message: `Last end date value should be equal to ${formatDateView(endDate)}`
        };
    }

    return {
        error: false,
        message: "All dates are valid."
    };
}

export const eleminateSpacesAtStartOfInputField = (input) => {
    /**
     * This function is used to eleminate spaces at the start of input field.
     * It will return empty string if input is only space.
     * @param {String} input
     */
    if (input === " ") {
        return "";
    }
    else {
        return input;
    }
}

export const eleminateSpecialCharactersAtStartOfInputField = (input) => {
    /**
     * This function is used to eleminate special characters at the start of input field.
     * It will return empty string if input is only special character.
     * @param {String} input
     */
    const sanitizedInput = input.replace(/[^a-zA-Z0-9 ]/g, '');
    return sanitizedInput;
}

export const handleCinInputChange = (e, setValue, setIsValid) => {

    /**
    * This function is used to check valid CIN and maxlength.
    * It will return invalid message if it doesn't match regex and 
    * Empty string if input exceeds the maxlength.
    * @param {String} input
    */

    const input = e.target.value;
    const cinRegex = /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
    const maxLength = 21;

    const cleanedInput = eleminateSpacesAtStartOfInputField(input);
    const truncatedInput = cleanedInput.length > maxLength ? cleanedInput.slice(0, maxLength) : cleanedInput;
    const isValid = cinRegex.test(truncatedInput) || truncatedInput === '';

    setValue(truncatedInput);
    setIsValid(isValid);

    e.target.value = truncatedInput;
};

export const eleminateAllSpaces = (e) => {
    /**
   * This function is used to eleminate spaces at the entire input field.
   * It will return empty string if input is only space.
   * @param {String} input
   */

    if (e.key === ' ') {
        e.preventDefault();
    }
};

export const validateGstNumber = (input) => {
    /**
   * This function checks whether a GST number is valid.
   * Converts all alphabetical characters to uppercase.
   * @param {String} input
   */
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[A-Z\d]{1}$/;
    const uppercasedInput = input.toUpperCase();
    const isValid = gstRegex.test(uppercasedInput) || uppercasedInput === '';
    return {
        isValid,
        uppercasedInput
    };
};

export const handleKeyPressForGst = (e) => {
    const allowedKeys = [
        'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight',
        'Backspace', 'Delete', 'Tab', 'Enter',
        'Home', 'End', 'PageUp', 'PageDown', 'Escape'
    ];
    if (allowedKeys.includes(e.key)) {
        return;
    }
    const allowedChars = /^[0-9a-zA-Z]$/;
    if (!allowedChars.test(e.key)) {
        e.preventDefault();
    }
};

export const encrypt = (value) => {

    value = { timestamp: new Date().toISOString(), ...value };

    if (!CRYPTO_SECRET_KEY) {
        console.error('CRYPTO_SECRET_KEY is not defined.');
        return null;
    }

    if (value) {
        // Ensure the key is 16 bytes (AES-128)
        const key = CryptoJS.enc.Utf8.parse(CRYPTO_SECRET_KEY);

        // Encrypt the value using AES with ECB mode and PKCS7 padding
        const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).toString();
        return encryptedValue;
    } else {
        return null;
    }
};

export const checkUniqueness = (data, exixstingInput, input) => {
    /**
    * This function is used to check uniqueness of input in the data array.
    * it accepts data array, actual contract_name/number and new input.
    * it returns true if input is already exist.
    * @param {Array,String,String} input
    */
    const lowerCaseInput = input?.toLowerCase();
    const isReplicaValid = lowerCaseInput === exixstingInput?.toLowerCase();
    const isUnique = data.some(item => item?.toLowerCase() === lowerCaseInput);
    return (isUnique && !isReplicaValid);
}


// recieves a list with created_at key and sorts it in from the latest.
export const sorting_lists = (list) => {
    return list?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
}

export const toTitleCase = (value) => {
    return value.split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
}